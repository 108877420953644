import {
  DeductCreditsDocument,
  getApolloClient,
  Organization,
} from 'modules/api'
import { featureFlags } from 'modules/featureFlags'
import { getCanUseProductFeature } from 'modules/monetization/hooks/productFeatures'
import { getStore } from 'modules/redux'
import { analytics, SegmentEvents } from 'modules/segment'
import { getCurrentWorkspaceId } from 'modules/user/context'

import { DEFAULT_CREDIT_COSTS } from './constants'
import { selectAvailableCredits, setCredits } from './reducer'
import { CreditCostTypes } from './types'

// If a workspace is passed (e.g. the doc workspace), that's preferred
// Otherwise, use the user's current workspace
export const deductCredits = async (
  type: CreditCostTypes,
  workspace?: Organization
) => {
  const hasUnlimitedAI = getCanUseProductFeature('unlimited_ai', workspace)
  const creditCosts = featureFlags.get('aiDebits')
  const value = creditCosts[type] ?? DEFAULT_CREDIT_COSTS[type]
  // Track overall credit consumption, even if the user has unlimited AI
  // Lets us understand relative AI use of Pro vs. Plus/Free users
  analytics.track(SegmentEvents.AI_CREDITS_USED, {
    operation: type,
    used: value,
    has_unlimited_ai: hasUnlimitedAI,
  })
  if (value === 0 || hasUnlimitedAI) return

  const workspaceId = workspace?.id || getCurrentWorkspaceId()
  if (!workspaceId) return

  const client = getApolloClient()
  const result = await client.mutate({
    mutation: DeductCreditsDocument,
    variables: {
      value,
      workspaceId: workspaceId,
    },
  })
  const response = result.data?.deductCredits
  if (!response) {
    return null
  }
  // Track actual deductions
  analytics.track(SegmentEvents.AI_CREDITS_DEDUCTED, {
    operation: type,
    used: value,
    remaining: response.credits,
  })

  const store = getStore()
  store.dispatch(setCredits({ availableCredits: response.credits }))
  return response.credits
}

export const checkCredits = (
  type: CreditCostTypes,
  workspace?: Organization
): Boolean => {
  const hasUnlimitedAI = getCanUseProductFeature('unlimited_ai', workspace)
  const creditCosts = featureFlags.get('aiDebits')
  const value = creditCosts[type] ?? DEFAULT_CREDIT_COSTS[type]
  if (value === 0 || hasUnlimitedAI) return true

  const store = getStore()
  const state = store.getState()
  const availableCredits = selectAvailableCredits(state)
  return !!availableCredits && availableCredits >= value
}
