import {
  Box,
  Button,
  Center,
  Fade,
  GridItem,
  IconButton,
  Skeleton,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { memo } from 'react'

import { SavedMedia } from 'modules/api'
import {
  backgroundImageFromUrls,
  resizeAndProxyImageUrl,
  THUMBNAIL_RESIZE_PARAMS,
} from 'utils/image'

type AIImageHistoryItemProps = {
  image: SavedMedia
  isSelected: boolean
  onImageClick: (image: SavedMedia) => void
  resizeThumbnail?: boolean
  isDeleting: boolean
  onDeleteImage: (savedMediaId: string) => void
}

export const AIImageHistoryItem = memo(
  ({
    image,
    isSelected,
    onImageClick,
    resizeThumbnail,
    isDeleting,
    onDeleteImage,
  }: AIImageHistoryItemProps) => {
    const thumbnailUrl = resizeThumbnail
      ? resizeAndProxyImageUrl(
          image.attrs.src,
          THUMBNAIL_RESIZE_PARAMS,
          image.attrs.meta
        )
      : image.attrs.src
    const backgroundImage = backgroundImageFromUrls(thumbnailUrl)
    return (
      <GridItem key={image.id}>
        <Button
          borderRadius="base"
          display="block"
          variant="ghost"
          width="100%"
          opacity={1}
          height="100px"
          textAlign="left"
          pos="relative"
          p={0}
          _hover={{ shadow: 'outline' }}
          _active={{ shadow: 'outline' }}
          transition="box-shadow .2s ease"
          cursor="pointer"
          onClick={() => onImageClick(image)}
          backgroundImage={backgroundImage}
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition="center"
          sx={{
            _focusVisible: {
              '.attribution-container': {
                opacity: 1,
              },
            },
            _hover: {
              '.attribution-container': {
                opacity: 1,
              },
            },
          }}
          isDisabled={isDeleting}
          data-testid="image-search-grid-item"
          role="group"
        >
          <Fade in={isSelected}>
            <Box
              pointerEvents="none"
              color="white"
              pos="absolute"
              inset={0}
              bg="rgba(0,0,0,.5)"
              borderRadius="base"
            >
              <Center h="100%" w="100%">
                <FontAwesomeIcon icon={regular('check')} />
              </Center>
            </Box>
          </Fade>
          {/* // An icon button that deletes the image when clicked */}
          {!isSelected && (
            <IconButton
              isRound
              size="xs"
              aria-label="Delete image"
              onClick={(e) => {
                e.stopPropagation()
                onDeleteImage(image.id)
              }}
              color="red.500"
              icon={<FontAwesomeIcon icon={regular('trash')} />}
              zIndex={100}
              pos="absolute"
              top={1}
              display="none"
              bg="0 none"
              bgColor="red.50"
              right={1}
              _hover={{
                // override the default button hover color
                color: 'red.600',
                bg: '0 none',
                bgColor: 'red.200',
              }}
              _groupHover={{
                display: 'block',
              }}
            />
          )}

          {/* We need a fallback */}
          {!image.attrs.src && (
            <GridItem width="100%">
              <Skeleton height="80px" borderRadius="base" />
            </GridItem>
          )}
        </Button>
      </GridItem>
    )
  }
)

AIImageHistoryItem.displayName = 'AIImageHistoryItem'
