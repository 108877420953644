import { querySelectorArray } from './domUtils'
import { wrapListItems } from './list'

export const transformCopiedDocFragment = (
  doc: DocumentFragment
): DocumentFragment => {
  // Convert our flat list structure into a semantic tree
  querySelectorArray(doc, 'li').forEach((elt) => {
    wrapListItems(elt)
  })
  return doc
}
