import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { GradientPicker } from 'modules/theming/components/MeshGradientPicker/GradientPicker'

import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'

const GradientPanel = ({
  updateAttributes,
  currentAttributes,
  isDark,
}: MediaProviderPanelProps) => {
  return (
    <GradientPicker
      value={currentAttributes}
      updateValue={updateAttributes}
      isDark={isDark}
    />
  )
}

export const GradientProvider: MediaProviderType = {
  key: 'color.gradient',
  Panel: GradientPanel,
  label: () => t`Gradient`,
  description: () => t`Generate beautiful gradients`,
  icon: duotone('rainbow'),
  keywords: ['gradient', 'color', 'mesh'],
}
