import { FlexProps, Flex } from '@chakra-ui/react'

import { useModalDisclosure } from 'modules/modal_state/hooks/useModalDisclosure'
import { useHasUnlimitedAI } from 'modules/monetization/hooks/productFeatures'
import { SegmentEvents } from 'modules/segment'

import { useCreditStatus } from '../hooks'
import { AICreditsModal } from './AICreditsModal'
import { CreditsBadge } from './CreditsBadge'

interface AICreditsBadgeWithModalProps extends FlexProps {
  modalId?: string
  variant?: 'solid' | 'ghost'
  segmentEvent?: SegmentEvents
}
export const AICreditsBadgeWithModal = ({
  modalId = 'ai-credits-modal',
  variant,
  segmentEvent,
  ...rest
}: AICreditsBadgeWithModalProps) => {
  const { credits, lowCreditStatus } = useCreditStatus()
  const hasUnlimitedAI = useHasUnlimitedAI()

  const { isOpen, onOpen, onClose } = useModalDisclosure({ id: modalId })

  if (hasUnlimitedAI) {
    return null
  }

  return (
    <>
      <Flex {...rest}>
        <CreditsBadge
          credits={credits}
          onClick={onOpen}
          lowCreditStatus={lowCreditStatus}
          variant={variant}
        />
      </Flex>
      <AICreditsModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        credits={credits}
        segmentEvent={segmentEvent}
      />
    </>
  )
}
