import React, { createContext, useContext } from 'react'

import { NavigationStackContext } from 'modules/navigation_stack/NavigationStackContext'
import { ThemeAction } from 'modules/theming/themeReducer/types'
import { FontMap, FontPickerFonts, Theme } from 'modules/theming/types'

export type ThemeConfigNavigationState = {
  theme: Theme
  fontState: { fonts: FontPickerFonts; fontsMap: FontMap }
}

type ThemeConfigNavigationContext = {
  state: ThemeConfigNavigationState
  dispatch: React.Dispatch<ThemeAction>
  label: React.ReactNode
  setLabel: React.Dispatch<React.SetStateAction<string>>
}

export const ThemeConfigNavigationContext =
  createContext<ThemeConfigNavigationContext>({} as any)

export function useThemeConfigNavigationContext() {
  const themeCtx = useContext(ThemeConfigNavigationContext)
  const navCtx = useContext(NavigationStackContext)
  return { ...themeCtx, ...navCtx }
}
