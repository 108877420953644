import { TransformStream } from 'web-streams-polyfill/ponyfill'

import { StreamError, StreamErrorCode } from 'modules/ai/stream/errors'
import {
  CancelStreamMessageEvent,
  ContentStreamMessageEvent,
  DoneStreamMessageEvent,
  StreamMessageEvent,
} from 'modules/ai/stream/types'
import { EventSourceMessage } from 'utils/fetchEventSource'

/**
 * Converts event source messages into StreamMessageEvents
 *
 * Rerouting messages of type === 'error' to controller.error()
 *
 * The three types of messages the /ai/chatCompletion streaming api sends are
 *
 * {
 *   type: 'content'
 *   id: string
 *   data: string
 * }
 *
 * {
 *   type: 'error'
 *   id: string
 *   data: string // error string
 * }
 *
 * {
 *   type: 'done'
 *   id: string
 *   data: string // finsih reason
 * }
 *
 * The streamChatCompletionApi function can also send a cancel event
 * {
 *   type: 'cancel'
 *   id: string
 *   data: ''
 * }
 */
export class StreamMessageTransformer extends TransformStream<
  EventSourceMessage,
  StreamMessageEvent
> {
  constructor() {
    super({
      transform: async (
        msg: EventSourceMessage,
        controller: TransformStreamDefaultController<StreamMessageEvent>
      ) => {
        if (msg.event === 'error') {
          controller.error(
            new StreamError(
              `StreamError message received: ${JSON.stringify(msg)}`,
              msg.data as StreamErrorCode
            )
          )
          return
        }

        if (msg.event === 'cancel') {
          controller.enqueue(msg as CancelStreamMessageEvent)
          return
        }

        if (msg.event === 'done') {
          controller.enqueue(msg as DoneStreamMessageEvent)
          return
        }

        if (msg.event === 'content') {
          controller.enqueue(msg as ContentStreamMessageEvent)
          return
        }
      },
    })
  }
}
