import {
  Flex,
  FlexProps,
  HStack,
  ImageProps,
  Spinner,
  Tag,
  TagLabel,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import React from 'react'

import { ImageSearchProviderKey } from 'modules/media/types/ImageSearch'
import { ImageLoadingSpinner } from 'modules/tiptap_editor/extensions/media/Image/ImageLoadingSpinner'

interface MediaPlaceholderImageProps extends FlexProps {
  icon?: FontAwesomeIconProps['icon']
  iconSize?: FontAwesomeIconProps['size']
  isUploading?: boolean
  isLoading?: boolean
  loadingProvider?: ImageSearchProviderKey
  hasUploadError?: boolean
}

export const MediaPlaceholderSpinner = () => (
  <Tag
    size="sm"
    variant="subtle"
    colorScheme="gray"
    pos="absolute"
    top={4}
    left={4}
    p={1}
  >
    <Spinner color="gray.600" size="xs" />
  </Tag>
)

export const MediaPlaceholderErrorUploadingTag = () => (
  <Tag
    size="sm"
    variant="subtle"
    colorScheme="red"
    pos="absolute"
    top={4}
    left={4}
  >
    <TagLabel>
      <FontAwesomeIcon icon={regular('exclamation-circle')} />{' '}
      <Trans>Error uploading image.</Trans>
    </TagLabel>
  </Tag>
)

export const MediaPlaceholderImage: React.FC<MediaPlaceholderImageProps> = ({
  children,
  icon = regular('photo-video'),
  iconSize = '2x',
  isLoading = false,
  isUploading = false,
  hasUploadError = false,
  loadingProvider,
  ...flexProps
}) => {
  return (
    <Flex
      color="gray.400"
      align="center"
      justify="center"
      p={4}
      bg="gray.200"
      className="placeholder"
      data-drag-handle
      data-export-as-image
      position="relative"
      {...flexProps}
    >
      {!isLoading && (
        <HStack spacing={4}>
          <FontAwesomeIcon size={iconSize} icon={icon} />
        </HStack>
      )}
      {isLoading && <ImageLoadingSpinner provider={loadingProvider} />}
      {isUploading && <MediaPlaceholderSpinner />}
      {hasUploadError && <MediaPlaceholderErrorUploadingTag />}
      {children}
    </Flex>
  )
}

export const mediaPlaceholderStyleProps: ImageProps = {
  minW: 16,
  minH: 16,
  bg: 'gray.200',
}
