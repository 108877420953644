import { ImageSearchProviderKey } from '../types/ImageSearch'
import { MediaProviderType } from '../types/MediaProvider'
import { AIGeneratedImageProvider } from './AIGeneratedImage'
import { UnsplashProvider } from './Unsplash'
import { WebImageProvider } from './WebImage'

export const ImageSearchProvidersToMediaProviderKey: Record<
  ImageSearchProviderKey,
  MediaProviderType['key']
> = {
  bing: 'image.bing',
  giphy: 'image.giphy',
  google: 'image.google',
  web: 'image.web',
  unsplash: 'image.unsplash',
  lexica: 'image.lexica',
  aiGenerated: 'image.ai-generated',
}

export const ImageSearchProviders: Partial<
  Record<ImageSearchProviderKey, MediaProviderType>
> = {
  // unsplash: UnsplashProvider,
  web: WebImageProvider,
  aiGenerated: AIGeneratedImageProvider,
}
