import {
  callOrReturn,
  Extension,
  findChildren,
  getExtensionField,
} from '@tiptap/core'

import { ImageAttrs } from 'modules/media/types/Image'
import {
  ImageUploadResult,
  UploadStatus,
} from 'modules/media/types/ImageUpload'

import { UpdateNodeAttrsAnnotationEvent } from '../../Annotatable/AnnotationExtension/types'
import { resizeImageToFitViewport } from '../utils'
import { generateUploadPlugin } from './uploadPlugin'
import {
  getNodeImageAttrs,
  getUploadedImageAttrs,
  mergeImageAttrs,
} from './utils'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    mediaUpload: {
      /**
       * Save the organization ID for image uploading
       */
      setOrgId: (orgId: string) => ReturnType
      updateImageOnUploadSuccess: (
        pos: number,
        result: ImageUploadResult,
        key?: string
      ) => ReturnType
      updateImageOnUploadFailure: (
        pos: number,
        restoreOldSrc?: boolean
      ) => ReturnType
      revokeImageTempURLs: (tempUrl: string) => ReturnType
    }
  }
}

export interface MediaUploadOptions {}

export interface MediaUploadStorage {
  orgId: string | undefined
  completedUploads: Record<string, ImageUploadResult>
}

// Tiptap extension that handles dragging and pasting directly into the doc
export const MediaUpload = Extension.create<MediaUploadOptions>({
  name: 'mediaUpload',

  addCommands() {
    return {
      setOrgId: (orgId) => () => {
        this.storage.orgId = orgId
        return true
      },
      revokeImageTempURLs:
        (tempUrl: string) =>
        ({ tr, state }) => {
          const matches = findChildren(state.doc, (node) => {
            return node.attrs.tempUrl === tempUrl
          })
          if (matches.length == 0) {
            return true
          }

          matches.forEach((match) => {
            tr.setNodeAttribute(match.pos, 'tempUrl', null)
              .setMeta('addToHistory', false)
              .setMeta('imageUploadUpdateAttrs', true)
          })
          URL.revokeObjectURL(tempUrl)
          return true
        },

      updateImageOnUploadFailure:
        (pos, restoreAttrs = false) =>
        ({ tr, state }) => {
          const node = state.doc.nodeAt(pos)
          if (!node || !node.attrs) {
            return false
          }
          const existingImageAttrs = getNodeImageAttrs(node)

          const newImageAttrs: Partial<ImageAttrs> = restoreAttrs
            ? {
                ...existingImageAttrs,
                uploadStatus: UploadStatus.Done,
              }
            : getUploadedImageAttrs({
                isError: true,
              })

          const newNodeAttrs = mergeImageAttrs(node, newImageAttrs)

          tr.setNodeMarkup(
            pos,
            // undefined to signify no change to nodeType
            undefined,
            // we replace all node attrs here because things like src and tempUrl
            // are not valid if the upload failed
            newNodeAttrs
          )
            .setMeta('imageUploadUpdateAttrs', true)
            .setMeta('addToHistory', false)
            .setMeta('annotationEvent', <UpdateNodeAttrsAnnotationEvent>{
              type: 'update-node-attrs',
              pos,
            })
          return true
        },

      updateImageOnUploadSuccess:
        (pos, result) =>
        ({ tr, state }) => {
          const node = state.doc.nodeAt(pos)
          if (!node || !node.attrs) {
            return false
          }

          const uploadAttrs = getUploadedImageAttrs({
            isError: false,
            result,
          })
          const resizedAttrs = resizeImageToFitViewport(uploadAttrs)

          // include the existing node.attrs becuase we dont want to get rid of tempurl
          const newNodeAttrs = mergeImageAttrs(node, resizedAttrs)

          tr.setNodeMarkup(
            pos,
            // undefined to signify no change to nodeType
            undefined,
            newNodeAttrs
          )
            .setMeta('imageUploadUpdateAttrs', true)
            .setMeta('addToHistory', false)
            .setMeta('annotationEvent', <UpdateNodeAttrsAnnotationEvent>{
              type: 'update-node-attrs',
              pos,
            })
          return true
        },
    }
  },

  addStorage() {
    return {
      orgId: undefined,
      completedUploads: {},
    }
  },

  addProseMirrorPlugins() {
    return [generateUploadPlugin(this.editor)]
  },
})
