import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t, Trans } from '@lingui/macro'

import { GAMMA_ARTIFACT_PROPER_NOUN } from 'modules/i18n/properNouns'
import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'

import { EmbedPanel } from '../components/EmbedPanel'
import { EmbedTip } from '../components/EmbedTip'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import GoogleDriveIcon from './icons/google-drive.svg'

const Panel = (props: MediaProviderPanelProps<WebEmbedAttrs>) => {
  return (
    <EmbedPanel
      {...props}
      urlPlaceholder="https://docs.google.com/..."
      embedTip={
        <EmbedTip
          guideLink="https://support.google.com/docs/answer/183965?hl=en&co=GENIE.Platform%3DDesktop#zippy=%2Cedit-embedded-spreadsheets%2Cembed-a-document-spreadsheet-or-presentation"
          extraMessage={
            <Trans>
              You don't need to publish to embed, but if you don't, anyone you
              share this {GAMMA_ARTIFACT_PROPER_NOUN} with will need to be
              shared on the file to see it.
            </Trans>
          }
          providerName="Google Sheets, Docs, or Slides"
        />
      }
    />
  )
}

const SHEETS_PROPER_NOUN = 'Sheets'
const DOCS_PROPER_NOUN = 'Docs'
const SLIDES_PROPER_NOUN = 'Slides'
export const GoogleDriveProvider: MediaProviderType = {
  label: () => 'Google Drive',
  key: 'embed.gdrive',
  description: () =>
    t`Embed interactive ${SHEETS_PROPER_NOUN} and ${DOCS_PROPER_NOUN} and full ${SLIDES_PROPER_NOUN} presentations directly in a ${GAMMA_ARTIFACT_PROPER_NOUN}`,
  icon: brands('google-drive'),
  image: GoogleDriveIcon,
  urlRegex: /^https?:\/\/docs.google.com\//,
  Panel,
  canEmbedPrivate: true,
  rewriteEmbedUrl: (embedUrl) => {
    // https://stackoverflow.com/questions/23446449/google-sheet-embed-url-documentation
    if (embedUrl.includes('/presentation/')) {
      // Always load the play version for Slides
      const previewUrl = new URL(embedUrl.replace('/edit', '/preview'))
      previewUrl.searchParams.set('rm', 'minimal') // Hides the nav that causes weird jumping behavior
      return previewUrl.toString()
    } else {
      // Always load the editable version for Docs and Sheets
      const editableUrl = new URL(embedUrl.replace('/preview', '/edit'))
      // editableUrl.searchParams.set('rm', 'embedded') // This hides the title, but not sure we want that by default
      return editableUrl.toString()
    }
  },
  keywords: ['docs', 'sheet', 'slide', 'gdrive', 'document'],
}
