import { VStack } from '@chakra-ui/react'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t, Trans } from '@lingui/macro'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { getMediaEmbedUrl } from 'modules/tiptap_editor/extensions/media/utils'

import { EmbedTip } from '../components/EmbedTip'
import { URLFetcher } from '../components/URLFetcher'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import { EmbedMetadata } from './EmbedMetadata'
import OfficeIcon from './icons/office-365.svg'

const MicrosoftOfficePanel = ({
  editor,
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<WebEmbedAttrs>) => {
  const url = getMediaEmbedUrl(currentAttributes)

  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url}
        updateAttributes={updateAttributes}
        placeholder={t`Paste a URL or <iframe...`}
      />
      <EmbedTip
        guideLink="https://support.microsoft.com/en-us/office/embed-a-word-document-in-your-blog-0faa0253-2751-44bf-8486-ea7837fe7db1"
        extraMessage={
          <Trans>
            If you see a "sign in" button, you'll need to refresh after logging
            in.
          </Trans>
        }
        providerName="Word, Excel, or PowerPoint"
      />
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
    </VStack>
  )
}

const MICROSOFT_PROPER_NOUN = 'Microsoft'
const WORD_PROPER_NOUN = 'Word'
const EXCEL_PROPER_NOUN = 'Excel'
const POWERPOINT_PROPER_NOUN = 'PowerPoint'
const ONENOTE_PROPER_NOUN = 'OneNote'

export const MicrosoftOfficeProvider: MediaProviderType = {
  key: 'embed.microsoft.office',
  Panel: MicrosoftOfficePanel,
  label: () => 'Office 365',
  description: () =>
    t`${MICROSOFT_PROPER_NOUN} ${WORD_PROPER_NOUN}, ${POWERPOINT_PROPER_NOUN}, ${EXCEL_PROPER_NOUN}, or ${ONENOTE_PROPER_NOUN}`,
  icon: brands('microsoft'),
  image: OfficeIcon,
  urlRegex: /^https?:\/\/.*\.sharepoint\.com\//,
  canEmbedPrivate: true,
  iframeOptions: {
    allowPopups: true, // Required for their sign-in system
  },
  rewriteEmbedUrl: (url: string) => {
    // Hacks to make coerce the regular URL into an embeddable one
    const embeddableUrl = new URL(
      url.replace('/:p:/r', '').replace('/doc2.aspx', '/Doc.aspx')
    )
    embeddableUrl.searchParams.set('action', 'embedview')
    embeddableUrl.searchParams.delete('mobileredirect')
    return embeddableUrl.toString()
  },
  keywords: ['word', 'excel', 'powerpoint', 'office', 'microsoft', 'msoffice'],
}
// URL examples
// Embed code
// <iframe src="https://trygamma-my.sharepoint.com/personal/jon_trygamma_onmicrosoft_com/_layouts/15/Doc.aspx?sourcedoc={15af796d-0db9-4ba3-8b97-7c95c6fd1cae}&amp;action=embedview&amp;wdAr=1.7777777777777777" width="350px" height="221px" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>

// Embed src
// https://trygamma-my.sharepoint.com/:p:/r/personal/jon_trygamma_onmicrosoft_com/_layouts/15/doc2.aspx?sourcedoc=%7B15AF796D-0DB9-4BA3-8B97-7C95C6FD1CAE%7D&file=Presentation.pptx&action=edit&mobileredirect=true&DefaultItemOpen=1&ct=1640115118970&wdOrigin=OFFICECOM-WEB.MAIN.OTHER&cid=ce9c014a-dc5f-43fd-8534-42f143af5cd2

// Direct URL, needs to be rewritten with rewriteEmbedUrl above
// https://trygamma-my.sharepoint.com/personal/jon_trygamma_onmicrosoft_com/_layouts/15/doc2.aspx?sourcedoc=%7B15af796d-0db9-4ba3-8b97-7c95c6fd1cae%7D&action=embedview&wdAr=1.7777777777777777&ClientRender=1
