import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { useCallback, useEffect, useRef, useState } from 'react'

import { AIImageRatingKey, trackAIImageRating } from 'modules/ai/track'
import { SavedMedia } from 'modules/api'
import { AICreditsBadgeWithModal } from 'modules/credits/components/AICreditsBadgeWithModal'
import { CreditCostIcon } from 'modules/credits/components/CreditCostIcon'
import { useCreditStatus } from 'modules/credits/hooks'
import { GAMMA_PROPER_NOUN } from 'modules/i18n/properNouns'
import { AIImageHistory } from 'modules/media/components/AIGeneratedImages/AIImageHistory'
import { AIImageRating } from 'modules/media/components/AIGeneratedImages/AIImageRating'
import { AspectRatioSelect } from 'modules/media/components/AIGeneratedImages/AspectRatioSelect'
import {
  useCanUseAIGeneratedImages,
  useEnhanceImagePrompt,
  useImageGenerate,
  useImageRatingStore,
} from 'modules/media/components/AIGeneratedImages/hooks'
import { useImageGenerateConfig } from 'modules/media/components/AIGeneratedImages/imageGenerateConfig'
import { NoCredits } from 'modules/media/components/AIGeneratedImages/NoCredits'
import { PromptSelector } from 'modules/media/components/AIGeneratedImages/PromptSelector'
import { StylePresetMenu } from 'modules/media/components/AIGeneratedImages/StylePresetMenu'
import { MediaProviderPanelProps } from 'modules/media/types/MediaProvider'
import { SegmentEvents } from 'modules/segment/segmentEvents'
import { useScrollToOnImageNodeLoad } from 'modules/tiptap_editor/extensions/media/Image/hooks'

import { ImageAttrs } from '../../types/Image'

type AIGenerateImageProps = MediaProviderPanelProps<ImageAttrs>

const AI_GENERATED_PROVIDER_KEY = 'image.ai-generated'

export const AIGeneratedImagesPanel = ({
  currentAttributes,
  updateAttributes,
  editor,
}: AIGenerateImageProps) => {
  const { hasEnoughCreditsToGenerateImage: hasEnoughCredits, isCreditsReady } =
    useCreditStatus()
  const showNotEnoughCreditsMessage = isCreditsReady && !hasEnoughCredits
  // if the user does not have feature flag still let them open the panel, but dont let them generate
  const enabled = useCanUseAIGeneratedImages()
  const canGenerate = enabled && hasEnoughCredits

  const {
    prompt,
    addPrompt,
    promptPageInfo,
    nextPrompt,
    prevPrompt,
    count,
    stylePreset,
    setStylePreset,
    aspectRatio,
    setAspectRatio,
    imageGenerateConfig,
    loadImageAiParams,
  } = useImageGenerateConfig()

  const scrollOnImageLoad = useScrollToOnImageNodeLoad(editor)

  const selectImage = useCallback(
    (image: SavedMedia) => {
      loadImageAiParams(image.attrs, 'select')
      updateAttributes({
        tempUrl: null,
        ...image.attrs,
      })
      if (editor) {
        scrollOnImageLoad(editor?.state.selection.from)
      }
    },
    [editor, loadImageAiParams, scrollOnImageLoad, updateAttributes]
  )

  const {
    generate,
    isGenerating,
    isLoadingMore,
    hasError,
    imageResults,
    loadMore,
    canLoadMore,
    errorMessage,
    deleteImage,
    deletingId,
  } = useImageGenerate({ selectImage })

  const currentMediaIsAIGenerated =
    currentAttributes.source === AI_GENERATED_PROVIDER_KEY

  const selectedSavedMediaId = currentAttributes.savedMediaId

  const currentAIImageUrl = currentMediaIsAIGenerated
    ? currentAttributes.src
    : undefined

  // only run this when loading
  useEffect(() => {
    // loading an AI generated image into panel
    if (currentMediaIsAIGenerated && currentAttributes.aiParams?.prompt) {
      loadImageAiParams(currentAttributes, 'load')
    }

    if (!currentMediaIsAIGenerated && currentAttributes.query) {
      // coming from a different source, set the query as the prompt
      addPrompt(currentAttributes.query, 'load')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const textarea = useRef<any>(null)
  // hack to get around MediaDrawer's useEffect for getting first focusable
  useEffect(() => {
    setTimeout(() => {
      textarea.current?.focus()
    }, 200)
  }, [])

  const scrollTextAreaToBottom = useCallback(() => {
    if (textarea.current) {
      textarea.current.scrollTop = textarea.current?.scrollHeight
    }
  }, [])

  // Enhance prompt logic
  const [isEnhancingPrompt, setIsEnhancingPrompt] = useState(false)
  const enhancePrompt = useEnhanceImagePrompt(editor, {
    onStart() {
      addPrompt('', 'push')
    },
    onContent(content: string) {
      addPrompt(content, 'update')
      scrollTextAreaToBottom()
    },
  })

  const runEnhancePrompt = useCallback(() => {
    if (isEnhancingPrompt) {
      return
    }
    setIsEnhancingPrompt(true)
    enhancePrompt(prompt)
      .catch((err) => {
        console.error('Error enhancing AI image prompt', err)
      })
      .finally(() => {
        setIsEnhancingPrompt(false)
      })
  }, [enhancePrompt, isEnhancingPrompt, prompt])

  useEffect(() => {
    if (currentAIImageUrl || !editor) return
    // when this component loads, automatically run the enhance prompt if it's a net new image
    runEnhancePrompt()
    // we only want this hook to run when the component loads, once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  const submitGenerate = useCallback(() => {
    if (isEnhancingPrompt || isGenerating) {
      return
    }

    if (prompt.trim().length === 0) {
      setIsEnhancingPrompt(true)
      // auto enhance prompt before submitting
      enhancePrompt('')
        .then((newPrompt) => {
          generate({
            ...imageGenerateConfig,
            prompt: newPrompt,
          })
        })
        .catch((err) => {
          console.error('Error enhancing AI image prompt', err)
        })
        .finally(() => {
          setIsEnhancingPrompt(false)
        })
    } else {
      generate(imageGenerateConfig)
    }
  }, [
    enhancePrompt,
    generate,
    imageGenerateConfig,
    isEnhancingPrompt,
    isGenerating,
    prompt,
  ])

  const onPromptKeydown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && e.metaKey) {
        e.preventDefault()
        submitGenerate()
      }
      // Prevent outside handlers like forwardUndo from running on this
      e.stopPropagation()
    },
    [submitGenerate]
  )

  const { saveImageRating, hasRatedImage } = useImageRatingStore()

  const onRateImage = useCallback(
    (rating: AIImageRatingKey) => {
      if (!currentAttributes.aiParams?.interactionId) {
        return
      }

      trackAIImageRating({
        rating,
        aiParams: currentAttributes.aiParams,
        imageUrl: currentAttributes.src!,
        interactionId: currentAttributes.aiParams.interactionId,
      })

      saveImageRating(currentAttributes, rating)
    },
    [currentAttributes, saveImageRating]
  )

  return (
    <VStack
      spacing={4}
      align="stretch"
      translate="no" // https://linear.app/gamma-app/issue/G-3050/reactdom-insertbefore-error-when-using-translation-plugin
    >
      {currentAIImageUrl && (
        <Flex justify="center" pos="relative" overflow="visible">
          <Image
            maxH="30vh"
            maxW="100%"
            objectFit="contain"
            src={currentAIImageUrl}
            alt=""
            ignoreFallback
            borderRadius="md"
          />
          {!hasRatedImage(currentAttributes) && (
            <AIImageRating
              attrs={currentAttributes}
              onRateImage={onRateImage}
            />
          )}
        </Flex>
      )}
      {showNotEnoughCreditsMessage && <NoCredits />}
      {hasError && (
        <Alert
          status="error"
          flexDirection="row"
          alignItems="start"
          justifyContent="start"
          borderRadius="md"
        >
          <AlertIcon />
          <AlertDescription>{errorMessage}</AlertDescription>
        </Alert>
      )}
      <VStack align="stretch" spacing={4}>
        {/* Prompt area */}
        <VStack align="stretch" spacing={1}>
          <HStack position="relative" align="center" justify="space-between">
            <HStack>
              <Heading size="sm">
                <Trans>Prompt</Trans>
              </Heading>
            </HStack>
            <HStack>
              <GammaTooltip
                placement="top"
                label={
                  <Trans>
                    {GAMMA_PROPER_NOUN} will use your card content to enhance or
                    write prompt for you
                  </Trans>
                }
              >
                <Button
                  variant="ghost"
                  rightIcon={
                    <FontAwesomeIcon icon={regular('magic-wand-sparkles')} />
                  }
                  isLoading={isEnhancingPrompt || isGenerating}
                  isDisabled={!enabled}
                  aria-label="magic generate"
                  onClick={runEnhancePrompt}
                  size="sm"
                >
                  <Trans>Enhance</Trans>
                </Button>
              </GammaTooltip>
            </HStack>
          </HStack>
          <Textarea
            ref={textarea}
            isDisabled={!enabled || isGenerating}
            placeholder={
              isEnhancingPrompt
                ? ''
                : t`Tip: click "Generate" to auto generate a prompt based on your card's content.`
            }
            onKeyDown={onPromptKeydown}
            value={prompt}
            onChange={(event) => {
              addPrompt(event.target.value, 'update')
            }}
            data-testid="image-search-input"
            rows={5}
          ></Textarea>
          {promptPageInfo.total > 1 && (
            <PromptSelector
              alignSelf="end"
              promptPageInfo={promptPageInfo}
              nextPrompt={nextPrompt}
              prevPrompt={prevPrompt}
              isDisabled={isGenerating || isEnhancingPrompt}
            />
          )}
        </VStack>

        {/* Style Presets and submit */}
        <VStack align="stretch">
          <Heading size="sm">
            <Trans>Style settings</Trans>
          </Heading>
          <StylePresetMenu
            value={stylePreset}
            setValue={setStylePreset}
            isDisabled={!enabled}
          />
          <AspectRatioSelect
            value={aspectRatio}
            setValue={setAspectRatio}
            isDisabled={!enabled}
          />
          <VStack spacing={1} align="stretch">
            <Button
              variant="solid"
              isLoading={isGenerating}
              isDisabled={!canGenerate || isEnhancingPrompt}
              onClick={submitGenerate}
              px={12}
            >
              <Trans>Generate</Trans>
              <CreditCostIcon
                type="generateImage"
                position="absolute"
                right={4}
              />
            </Button>
            <AICreditsBadgeWithModal
              modalId="ai-credits-modal-message-list"
              variant="ghost"
              justify="flex-end"
              segmentEvent={
                SegmentEvents.GAMMA_UPSELL_CALLOUT_AI_CREDITS_IMAGES
              }
            />
          </VStack>
        </VStack>
      </VStack>
      {enabled && (
        <AIImageHistory
          onSelectImage={selectImage}
          savedMediaId={selectedSavedMediaId}
          imageResults={imageResults}
          onDeleteImage={deleteImage}
          deletingId={deletingId}
          isLoading={isGenerating}
          canLoadMore={canLoadMore}
          isLoadingMore={isLoadingMore}
          loadMore={loadMore}
          resultsPerPage={count}
          resizeThumbnails
        />
      )}
    </VStack>
  )
}
