import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  VStack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { motion } from 'framer-motion'
import { sample } from 'lodash'
import { useCallback, useState } from 'react'

import { ImageSearchProviderKey } from 'modules/media/types/ImageSearch'
import { preventDefaultToAvoidBlur } from 'utils/handlers'
import { useDebounced, useEffectWhen } from 'utils/hooks'

import { ImageAttrs } from '../types/Image'
import { ImageLicensePicker } from './ImageLicensePicker'
import { useImageSearch } from './ImageSearch/hooks'
import { ImageSearchGrid } from './ImageSearch/ImageSearchGrid'

type ImageSearchProps = {
  currentImageUrl?: string
  updateAttributes: (attrs: Partial<ImageAttrs>) => void
  defaultQuery?: string
  randomQueries?: string[]
  provider: ImageSearchProviderKey
  enablePagination?: boolean
  enableSearch?: boolean
  enableLicenseFilter?: boolean
  resultsPerPage?: number
  resizeThumbnails?: boolean
}

const MotionIconButton = motion(IconButton)

const RESULTS_PER_PAGE = 18

export const ImageSearch = ({
  currentImageUrl,
  updateAttributes,
  defaultQuery,
  randomQueries,
  provider,
  enablePagination = true,
  enableSearch = true,
  enableLicenseFilter = false,
  resultsPerPage = RESULTS_PER_PAGE,
  resizeThumbnails = false,
}: ImageSearchProps) => {
  const [inputValue, setInputValue] = useState('')

  const {
    search,
    isLoading,
    loadMore,
    canLoadMore,
    hasError,
    imageResults,
    resetResults,
    searchQuery,
    license,
    updateLicense,
  } = useImageSearch({
    provider,
    enablePagination,
    resultsPerPage,
  })

  // When we change providers, reset the search
  useEffectWhen(
    () => {
      if (defaultQuery) {
        setInputValue(defaultQuery)
        search(defaultQuery)
        return
      }

      setInputValue('')
      if (randomQueries) {
        search(sample(randomQueries) || '')
      } else {
        resetResults()
      }
    },
    [defaultQuery, randomQueries, resetResults, search],
    [provider]
  )

  const randomizeQuery = useCallback(() => {
    const query = sample(randomQueries)
    if (!query) return
    search(query)
  }, [randomQueries, search])

  const doSearchDebounced = useDebounced(search, 1000)

  return (
    <VStack
      spacing={4}
      align="stretch"
      translate="no" // https://linear.app/gamma-app/issue/G-3050/reactdom-insertbefore-error-when-using-translation-plugin
    >
      <GammaTooltip
        placement="top"
        label={
          <Trans>
            To search for a different term, try using Unsplash or Giphy.
          </Trans>
        }
        isDisabled={enableSearch}
      >
        <InputGroup size="md">
          <InputLeftElement pointerEvents="none">
            <Box color="gray.400">
              <FontAwesomeIcon icon={regular('search')} />
            </Box>
          </InputLeftElement>
          <Input
            isDisabled={!enableSearch}
            placeholder={t`Find an image`}
            value={inputValue}
            onChange={(event) => {
              setInputValue(event.target.value)
              doSearchDebounced(event.target.value)
            }}
            data-testid="image-search-input"
          />

          {randomQueries && (
            <InputRightElement>
              <GammaTooltip placement="top" label={<Trans>Surprise me</Trans>}>
                <MotionIconButton
                  icon={<FontAwesomeIcon icon={regular('dice')} />}
                  aria-label="Surprise me"
                  variant="ghost"
                  onClick={randomizeQuery}
                  size="sm"
                  whileTap={{ y: '-5px' }}
                  onMouseDown={preventDefaultToAvoidBlur}
                />
              </GammaTooltip>
            </InputRightElement>
          )}
        </InputGroup>
      </GammaTooltip>
      {enableLicenseFilter && (
        <FormControl>
          <FormLabel fontSize="sm" fontWeight="semibold">
            <Trans>Image license</Trans>
          </FormLabel>
          <ImageLicensePicker value={license} onChange={updateLicense} />
        </FormControl>
      )}
      {searchQuery && (
        <ImageSearchGrid
          updateAttributes={updateAttributes}
          currentImageUrl={currentImageUrl}
          searchQuery={searchQuery}
          provider={provider}
          imageResults={imageResults}
          isLoading={isLoading}
          canLoadMore={canLoadMore}
          loadMore={loadMore}
          hasError={hasError}
          resultsPerPage={resultsPerPage}
          resizeThumbnails={resizeThumbnails}
        />
      )}
    </VStack>
  )
}
