import {
  Box,
  Button,
  FormControl,
  Heading,
  HStack,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from '@lingui/macro'
import { memo } from 'react'

import { ImageGenerateStylePreset } from 'modules/media/apis/imageGenerate'

type Preset = {
  preset: ImageGenerateStylePreset
  name: () => string
  imageUrl: string
}

type Section = {
  title: () => string
  presets: Preset[]
}

const STYLE_PRESETS: Section[] = [
  {
    title: () => t`Default`,
    presets: [
      {
        preset: 'None',
        imageUrl: 'https://cdn.gamma.app/ai-image-assets/default.jpg',
        name: () => t`Default`,
      },
      {
        preset: 'Texture',
        imageUrl: 'https://cdn.gamma.app/ai-image-assets/texture.jpg',
        name: () => t`Texture`,
      },
    ],
  },
  {
    title: () => t`Realistic`,
    presets: [
      {
        preset: 'Analog_film',
        imageUrl: 'https://cdn.gamma.app/ai-image-assets/analog_film.jpg',
        name: () => t`Analog film`,
      },
      {
        preset: 'Photography',
        imageUrl: 'https://cdn.gamma.app/ai-image-assets/photography.jpg',
        name: () => t`Photography`,
      },
    ],
  },
  {
    title: () => t`Artistic`,
    presets: [
      {
        preset: 'Anime',
        imageUrl: 'https://cdn.gamma.app/ai-image-assets/anime.jpg',
        name: () => t`Anime`,
      },
      {
        preset: 'Claymation',
        imageUrl: 'https://cdn.gamma.app/ai-image-assets/claymation.jpg',
        name: () => t`Claymation`,
      },
      // TODO add crochet
      // imageUrl: "https://cdn.gamma.app/ai-image-assets/amigurumi_crochet.jpg",
      {
        preset: 'Digital_Art_Concept_Art',
        imageUrl: 'https://cdn.gamma.app/ai-image-assets/digital_art.jpg',
        name: () => t`Digital Art`,
      },
      {
        preset: 'Ethereal_Fantasy_Art',
        imageUrl: 'https://cdn.gamma.app/ai-image-assets/fantasy_art.jpg',
        name: () => t`Fantasy`,
      },
      {
        preset: 'Isometric',
        imageUrl: 'https://cdn.gamma.app/ai-image-assets/isometric.jpg',
        name: () => t`Isometric`,
      },
      {
        preset: 'Line_Art',
        imageUrl: 'https://cdn.gamma.app/ai-image-assets/line_art.jpg',
        name: () => t`Line Art`,
      },
      // TODO add oil painting
      // imageUrl: "https://cdn.gamma.app/ai-image-assets/oil_painting.jpg",
      {
        preset: 'Low_Poly',
        imageUrl: 'https://cdn.gamma.app/ai-image-assets/low_poly.jpg',
        name: () => t`Low Poly`,
      },
      {
        preset: 'Vaporwave',
        imageUrl: 'https://cdn.gamma.app/ai-image-assets/vaporwave.jpg',
        name: () => t`Vaporwave`,
      },
      {
        preset: 'Origami',
        imageUrl: 'https://cdn.gamma.app/ai-image-assets/origami.jpg',
        name: () => t`Origami`,
      },
      {
        preset: 'Pixel_Art',
        imageUrl: 'https://cdn.gamma.app/ai-image-assets/pixel_art.jpg',
        name: () => t`Pixel Art`,
      },
    ],
  },
]

export const findStylePreset = (preset: ImageGenerateStylePreset): Preset => {
  let ret: Preset = STYLE_PRESETS[0].presets[0]

  STYLE_PRESETS.forEach((section) => {
    const found = section.presets.find((p) => p.preset === preset)
    if (found) {
      ret = found
      return
    }
  })

  return ret
}

type StylePresetMenuProps = {
  value: ImageGenerateStylePreset
  setValue: (val: ImageGenerateStylePreset) => void
  isDisabled?: boolean
}

export const StylePresetMenu = memo(
  ({ value, setValue, isDisabled }: StylePresetMenuProps) => {
    const { isOpen, onToggle, onClose } = useDisclosure()

    const preset = findStylePreset(value)

    return (
      <FormControl>
        <Popover
          placement="bottom-start"
          matchWidth={true}
          isOpen={isOpen}
          onClose={onClose}
          closeOnBlur={true}
        >
          <PopoverTrigger>
            <Button
              width="100%"
              type="button"
              boxShadow="none"
              onClick={onToggle}
              rightIcon={<FontAwesomeIcon icon={regular('chevron-down')} />}
              isDisabled={isDisabled}
              justifyContent="space-between"
            >
              <HStack spacing={2}>
                <Image
                  width="24px"
                  height="24px"
                  src={preset.imageUrl}
                  borderRadius="md"
                />
                <Box flex={1}>{preset.name()}</Box>
              </HStack>
            </Button>
          </PopoverTrigger>
          <PopoverContent
            width="100%"
            maxHeight="max(350px, 40vh)"
            overflowY="auto"
          >
            <PopoverBody>
              <VStack spacing={4}>
                {STYLE_PRESETS.map((section) => (
                  <VStack key={section.title()} align="start" spacing={0}>
                    <Heading size="sm" mb={2}>
                      {section.title()}
                    </Heading>
                    <SimpleGrid columns={{ base: 2, '2xl': 3 }} spacing={2}>
                      {section.presets.map((p) => (
                        <StylePresetButton
                          key={p.preset}
                          preset={p}
                          onClick={() => {
                            setValue(p.preset)
                            onClose()
                          }}
                          isSelected={p.preset === value}
                        />
                      ))}
                    </SimpleGrid>
                  </VStack>
                ))}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </FormControl>
    )
  }
)

StylePresetMenu.displayName = 'StylePresetMenu'

type StylePresetButtonProps = {
  preset: Preset
  onClick: () => void
  isSelected: boolean
}
export const StylePresetButton = ({
  preset,
  onClick,
  isSelected,
}: StylePresetButtonProps) => {
  return (
    <VStack
      _hover={{ cursor: !isSelected ? 'pointer' : 'default' }}
      onClick={onClick}
      borderRadius="md"
      spacing={1}
      p={2}
      border="1px"
      bgColor={isSelected ? 'trueblue.50' : 'transparent'}
      borderColor={isSelected ? 'trueblue.200' : 'transparent'}
    >
      <Image
        src={preset.imageUrl}
        width="100%"
        height="auto"
        borderRadius="md"
      />
      <Text color={isSelected ? 'trueblue.500' : 'gray.900'} fontSize="sm">
        {preset.name()}
      </Text>
    </VStack>
  )
}
