import { Spinner } from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import React, { Suspense } from 'react'

import { VideoEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'

import { MediaProviderType } from '../types/MediaProvider'
import LoomIcon from './icons/loom.svg'

const LoomPanel = React.lazy(() =>
  import('./LoomPanel').then((module) => ({ default: module.LoomPanel }))
)

const LoomPanelLazy = (props: any) => {
  return (
    <Suspense fallback={<Spinner size="md" />}>
      <LoomPanel {...props} />
    </Suspense>
  )
}

export const LoomProvider: MediaProviderType<VideoEmbedAttrs> = {
  key: 'video.loom',
  Panel: LoomPanelLazy,
  label: () =>
    t({
      message: `Loom recording`,
      comment: 'Loom refers to the video recording tool at loom.com',
    }),
  description: () =>
    t({
      message: `Add video and screen recordings, or attach an existing Loom`,
      comment: 'Loom refers to the video recording tool at loom.com',
    }),
  icon: duotone('video'),
  image: LoomIcon,
  urlRegex: /^https:\/\/www.loom.com/,
  rewriteEmbedUrl: (embedUrl) => {
    // http://support.loom.com/hc/en-us/articles/360002208317-How-to-embed-your-video-into-a-webpage-
    if (!embedUrl.includes('/embed/')) return embedUrl
    const url = new URL(embedUrl)
    url.searchParams.set('hideEmbedTopBar', 'true')
    return url.toString()
  },
  keywords: ['video', 'recording', 'loom', 'screen'],
}
