/* eslint-disable jsx-a11y/accessible-emoji */
import { Alert, AlertDescription, Button, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import { AICreditsModal } from 'modules/credits/components/AICreditsModal'
import { selectAvailableCredits } from 'modules/credits/reducer'
import { useModalDisclosure } from 'modules/modal_state/hooks/useModalDisclosure'
import { useAppSelector } from 'modules/redux'

export const NoCredits = () => {
  const { isOpen, onOpen, onClose } = useModalDisclosure({
    id: 'ai-credits-generate-image',
  })
  const availableCredits = useAppSelector(selectAvailableCredits)

  return (
    <>
      <Alert status="error">
        <VStack>
          <AlertDescription>
            <strong>
              ❗️<Trans>You don't have enough credits.</Trans>
            </strong>{' '}
            <Trans>
              Earn more credits to continue using Gamma's AI generated images
              feature.
            </Trans>
          </AlertDescription>
          <Button
            onClick={onOpen}
            leftIcon={
              <span role="img" aria-label="credits">
                💰
              </span>
            }
          >
            <Trans>Earn more credits</Trans>
          </Button>
        </VStack>
      </Alert>
      <AICreditsModal
        credits={availableCredits}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
      />
    </>
  )
}
