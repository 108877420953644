import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Box,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react'
import { Plural, t, Trans } from '@lingui/macro'

import { useFeatureFlag } from 'modules/featureFlags'
import {
  GAMMA_ARTIFACT_PROPER_NOUN,
  GAMMA_PROPER_NOUN,
  GAMMA_PRO_PROPER_NOUN,
} from 'modules/i18n/properNouns'
import {
  AUTOCOMPLETE_INPUT_SHORTCUT,
  AUTOCOMPLETE_SLASH_SHORTCUT,
} from 'modules/tiptap_editor/extensions/AI/Autocomplete/constants'

export const AICreditsFAQ = ({
  onContactUsClick,
  ...rest
}: {
  onContactUsClick?: () => void
} & AccordionProps) => {
  const aiDebits = useFeatureFlag('aiDebits')
  const referralsEnabled = useFeatureFlag('ai-credits').enabled
  const aiGeneration = aiDebits.wizardCreation
  const aiEditing = aiDebits.chatSuggestion
  const aiAutocomplete = aiDebits.autocomplete
  const aiImages = aiDebits.generateImage
  const canAutocomplete = useFeatureFlag('aiAutocomplete')
  const canGenerateImages = useFeatureFlag('aiGeneratedImages')

  return (
    <Accordion allowMultiple {...rest}>
      <FAQAccordionItem
        accordionLabel={t`How many credits does it cost to use AI?`}
      >
        <UnorderedList>
          <ListItem>
            <strong>
              <Trans>Create with AI:</Trans>
            </strong>{' '}
            <Plural
              value={aiGeneration}
              one={`# credit per ${GAMMA_ARTIFACT_PROPER_NOUN} you create.`}
              other={`# credits per ${GAMMA_ARTIFACT_PROPER_NOUN} you create.`}
            />
          </ListItem>
          <ListItem>
            <strong>
              <Trans>Chat with AI:</Trans>
            </strong>{' '}
            <Plural
              value={aiEditing}
              one={`# credit per AI suggestion (eg. adding a card, finding images, rewriting
              content)`}
              other={`# credits per AI suggestion (eg. adding a card, finding images, rewriting
                content).`}
            />
            <Trans>You are not charged for errors or clarifications.</Trans>
          </ListItem>
          {canGenerateImages && (
            <ListItem>
              <strong>
                <Trans>AI images:</Trans>
              </strong>{' '}
              <Plural
                value={aiImages}
                one={`# credit per image generated`}
                other={`# credits per image generated`}
              />
            </ListItem>
          )}
          {canAutocomplete && (
            <ListItem>
              <strong>
                <Trans>Continue with AI:</Trans>
              </strong>{' '}
              <Plural
                value={aiAutocomplete}
                one={`# credit each time you use ${AUTOCOMPLETE_SLASH_SHORTCUT} or ${AUTOCOMPLETE_INPUT_SHORTCUT}.`}
                other={`# credits each time you use ${AUTOCOMPLETE_SLASH_SHORTCUT} or ${AUTOCOMPLETE_INPUT_SHORTCUT}.`}
              />
            </ListItem>
          )}
        </UnorderedList>
      </FAQAccordionItem>

      {referralsEnabled && (
        <FAQAccordionItem accordionLabel={t`How can I earn more credits?`}>
          <Trans>
            You can get unlimited credits by subscribing to{' '}
            {GAMMA_PRO_PROPER_NOUN}. Alternatively, you can refer others to{' '}
            {GAMMA_PROPER_NOUN} using your unique referral link, and you'll both
            earn credits when they sign up.
          </Trans>
        </FAQAccordionItem>
      )}
    </Accordion>
  )
}

const FAQAccordionItem = ({
  accordionLabel,
  children,
}: {
  accordionLabel: string
  children: React.ReactNode
}) => (
  <AccordionItem>
    <h2>
      <AccordionButton px={2} py={2} fontSize="sm">
        <Box as="span" flex="1" textAlign="left" fontWeight={500}>
          {accordionLabel}
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4} px={2} fontSize="sm">
      {children}
    </AccordionPanel>
  </AccordionItem>
)
