import { ImageMetadata } from './ImageMetadata'

export interface ImageUploadResult {
  src: string
  meta: ImageMetadata
  name: string
  uploadResultStep?: UploadResultStep
  thumbnail?: string
  croppedFace?: string
}

export enum UploadStatus {
  Done,
  Uploading,
  Error,
  Queued,
}

export type UploadResultStep = 'original' | 'optimized'
