import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { IconSearch } from '../components/IconSearch'
import {
  MediaProviderType,
  MediaProviderPanelProps,
} from '../types/MediaProvider'

const ICON_RANDOM_QUERIES = [
  'animals',
  'arrows',
  'business',
  'party',
  'nature',
  'food',
]

const IconPanel = ({
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps) => {
  return (
    <IconSearch
      updateAttributes={updateAttributes}
      currentAttributes={currentAttributes}
      currentIconUrl={currentAttributes.src}
      defaultQuery={currentAttributes.query || ''}
      randomQueries={ICON_RANDOM_QUERIES}
    />
  )
}

export const IconProvider: MediaProviderType = {
  key: 'image.icon',
  Panel: IconPanel,
  label: () => t`Icons`,
  icon: duotone('icons'),
  keywords: ['icon', 'symbol', 'picture'],
}
