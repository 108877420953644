import { Badge, BadgeProps } from '@chakra-ui/react'
import { ProductFeatures } from '@gammatech/authorization'

import { ExistingWorkspace } from 'modules/api'

import { useCanUseProductFeature } from '../hooks/productFeatures'
import { ProductKey } from '../types'
import { getProductForWorkspace, getProductRequiredForFeature } from '../utils'
import { getProductDetails } from './UpsellModal/constants'

type ProProductBadgeProps = {
  workspace?: ExistingWorkspace
} & BadgeProps

export const PaidProductBadge = ({
  product = 'pro',
  ...badgeProps
}: Omit<ProProductBadgeProps, 'workspace'> & {
  product?: ProductKey
}) => {
  return (
    <Badge
      size="sm"
      variant="solid"
      bottom={0}
      right={0}
      bg="linear-gradient(97deg, #3300D9 -10%, #9D20C9 56.82%, #DF7A6C 120%)"
      color="whiteAlpha.900"
      textShadow="0px 0px 1px rgba(0, 0, 0, .5)"
      fontSize="11px"
      {...badgeProps}
    >
      {getProductDetails()[product].name}
    </Badge>
  )
}

export const CurrentProductBadge = ({
  workspace,
  ...badgeProps
}: ProProductBadgeProps) => {
  const product = getProductForWorkspace(workspace)

  if (!product) {
    return null
  }

  return <PaidProductBadge variant="subtle" {...badgeProps} product={product} />
}

export const UpsellProductBadge = ({
  workspace,
  productFeature,
  ...badgeProps
}: ProProductBadgeProps & {
  productFeature: ProductFeatures
}) => {
  const canUseFeature = useCanUseProductFeature(productFeature, workspace)
  const productRequired = getProductRequiredForFeature(productFeature)

  if (canUseFeature || productRequired === null) {
    return null
  }

  return (
    <PaidProductBadge
      variant="solid"
      product={productRequired}
      {...badgeProps}
    />
  )
}
