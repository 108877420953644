export type StreamErrorCode =
  | 'timeout'
  | 'stream_error'
  | 'on_open'
  | 'unexpected_close'

export class StreamError extends Error {
  readonly name: string = 'StreamError'
  readonly code: StreamErrorCode = 'stream_error'

  constructor(message: string, code?: StreamErrorCode) {
    super(message)

    if (code) {
      this.code = code
    }
  }
}

export class StreamTimeoutError extends StreamError {
  readonly name = 'StreamTimeoutError'
  readonly code = 'timeout'

  constructor(message: string) {
    super(message)
  }
}

export class StreamOnOpenError extends StreamError {
  readonly name = 'StreamOnOpenError'
  readonly code = 'on_open'

  constructor(message: string) {
    super(message)
  }
}

export class StreamUnexpectedCloseError extends StreamError {
  readonly name = 'StreamUnexpectedCloseError'
  readonly code = 'unexpected_close'

  constructor(message: string) {
    super(message)
  }
}
