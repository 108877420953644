import { CSSObject } from '@emotion/react'
import { isNodeEmpty, NodeWithPos } from '@tiptap/core'
import { Fragment, Node, ResolvedPos, Schema, Slice } from 'prosemirror-model'
import { Transaction } from 'prosemirror-state'

import {
  BackgroundOptions,
  BackgroundType,
} from 'modules/tiptap_editor/styles/types'
import { findDirectChildren } from 'modules/tiptap_editor/utils'

import { WrapNodesAnnotationEvent } from '../../Annotatable/AnnotationExtension/types'
import { CardLayout, DisplayLayout } from '../types'
import { isCardLayoutItemNode } from '../utils'
import { CardLayoutPreset, LAYOUT_PRESETS } from './layoutPresets'
import {
  CardAccentLayoutItemAttrs,
  CardLayoutItemAttrs,
  CardLayoutItemIds,
} from './types'

const isSideLayout = (layout: CardLayout) => ['left', 'right'].includes(layout)

export const getDisplayLayout = ({
  layout,
  parentCards,
  isMobileDevice,
}: {
  layout: CardLayout
  parentCards: Node[]
  isMobileDevice: boolean
}): DisplayLayout => {
  if (isMobileDevice && isSideLayout(layout)) {
    return 'mobileStacked'
  }

  if (isSideLayout(layout) && checkInsideSideLayout(parentCards)) {
    return 'blank'
  }

  return layout
}

export const checkInsideSideLayout = (parentCards: Node[]) => {
  return parentCards.some((parent) => isSideLayout(parent.attrs.layout))
}

export const getCardLayoutChildrenSx = (layout: DisplayLayout) => {
  const preset = findLayoutPreset(layout)
  const childSx: CSSObject = {}
  Object.entries(preset.items).forEach(([itemId, item]) => {
    const style = item?.style ?? {
      gridArea: itemId,
    }
    childSx[`> [data-layout-item-id="${itemId}"]`] = style
  })
  return childSx
}

export const findLayoutPreset = (layout?: DisplayLayout) => {
  return LAYOUT_PRESETS[layout ?? 'blank'] || LAYOUT_PRESETS['blank']
}

export const getCardLayoutItemsFromResolvedPos = (
  $card: ResolvedPos
): Partial<{ [id in CardLayoutItemIds]: NodeWithPos }> => {
  const items: Partial<{ [id in CardLayoutItemIds]: NodeWithPos }> = {}

  const cardPos = $card.pos
  const cardNode = $card.nodeAfter
  if (!cardNode) {
    console.error(
      `[getCardLayoutItems] could not find card node at pos ${cardPos}`
    )
    return items
  }

  cardNode.descendants((n, pos) => {
    if (!isCardLayoutItemNode(n)) {
      // dont descend further into card
      return false
    }
    items[n.attrs.itemId] = {
      node: n,
      pos: cardPos + 1 + pos,
    }
    return
  })
  return items
}

export const getCardLayoutItems = (
  tr: Transaction,
  cardPos: number
): Partial<{ [id in CardLayoutItemIds]: NodeWithPos }> => {
  return getCardLayoutItemsFromResolvedPos(tr.doc.resolve(cardPos))
}

const wrapWithBodyLayoutItem = (
  tr: Transaction,
  card: NodeWithPos,
  schema: Schema
) => {
  const $insideCard = tr.doc.resolve(card.pos + 1)
  const range = $insideCard.blockRange(tr.doc.resolve($insideCard.end()))
  if (!range) {
    return
  }
  tr.wrap(range, [
    {
      type: schema.nodes.cardLayoutItem,
    },
  ]).setMeta('annotationEvent', <WrapNodesAnnotationEvent>{
    type: 'wrap-nodes',
    start: range.start,
    end: range.end,
    level: 1,
  })
}

export const prependBodyLayoutItem = (
  tr: Transaction,
  cardPos: number,
  schema: Schema,
  attrs?: Partial<CardLayoutItemAttrs>
) => {
  const toInsert = schema.nodes.cardLayoutItem.createAndFill({
    itemId: 'body',
    ...attrs,
  })
  tr.insert(cardPos + 1, toInsert!)
}

export const prependAccentLayoutItem = (
  tr: Transaction,
  cardPos: number,
  schema: Schema,
  attrs?: Partial<CardLayoutItemAttrs>
) => {
  const toInsert = schema.nodes.cardAccentLayoutItem.createAndFill({
    itemId: 'accent',
    background: { type: BackgroundType.NONE },
    ...attrs,
  })
  tr.insert(cardPos + 1, toInsert!)
}

export const appendLayoutItem = (
  tr: Transaction,
  cardPos: number,
  schema: Schema,
  itemId: CardLayoutItemIds,
  content?: Fragment,
  attrs?: Partial<CardLayoutItemAttrs>
) => {
  const end = tr.doc.resolve(cardPos + 1).end()
  const toInsert = schema.nodes.cardLayoutItem.createAndFill(
    {
      itemId,
      ...attrs,
    },
    content ? trimContent(content) : undefined
  )
  tr.insert(end, toInsert!)
}

export const appendContentToLayoutItem = (
  tr: Transaction,
  nodePos: number,
  content?: Fragment
) => {
  if (!content) {
    return
  }
  const trimmed = trimContent(content)
  const firstChildPos = nodePos + 1
  const $firstChild = tr.doc.resolve(firstChildPos)
  if (trimmed.content.length === 0) {
    return
  }
  if ($firstChild.nodeAfter && $firstChild.nodeAfter.content.size === 0) {
    // there's no content replace the first one with content
    tr.replace(
      firstChildPos,
      firstChildPos + $firstChild.nodeAfter.nodeSize,
      new Slice(trimmed, 1, 1)
    )
  } else {
    tr.insert($firstChild.end(), trimContent(content))
  }
}

export const deleteLayoutNode = (tr: Transaction, item: NodeWithPos) => {
  tr.delete(item.pos, item.pos + item.node.nodeSize)
}

const trimContent = (frag: Fragment): Fragment => {
  let start: number = 0
  let end: number = frag.size

  // ltrim
  for (let i = 0; start < frag.size; i++) {
    const child = frag.content[i]
    if (child.isAtom) {
      break
    }
    if (
      child.childCount === 0 ||
      (child.childCount === 1 && isNodeEmpty(child.firstChild!))
    ) {
      start += child.nodeSize
      continue
    }
    break
  }

  // TODO figure out >=
  for (let i = 1; end > 0; i++) {
    const child = frag.content[frag.content.length - i]
    if (child.isAtom) {
      break
    }
    if (
      child.childCount === 0 ||
      (child.childCount === 1 && isNodeEmpty(child.firstChild!))
    ) {
      end -= child.nodeSize
      continue
    }
    break
  }

  return frag.cut(start, end)
}

/**
 * Ensure that the card contents conforms to the existing preset, so
 * that we are starting from a known good state
 */
export const ensureCardLayoutItems = (
  tr: Transaction,
  cardPos: number,
  schema: Schema,
  preset: CardLayoutPreset
) => {
  const cardNode = tr.doc.nodeAt(cardPos)!
  const layoutItems = getCardLayoutItems(tr, cardPos)

  // handle the case where there are no layout items
  if (Object.keys(layoutItems).length === 0) {
    // dont have any items wrap all content in body LayoutItem
    // TODO: handle annotation move
    wrapWithBodyLayoutItem(
      tr,
      {
        node: cardNode,
        pos: cardPos,
      },
      schema
    )

    if (preset.items.accent) {
      prependAccentLayoutItem(tr, cardPos, schema)
    }
    return
  }

  if (preset.items.body && !layoutItems.body) {
    prependBodyLayoutItem(tr, cardPos, schema)
  }

  if (preset.items.accent && !layoutItems.accent) {
    prependAccentLayoutItem(tr, cardPos, schema)
  } else if (
    layoutItems.accent &&
    layoutItems.accent.node.type.name === 'cardLayoutItem'
  ) {
    // convert old cardLayoutItem to cardLayoutItemView
    replaceCardLayoutWithCardAccentLayoutItem(
      tr,
      layoutItems.accent.pos,
      layoutItems.accent.node,
      schema
    )
  }

  // handle the case where there are layout items but do not match
}

export const replaceCardLayoutWithCardAccentLayoutItem = (
  tr: Transaction,
  pos: number,
  node: Node,
  schema: Schema
) => {
  tr.replaceRangeWith(
    pos,
    pos + node.nodeSize,
    schema.nodes.cardAccentLayoutItem.createAndFill({
      ...node.attrs,
    })
  )
}

export const isAccentCardLayoutItem = (node: Node): boolean => {
  return (
    node.type.name === 'cardAccentLayoutItem' && node.attrs.itemId === 'accent'
  )
}

export const isBodyCardLayoutItem = (node: Node): boolean => {
  return node.type.name === 'cardLayoutItem' && node.attrs.itemId === 'body'
}

export const getStartingIndexForBackgroundSelection = (
  currentAccentBackground: BackgroundOptions,
  themeAccentBackgrounds?: BackgroundOptions[]
): number => {
  if (!themeAccentBackgrounds) {
    return 0
  }

  const currentBackgroundIndex = themeAccentBackgrounds.findIndex(
    (bg) => bg.accentId === currentAccentBackground.accentId
  )

  return currentBackgroundIndex === -1 || currentBackgroundIndex === undefined
    ? 0
    : (currentBackgroundIndex + 1) % themeAccentBackgrounds.length
}

export const findCardAccentBackground = (
  node: Node
): BackgroundOptions | undefined => {
  const accent = findDirectChildren(node, isAccentCardLayoutItem)[0]
  if (!accent) return undefined
  const attrs = accent.node.attrs as CardAccentLayoutItemAttrs
  return attrs.background
}
