import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'

import { EmbedPanel } from '../components/EmbedPanel'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import Icon from './icons/google-form.svg'

const Panel = (props: MediaProviderPanelProps<WebEmbedAttrs>) => {
  return (
    <EmbedPanel {...props} urlPlaceholder="https://docs.google.com/forms/..." />
  )
}

export const GoogleFormProvider: MediaProviderType = {
  label: () => 'Google Form',
  key: 'embed.google.form',
  description: () => t`Embed an interactive Google Form`,
  icon: brands('google-drive'),
  image: Icon,
  urlRegex: /^https?:\/\/docs.google.com\/forms/,
  Panel,
  canEmbedPrivate: true,
  keywords: ['google form', 'form', 'survey', 'questionnaire'],
  defaultDisplayStyle: 'inline',
  inlineInteractive: true,
  categories: ['form'],
  iframeOptions: {
    allowPopups: true,
  },
}
