import { t } from '@lingui/macro'
import { useCallback, useState } from 'react'

import { useFeatureFlag } from 'modules/featureFlags'
import {
  GenerateImageOptions,
  ImageGenerateModel,
  ImageGenerateStylePreset,
} from 'modules/media/apis/imageGenerate'
import { usePromptHistory } from 'modules/media/components/AIGeneratedImages/hooks'
import { findStylePreset } from 'modules/media/components/AIGeneratedImages/StylePresetMenu'
import { ImageAttrs } from 'modules/media/types/Image'

export type AspectRatio = {
  key: AspectRatioKey
  name: () => string
  width: number
  height: number
}
export type AspectRatioKey = 'square' | 'landscape' | 'portrait'

// These should match SDXL's recommended aspect ratios
// https://platform.stability.ai/docs/features/api-parameters#about-dimensions
export const ASPECT_RATIOS: Record<AspectRatioKey, AspectRatio> = {
  square: {
    key: 'square',
    name: () => t`Square`,
    width: 1024,
    height: 1024,
  },
  landscape: {
    key: 'landscape',
    name: () => t`Landscape`,
    width: 1216,
    height: 832,
  },
  portrait: {
    key: 'portrait',
    name: () => t`Portrait`,
    width: 896,
    height: 1152,
  },
}

export const useImageGenerateConfig = () => {
  const model: ImageGenerateModel = 'stable-diffusion-xl-v1-0'
  const steps = 30
  const count = useFeatureFlag('aiGeneratedImagesCount')

  const { prompt, addPrompt, promptPageInfo, nextPrompt, prevPrompt } =
    usePromptHistory()
  const [aspectRatio, setAspectRatio] = useState<AspectRatioKey>('square')
  const [stylePreset, setStylePreset] =
    useState<ImageGenerateStylePreset>('None')

  const ratio = ASPECT_RATIOS[aspectRatio]
  const imageGenerateConfig: Omit<
    GenerateImageOptions,
    'interactionId' | 'workspaceId' | 'themeId'
  > = {
    prompt,
    model,
    style_preset: stylePreset,
    height: ratio.height,
    width: ratio.width,
    steps,
    count,
  }

  const loadImageAiParams = useCallback(
    (attrs: ImageAttrs, loadOperation: 'select' | 'load') => {
      if (!attrs.aiParams) return

      const { prompt: p, height, width, model, style_preset } = attrs.aiParams

      const found = Object.values(ASPECT_RATIOS).find(
        (r) => r.height === height && r.width === width
      )
      if (found) {
        setAspectRatio(found.key)
      }
      if (p) {
        addPrompt(p, loadOperation)
      }

      if (style_preset) {
        setStylePreset(
          findStylePreset(style_preset as ImageGenerateStylePreset).preset
        )
      }
    },
    [addPrompt]
  )

  return {
    prompt,
    addPrompt,
    promptPageInfo,
    nextPrompt,
    prevPrompt,
    stylePreset,
    setStylePreset,
    aspectRatio,
    setAspectRatio,
    imageGenerateConfig,
    count,
    loadImageAiParams,
  }
}
