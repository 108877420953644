import {
  ChatCompletionInputParams,
  ChatCompletionProvider,
  ChatMessage,
} from 'modules/api'

import { ChatCompletionParams } from '../openai'
import {
  ChatCompletionGenerateResult,
  ChatCompletionPrompt,
  ChatCompletionPromptGenerateOptions,
} from './ChatCompletionPrompt'

const DEFAULT_CHAT_COMPLETION_PARAMS: ChatCompletionInputParams = {
  model: 'gpt-3.5-turbo',
  provider: ChatCompletionProvider.Azure,
}

export abstract class BaseChatCompletionPrompt<T extends string>
  implements ChatCompletionPrompt<T>
{
  protected params: ChatCompletionParams

  constructor({ params }: { params?: ChatCompletionParams }) {
    this.params = {
      ...DEFAULT_CHAT_COMPLETION_PARAMS,
      ...params,
    }
  }

  prepare({
    variables,
    history = [],
    params,
  }: ChatCompletionPromptGenerateOptions<T>): ChatCompletionGenerateResult {
    const systemMsg = this.getSystemChatMessage(variables)
    const userMsg = this.getUserChatMessage(variables)

    return {
      messages: [systemMsg, ...history, userMsg],
      params: {
        ...this.params,
        ...params,
      },
    }
  }

  protected abstract getSystemChatMessage(
    variables: Record<T, any>
  ): ChatMessage

  protected abstract getUserChatMessage(variables: Record<T, any>): ChatMessage
}
