import { useCallback } from 'react'

import { ChatCompletionPrompt } from 'modules/ai/prompt/ChatCompletionPrompt'
import {
  createChatCompletionStream,
  createJsxGenerationStream,
} from 'modules/ai/stream/streamChatCompletionApi'
import { JsxGenerationInput } from 'modules/ai/stream/types'
import { consumeStream } from 'modules/ai/stream/utils'
import { generateAIInteractionId } from 'modules/ai/track'
import {
  StreamMessageCbs,
  StreamMessageTextAccumulator,
} from 'modules/ai/transform/StreamTransformers/StreamMessageTextAccumulator'
import { ChatCompletionInputParams } from 'modules/api'
import {
  JsxPromptKey,
  JsxPrompts,
} from 'modules/api/generated/jsx-prompt-types'

type StreamingTextGenerateProps<V extends string> = {
  variables: Record<V, any>
  interactionId?: string
  params?: ChatCompletionInputParams
  workspaceId?: string
}

type CancelFn = () => void

export type StreamingGenerateReturn = {
  cancel: CancelFn
  promise: Promise<string>
}

type StreamingChatCompletionOpts = {
  timeout?: number | null
} & StreamMessageCbs

export const useStreamingChatCompletion = <V extends string>(
  prompt: ChatCompletionPrompt<V>,
  opts: StreamingChatCompletionOpts
): {
  generate: (input: StreamingTextGenerateProps<V>) => StreamingGenerateReturn
} => {
  const generate = useCallback(
    ({
      variables,
      interactionId,
      params,
      workspaceId,
    }: StreamingTextGenerateProps<V>) => {
      interactionId = interactionId || generateAIInteractionId()

      const promptInput = prompt.prepare({
        variables,
        params,
        interactionId,
      })
      const { cancel, stream: apiStream } = createChatCompletionStream(
        {
          ...promptInput,
          workspaceId,
          interactionId,
        },
        {
          timeout: opts.timeout,
        }
      )

      const accumStream = new StreamMessageTextAccumulator(opts)
      const stream = apiStream.pipeThrough(accumStream)

      const promise = new Promise<string>((resolve, reject) => {
        consumeStream(stream, {
          onError(err) {
            opts.onError?.(err)
            reject(err)
          },
          onDone() {
            resolve(accumStream.getContent())
          },
        })
      })

      return { cancel, promise }
    },
    [opts, prompt]
  )

  return { generate }
}

type StreamingJsxGenerateProps<K extends JsxPromptKey> = {
  variables: JsxPrompts[K]['variables']
  interactionId?: string
  workspaceId: string
}

export const useStreamingJsxGeneration = <K extends JsxPromptKey>(
  promptKey: K,
  opts: StreamingChatCompletionOpts
): {
  generate: (input: StreamingJsxGenerateProps<K>) => StreamingGenerateReturn
} => {
  const generate = useCallback(
    ({
      variables,
      interactionId,
      workspaceId,
    }: StreamingJsxGenerateProps<K>) => {
      interactionId = interactionId || generateAIInteractionId()

      const { cancel, stream: apiStream } = createJsxGenerationStream<K>(
        {
          promptKey,
          variables,
          interactionId,
          workspaceId,
        },
        {
          timeout: opts.timeout,
        }
      )

      const accumStream = new StreamMessageTextAccumulator(opts)
      const stream = apiStream.pipeThrough(accumStream)

      const promise = new Promise<string>((resolve, reject) => {
        consumeStream(stream, {
          onError(err) {
            opts.onError?.(err)
            reject(err)
          },
          onDone() {
            resolve(accumStream.getContent())
          },
        })
      })

      return { cancel, promise }
    },
    [opts, promptKey]
  )

  return { generate }
}
