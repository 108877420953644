import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { featureFlags } from 'modules/featureFlags'
import { getCanUseProductFeature } from 'modules/monetization/hooks/productFeatures'

import { AIGeneratedImagesPanel } from '../components/AIGeneratedImages/AIGeneratedImagesPanel'
import { MediaProviderType } from '../types/MediaProvider'

export const AIGeneratedImageProvider: MediaProviderType = {
  key: 'image.ai-generated',
  Panel: AIGeneratedImagesPanel,
  checkDisabled: () => {
    const canUseFeature = getCanUseProductFeature('ai_generated_images')
    const hasFeatureFlag = featureFlags.get('aiGeneratedImages')
    return !(canUseFeature || hasFeatureFlag)
  },
  label: () => t`Generate images with AI`,
  icon: solid('sparkle'),
  description: () => t`Create original illustrations and graphics with AI`,
  // image: AIImageIcon,
  keywords: ['ai', 'image', 'illustration', 'graphics', 'generate'],
  resizeToFit: true,
  badge: () => t`New`,
}
