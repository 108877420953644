import {
  Box,
  Button,
  Center,
  Fade,
  GridItem,
  Link,
  Skeleton,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import React, { memo } from 'react'

import { ImageSearchResult } from 'modules/media/types/ImageSearch'
import {
  resizeAndProxyImageUrl,
  backgroundImageFromUrls,
  THUMBNAIL_RESIZE_PARAMS,
} from 'utils/image'

type ImageSearchGridItemProps = {
  image: ImageSearchResult
  isSelected: boolean
  onImageClick: (image: ImageSearchResult) => void
  provider: string
  resizeThumbnail?: boolean
}

export const ImageSearchGridItem = memo(
  ({
    image,
    isSelected,
    onImageClick,
    resizeThumbnail,
  }: ImageSearchGridItemProps) => {
    const thumbnailUrl = resizeThumbnail
      ? resizeAndProxyImageUrl(image.thumbnailUrl, THUMBNAIL_RESIZE_PARAMS)
      : image.thumbnailUrl
    const backgroundImage = backgroundImageFromUrls(
      thumbnailUrl,
      image.thumbnailUrl
    )

    return (
      <GridItem key={image.id || image.imageUrl}>
        <Button
          borderRadius="base"
          display="block"
          variant="ghost"
          width="100%"
          opacity={1}
          height="100px"
          textAlign="left"
          pos="relative"
          p={0}
          _hover={{ shadow: 'outline' }}
          _active={{ shadow: 'outline' }}
          transition="box-shadow .2s ease"
          cursor="pointer"
          onClick={() => onImageClick(image)}
          backgroundImage={backgroundImage}
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition="center"
          sx={{
            _focusVisible: {
              '.attribution-container': {
                opacity: 1,
              },
            },
            _hover: {
              '.attribution-container': {
                opacity: 1,
              },
            },
          }}
          data-testid="image-search-grid-item"
        >
          <Fade in={isSelected}>
            <Box
              pointerEvents="none"
              color="white"
              pos="absolute"
              inset={0}
              bg="rgba(0,0,0,.5)"
              borderRadius="base"
            >
              <Center h="100%" w="100%">
                <FontAwesomeIcon icon={regular('check')} />
              </Center>
            </Box>
          </Fade>

          {/* We need a fallback */}
          {!image.thumbnailUrl && (
            <GridItem width="100%">
              <Skeleton height="80px" borderRadius="base" />
            </GridItem>
          )}
          <Box
            left={0}
            right={0}
            bottom={0}
            p={2}
            pos="absolute"
            color="white"
            fontSize="xs"
            textOverflow="ellipsis"
            overflow="hidden"
            bg="linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)"
            borderBottomRadius="base"
            className="attribution-container"
            opacity={0}
            transitionProperty="common"
            transitionDuration="normal"
          >
            {image.attributionUrl && image.credit && (
              <GammaTooltip label={image.credit} placement="left">
                <Link
                  mr={1}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={image.attributionUrl}
                  tabIndex={-1}
                >
                  <FontAwesomeIcon
                    transform="shrink-4"
                    icon={regular('external-link-alt')}
                  />
                </Link>
              </GammaTooltip>
            )}
            {image.credit}
          </Box>
        </Button>
      </GridItem>
    )
  }
)

ImageSearchGridItem.displayName = 'ImageSearchGridItem'
