import { JSONContent } from '@tiptap/core'
import { nanoid } from 'nanoid'

import { config } from 'config'
import { ChatCompletionProvider } from 'modules/api'
import {
  GenerateImageOptions,
  ImageGenerateProvider,
} from 'modules/media/apis/imageGenerate'
import { AIImageGenerateParams } from 'modules/media/types/Image'
import { ImageSearchProviderKey } from 'modules/media/types/ImageSearch'
import { analytics, SegmentEvents } from 'modules/segment'

export type AIRequestProps = {
  interactionId?: string
  interface: 'chat' | 'wizard' | 'autocomplete' | 'generate-image'
  isResuming?: boolean
  provider?: ChatCompletionProvider | ImageGenerateProvider
  streaming?: boolean
  classification?: string
  inputMessage?: string
  inputContent?: string
  inputData?: Record<string, any>
  docId?: string
  cardId?: string
  gitSHA?: string
  // things for generate image
  generateImageOptions?: Omit<
    GenerateImageOptions,
    'workspaceId' | 'interactionId'
  >
}

export type AIStreamStartProps = AIRequestProps & {
  timeToStart: number
}

export type AIAbortProps = AIRequestProps & {
  abortReason: string
  timeToAbort: number
}

export type AIResponseProps = AIRequestProps & {
  outputMessage?: string
  outputContent?: string
  latency?: number
  rawResponse?: string
  generateImageUrls?: string[]
}

export type AIRequestCompleteProps = AIResponseProps & {
  timeToComplete: number
  imageProvider?: ImageSearchProviderKey
  imageSearches?: string[]
  numImageSearches?: number
  ydocToJsonRatio?: number
}

export type AISuggestionAppliedProps = AIRequestProps & {
  suggestionContent?: string | JSONContent
  suggestionType: string
  suggestionLabel: string
  autoApplied: boolean
  outputMessage?: string
  outputContent?: string
}

export type AIErrorProps = AIRequestProps & {
  errorName: string
  errorMessage: string
  errorCode?: string
  outputMessage: string
  rawResponse?: string
  latency: number
}

export type DocRatingKey = 'bad' | 'meh' | 'good'

export type AIImageRatingKey = 'poor' | 'good'

type AIDocRatingProps = {
  rating: DocRatingKey
  doc_id: string
  interactionId: string
}

type AIImageRatingProps = {
  aiParams: AIImageGenerateParams
  rating: AIImageRatingKey
  imageUrl: string
  interactionId: string
}

type AIImageFeedbackProps = {
  aiParams: AIImageGenerateParams
  feedback: string
  imageUrl: string
  interactionId: string
}

type AIDocFeedbackProps = {
  feedback: string
  doc_id: string
  interactionId: string
}

export type AIRetryProps = AIRequestProps & {
  rawResponse?: string
  retriesRemaining: number
}

export const trackAIRequestSent = (properties: AIRequestProps) => {
  analytics.track(SegmentEvents.AI_REQUEST_SENT, {
    ...properties,
    gitSHA: config.GIT_SHA,
  })
}

export const trackAIRequestComplete = (properties: AIRequestCompleteProps) => {
  analytics.track(SegmentEvents.AI_REQUEST_COMPLETE, {
    ...properties,
    gitSHA: config.GIT_SHA,
  })
}

/**
 * Called when the user does a behavior that will prevent ai.request.complete
 * to ever happen.  Such as leaving the doc while a stream is happening
 */
export const trackAIRequestAbort = (properties: AIAbortProps) => {
  analytics.track(SegmentEvents.AI_REQUEST_ABORT, {
    ...properties,
    gitSHA: config.GIT_SHA,
  })
}

/**
 * Called when the API response fully compes back
 */
export const trackAIRequestStreamStart = (properties: AIStreamStartProps) => {
  analytics.track(SegmentEvents.AI_REQUEST_STREAM_START, {
    ...properties,
    gitSHA: config.GIT_SHA,
  })
}

/**
 * Called when the API response fully compes back
 */
export const trackAIRequestResponse = (properties: AIResponseProps) => {
  analytics.track(SegmentEvents.AI_REQUEST_RESPONSE, {
    ...properties,
    gitSHA: config.GIT_SHA,
  })
}

export const trackAISuggestionApplied = (
  properties: AISuggestionAppliedProps
) => {
  analytics.track(SegmentEvents.AI_SUGGESTION_APPLY, {
    ...properties,
    gitSHA: config.GIT_SHA,
  })
}

export const trackAIRequestError = (properties: AIErrorProps) => {
  analytics.track(SegmentEvents.AI_REQUEST_ERROR, {
    ...properties,
    gitSHA: config.GIT_SHA,
  })
}

export const trackAIRequestRetry = (properties: AIRetryProps) => {
  analytics.track(SegmentEvents.AI_REQUEST_RETRY, {
    ...properties,
    gitSHA: config.GIT_SHA,
  })
}

export const trackDocRating = (properties: AIDocRatingProps) => {
  analytics.track(SegmentEvents.AI_DOC_RATED, {
    ...properties,
    gitSHA: config.GIT_SHA,
  })
}

export const trackDocFeedbackSubmitted = (properties: AIDocFeedbackProps) => {
  analytics.track(SegmentEvents.AI_DOC_FEEDBACK_SUBMITTED, {
    ...properties,
    gitSHA: config.GIT_SHA,
  })
}

export const trackAIIImageFeedback = (properties: AIImageFeedbackProps) => {
  analytics.track(SegmentEvents.AI_IMAGE_FEEDBACK_SUBMITTED, {
    ...properties,
    gitSHA: config.GIT_SHA,
  })
}

export const trackAIImageRating = (properties: AIImageRatingProps) => {
  analytics.track(SegmentEvents.AI_IMAGE_RATED, {
    ...properties,
    gitSHA: config.GIT_SHA,
  })
}

export const generateAIInteractionId = () => nanoid()
