import { Box, Link, Text } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'

import { ImageSearch } from '../components/ImageSearch'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import LexicaIcon from './icons/lexica.png'

const LEXICA_RANDOM_QUERIES = [
  'wallpaper',
  'textures',
  'background',
  'nature',
  'gradient',
  'relax',
  'sky',
  'water',
  'pattern',
  'abstract',
]
const LexicaPanel = ({
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps) => {
  return (
    <>
      <ImageSearch
        updateAttributes={updateAttributes}
        currentImageUrl={currentAttributes.src}
        defaultQuery={currentAttributes.query}
        randomQueries={LEXICA_RANDOM_QUERIES}
        provider="lexica"
        enablePagination={false}
      />
      <Box width="100%" textAlign="center" padding="10px" paddingTop="30px">
        <Text fontSize="sm" color="gray.500">
          <Trans>
            Photos from{' '}
            <Link
              href="https://lexica.art"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Lexica AI
            </Link>
          </Trans>
        </Text>
      </Box>
    </>
  )
}

export const LexicaProvider: MediaProviderType = {
  key: 'image.lexica',
  Panel: LexicaPanel,
  featureFlag: 'lexicaImageSearch',
  label: () => t`AI generated images`,
  description: () => t`Search for images generated by Lexica AI`,
  image: LexicaIcon,
  keywords: [
    'ai',
    'lexica',
    'stable diffusion',
    'generated',
    'photo',
    'photography',
    'image',
  ],
}
