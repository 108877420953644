import { VStack } from '@chakra-ui/react'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'

import { URLFetcher } from '../components/URLFetcher'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import TwitterIcon from './icons/twitter.svg'

const TwitterPanel = ({
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<WebEmbedAttrs>) => {
  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={currentAttributes.url}
        updateAttributes={updateAttributes}
        placeholder="https://twitter.com/..."
      />
    </VStack>
  )
}

export const TwitterProvider: MediaProviderType = {
  key: 'embed.twitter',
  Panel: TwitterPanel,
  label: () => 'Tweet',
  icon: brands('twitter'),
  image: TwitterIcon,
  urlRegex: /^https?:\/\/(www\.)?twitter\.com/,
  keywords: ['twitter', 'tweet'],
  preferHtml: true,
  defaultDisplayStyle: 'inline',
  inlineInteractive: true,
}
