import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Alert,
  Badge,
  Box,
  Button,
  ButtonGroup,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Plural, t, Trans } from '@lingui/macro'
import { memo, useCallback, useMemo, useState } from 'react'

import { LinkCopier } from 'gamma_components/LinkCopier'
import { useFeatureFlag } from 'modules/featureFlags'
import { GAMMA_SUPPORT_EMAIL } from 'modules/help/constants'
import { GAMMA_PROPER_NOUN } from 'modules/i18n/properNouns'
import { useLaunchIntercom } from 'modules/intercom/hooks'
import { UpsellCallout } from 'modules/monetization/components/UpsellCallout'
import { useHasUnlimitedAI } from 'modules/monetization/hooks/productFeatures'
import { SegmentEvents } from 'modules/segment'
import { useCanManageWorkspace, useUserContext } from 'modules/user'
import { generateReferralLink } from 'utils/url'

import { getLowCreditAttributes } from '../constants'
import { useCreditStatus } from '../hooks'
import { AICreditsFAQ } from './AICreditsFAQ'

const earnMethodAttributes = (): Record<
  EarnMethod,
  {
    label: string
  }
> => {
  return {
    friend: {
      label: t`Copy referral link`,
    },
    teammate: {
      label: t`Copy workspace invite`,
    },
    buy: {
      label: t`Buy more credits`,
    },
  }
}

const removeModalHeaderFontStyles = {
  fontWeight: 500,
  letterSpacing: 'normal',
  color: 'gray.600',
}

type EarnMethod = 'buy' | 'friend' | 'teammate'

type AICreditsModalProps = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  credits: number | null
  segmentEvent?: SegmentEvents
}
const AICreditsModalInner = ({
  onClose,
  onOpen,
  credits,
  segmentEvent = SegmentEvents.GAMMA_PRO_UPSELL_CALLOUT_AI_CREDITS,
}: Omit<AICreditsModalProps, 'isOpen'>) => {
  const { user, currentWorkspace } = useUserContext()
  const canManageWorkspace = useCanManageWorkspace()
  const upsellUXEnabled_Pro = useFeatureFlag('upsellUXEnabled_Pro')
  const hasUnlimitedCredits = useHasUnlimitedAI()
  const [earnMethod, setEarnMethod] = useState<EarnMethod>(
    upsellUXEnabled_Pro ? 'buy' : 'friend'
  )
  const aiCredits = useFeatureFlag('ai-credits')

  const isIntercomEnabled = useFeatureFlag('intercom')
  const launchIntercomUI = useLaunchIntercom()
  const { lowCreditStatus } = useCreditStatus()

  const referralLink = useMemo(() => {
    if (!user) return ''
    if (earnMethod === 'teammate' && currentWorkspace && canManageWorkspace) {
      return generateReferralLink({
        userId: user.id,
        workspace: currentWorkspace,
      })
    } else if (earnMethod === 'friend') {
      return generateReferralLink({
        userId: user.id,
      })
    } else {
      return ''
    }
  }, [canManageWorkspace, currentWorkspace, earnMethod, user])

  const isReferralEnabled = aiCredits.enabled
  const referralBonusAmount = aiCredits.referralBonus

  const currentReferralAttrs = earnMethodAttributes()[earnMethod]

  const onContactClick = useCallback(() => {
    launchIntercomUI()
    onClose()
  }, [launchIntercomUI, onClose])

  const lowCreditAttributes = getLowCreditAttributes()

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <VStack py={4} px={6} textAlign="center">
          {lowCreditStatus && (
            <Alert
              status={lowCreditAttributes[lowCreditStatus].status}
              px={2}
              py={1}
              w="auto"
            >
              <HStack color={lowCreditAttributes[lowCreditStatus].darkColor}>
                <Box>
                  <FontAwesomeIcon icon={regular('circle-info')} />
                </Box>
                <Text fontSize="sm">
                  {lowCreditAttributes[lowCreditStatus].description}
                </Text>
              </HStack>
            </Alert>
          )}
          <Text {...removeModalHeaderFontStyles} fontSize="md">
            <Trans>Your account balance</Trans>
          </Text>
          <ModalHeader
            p={0}
            color={
              lowCreditStatus
                ? lowCreditAttributes[lowCreditStatus].lightColor
                : undefined
            }
          >
            <span role="img" aria-label="Money emoji">
              💰
            </span>{' '}
            {credits === null ? (
              <Spinner size="sm" />
            ) : (
              <Plural value={credits} one="# credit" other="# credits" />
            )}
          </ModalHeader>
          <Text
            textAlign="left"
            fontSize="sm"
            pt={2}
            {...removeModalHeaderFontStyles}
          >
            <Trans>
              Credits let you create and edit with AI. Each user in your
              workspace gets their own credits.
            </Trans>
          </Text>
        </VStack>

        <ModalBody pb={4}>
          {isReferralEnabled ? (
            <VStack spacing={4}>
              <Text fontSize="xl" textAlign="center" fontWeight="bold">
                <Trans>Earn more credits</Trans>
              </Text>
              <ButtonGroup
                variant="ghost"
                size="sm"
                spacing={0}
                gap={2}
                justifyContent="center"
                flexWrap="wrap"
                alignItems="center"
              >
                {!hasUnlimitedCredits && upsellUXEnabled_Pro && (
                  <Button
                    leftIcon={<FontAwesomeIcon icon={regular('coins')} />}
                    onClick={() => setEarnMethod('buy')}
                    isActive={earnMethod === 'buy'}
                    color={earnMethod === 'buy' ? undefined : 'gray.700'}
                  >
                    <Trans>Get unlimited credits</Trans>
                  </Button>
                )}
                <Button
                  leftIcon={<FontAwesomeIcon icon={regular('comment')} />}
                  onClick={() => setEarnMethod('friend')}
                  isActive={earnMethod === 'friend'}
                  color={earnMethod === 'friend' ? undefined : 'gray.700'}
                >
                  <Trans>Refer a friend</Trans>
                </Button>
                {canManageWorkspace && (
                  <Button
                    leftIcon={<FontAwesomeIcon icon={regular('user-plus')} />}
                    onClick={() => setEarnMethod('teammate')}
                    isActive={earnMethod === 'teammate'}
                    color={earnMethod === 'teammate' ? undefined : 'gray.700'}
                  >
                    <Trans>Invite a teammate</Trans>
                  </Button>
                )}
              </ButtonGroup>
              {(earnMethod === 'friend' || earnMethod === 'teammate') && (
                <>
                  <HStack spacing={2}>
                    <Text fontSize="sm" minW="50%">
                      {earnMethod === 'friend' && (
                        <Plural
                          value={referralBonusAmount}
                          one={`Give ${referralBonusAmount} credit and earn ${referralBonusAmount} credit for each new referral who signs up for ${GAMMA_PROPER_NOUN}.`}
                          other={`Give ${referralBonusAmount} credits and earn ${referralBonusAmount} credits for each new referral who signs up for ${GAMMA_PROPER_NOUN}.`}
                        />
                      )}
                      {earnMethod === 'teammate' && (
                        <Plural
                          value={referralBonusAmount}
                          one={`Give ${referralBonusAmount} credit and earn ${referralBonusAmount} credit for each new referral who signs up and joins your workspace.`}
                          other={`Give ${referralBonusAmount} credits and earn ${referralBonusAmount} credits for each new referral who signs up and joins your workspace.`}
                        />
                      )}
                    </Text>
                    <Badge
                      colorScheme="green"
                      fontSize="xs"
                      px="0.4em"
                      py="0.2em"
                    >
                      +{' '}
                      <Plural
                        value={referralBonusAmount}
                        one="# credit"
                        other="# credits"
                      />
                    </Badge>
                  </HStack>
                  <LinkCopier
                    url={referralLink}
                    customLabel={currentReferralAttrs.label}
                    variant="solid"
                  />
                </>
              )}
              {earnMethod === 'buy' && (
                <UpsellCallout
                  segmentEvent={segmentEvent}
                  onOpenCallback={onClose}
                  onCloseCallback={onOpen}
                />
              )}
            </VStack>
          ) : (
            <HStack align="baseline" spacing={1}>
              <Text>
                <Trans>Want more credits?</Trans>
              </Text>
              {isIntercomEnabled ? (
                <Button onClick={onContactClick} variant="link">
                  <Trans>Contact us</Trans>
                </Button>
              ) : (
                <Link href={`mailto:${GAMMA_SUPPORT_EMAIL}`}>
                  <Trans>Contact us</Trans>
                  <ExternalLinkIcon ml={1} transform="translateY(-2px)" />
                </Link>
              )}
            </HStack>
          )}
          <AICreditsFAQ
            my={4}
            onContactUsClick={isIntercomEnabled ? onContactClick : undefined}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const AICreditsModalComponent = ({ isOpen, ...props }: AICreditsModalProps) => {
  return isOpen ? <AICreditsModalInner {...props} /> : null
}

export const AICreditsModal = memo(AICreditsModalComponent)
