import { ChatMessage, ChatOperation, ChatRole } from 'modules/api'

import { ChatCompletionParams } from '../openai'
import { BaseChatCompletionPrompt } from './BaseChatCompletionPrompt'

export class BackendChatCompletionPrompt<
  T extends string = string
> extends BaseChatCompletionPrompt<T> {
  protected variables: T[]
  protected systemPromptOperation: ChatOperation
  protected userPromptOperation: ChatOperation

  constructor({
    variables,
    systemPromptOperation,
    userPromptOperation,
    params,
  }: {
    variables: T[]
    systemPromptOperation: ChatOperation
    userPromptOperation: ChatOperation
    params?: ChatCompletionParams
  }) {
    super({ params })
    this.variables = variables
    this.systemPromptOperation = systemPromptOperation
    this.userPromptOperation = userPromptOperation
  }

  getSystemChatMessage(variables: Record<T, any>): ChatMessage {
    return {
      role: ChatRole.System,
      operation: this.systemPromptOperation,
      variables,
    }
  }
  getUserChatMessage(variables: Record<T, any>): ChatMessage {
    return {
      role: ChatRole.User,
      operation: this.userPromptOperation,
      variables,
    }
  }
}
