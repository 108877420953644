import {
  Alert,
  AlertIcon,
  AspectRatio,
  Box,
  Button,
  Flex,
  GridItem,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SimpleGrid,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans, t } from '@lingui/macro'
import { motion } from 'framer-motion'
import { range, sample } from 'lodash'
import { memo, useCallback, useEffect, useState } from 'react'

import { preventDefaultToAvoidBlur } from 'utils/handlers'
import { useDebounced } from 'utils/hooks'

import { ImageAttrs } from '../types/Image'

const GRID_ITEM_HEIGHT_PX = '60px'
const RESULTS_PER_PAGE = 100

export type IconSearchResult = {
  id: string
  src: string
  provider: string
  html: string
  width: number
  height: number
}

type IconSearchGridItemProps = {
  icon: IconSearchResult
  isSelected: boolean
  onIconClick: (icon: IconSearchResult) => void
  provider: string
}

const IconSearchGridItem = memo(
  ({ icon, isSelected, onIconClick }: IconSearchGridItemProps) => {
    return (
      <GridItem
        key={icon.id}
        height={GRID_ITEM_HEIGHT_PX}
        width={GRID_ITEM_HEIGHT_PX}
      >
        <Button
          borderRadius="base"
          bgColor={isSelected ? 'trueblue.100' : undefined}
          color="gray.900"
          display="block"
          width="100%"
          height="100%"
          maxH="100%"
          pos="relative"
          _hover={{ bgColor: isSelected ? 'trueblue.100' : 'trueblue.50' }}
          _active={{ bgColor: 'trueblue.100' }}
          transition="box-shadow .2s ease"
          variant="ghost"
          cursor="pointer"
          onClick={() => onIconClick(icon)}
          data-testid="icon-search-grid-item"
        >
          <Box
            height="100%"
            maxH="100%"
            sx={{
              svg: {
                width: '100%',
                height: '100%',
              },
            }}
            dangerouslySetInnerHTML={{
              __html: icon.html,
            }}
          />
        </Button>
      </GridItem>
    )
  }
)

IconSearchGridItem.displayName = 'ImageSearchGridItem'

type IconSearchGridProps = {
  searchQuery: string
  currentAttributes?: Partial<ImageAttrs>
  updateAttributes: (attrs: Partial<ImageAttrs>) => void
  maxResults?: number
}

export const IconSearchGrid = ({
  searchQuery,
  currentAttributes,
  updateAttributes,
}: IconSearchGridProps) => {
  const [iconResults, setIconResults] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [page, setPage] = useState(1)

  const fetchResults = useCallback(
    async (forPage: number) => {
      const iconSearchEndpoint = `/api/icons/search?count=${RESULTS_PER_PAGE}&query=${searchQuery}&page=${forPage}`
      setLoading(true)
      setHasError(false)
      try {
        const req = await fetch(iconSearchEndpoint, { credentials: 'include' })
        const res = await req.json()
        setLoading(false)
        if (!(res?.data?.length >= 0)) {
          throw new Error(res)
        }
        setIconResults(res.data)
      } catch (error) {
        setHasError(true)
        setLoading(false)
        console.error('(caught) [ImageSearchGrid] fetchResults:', error)
      }
    },
    [searchQuery]
  )

  // Whenever the query changes, reset to the first page of results
  useEffect(() => {
    setIconResults([])
    setPage(1)
    fetchResults(1)
  }, [searchQuery, fetchResults])

  // Load more by incrementing the page and refetching
  const canLoadMore = false

  const loadMore = useCallback(() => {
    fetchResults(page + 1)
    setPage(page + 1)
  }, [fetchResults, page])

  const onIconClick = useCallback(
    (iconResult: IconSearchResult) => {
      updateAttributes({
        src: undefined,
        tempUrl: iconResult.src,
        meta: {
          height: iconResult.height,
          width: iconResult.width,
        },
        providerMeta: {
          name: iconResult.provider,
          id: iconResult.id,
        },
        query: searchQuery,
      })
    },
    [searchQuery, updateAttributes]
  )

  const gridItems: React.ReactNode[] = iconResults.map(
    (icon: IconSearchResult, index) => {
      const isSelected =
        icon.id === currentAttributes?.providerMeta?.id &&
        icon.provider === currentAttributes?.providerMeta?.name
      return (
        <IconSearchGridItem
          provider={icon.provider}
          icon={icon}
          isSelected={isSelected}
          onIconClick={onIconClick}
          key={index}
        />
      )
    }
  )
  return (
    <Flex direction="column" align="stretch">
      <SimpleGrid gap={2} columns={{ base: 4, '2xl': 6 }}>
        {gridItems}
        {isLoading && <GridSkeleton />}
      </SimpleGrid>
      {canLoadMore && (
        <Button variant="plain" onClick={loadMore} mt={4}>
          <Trans>Load more</Trans>
        </Button>
      )}
      {iconResults.length === 0 && !isLoading && (
        <Box width="100%" textAlign="center" padding="10px">
          <Text style={{ color: 'gray' }}>
            <Trans>No icons found for "{searchQuery}"</Trans>
          </Text>
        </Box>
      )}
      {hasError && (
        <Alert status="error">
          <AlertIcon />
          <Trans>Error searching for icons.</Trans>
        </Alert>
      )}
    </Flex>
  )
}

const GridSkeleton = () => {
  return (
    <>
      {range(RESULTS_PER_PAGE).map((_, index) => (
        <GridItem key={index} height={GRID_ITEM_HEIGHT_PX}>
          <AspectRatio ratio={1}>
            <Skeleton borderRadius="base" />
          </AspectRatio>
        </GridItem>
      ))}
    </>
  )
}

type IconSearchProps = {
  currentIconUrl?: string
  currentAttributes: Partial<ImageAttrs>
  updateAttributes: (attrs: Partial<ImageAttrs>) => void
  defaultQuery?: string
  randomQueries?: string[]
}

const MotionIconButton = motion(IconButton)

export const IconSearch = ({
  currentAttributes,
  updateAttributes,
  defaultQuery = '',
  randomQueries,
}: IconSearchProps) => {
  const [searchQuery, setSearchQuery] = useState<string>(defaultQuery)
  const [inputValue, setInputValue] = useState('')

  // When we change providers, reset the search
  useEffect(() => {
    setInputValue(defaultQuery)
    setSearchQuery(defaultQuery)
  }, [defaultQuery])

  const randomizeQuery = useCallback(() => {
    let randomQuery = sample(randomQueries)
    while (randomQuery === searchQuery) {
      randomQuery = sample(randomQueries)
    }
    if (!randomQuery) return
    setSearchQuery(randomQuery)
    setInputValue('')
  }, [randomQueries, searchQuery])

  const setSearchQueryDebounced = useDebounced(setSearchQuery, 500)

  return (
    <VStack
      spacing={4}
      align="stretch"
      translate="no" // https://linear.app/gamma-app/issue/G-3050/reactdom-insertbefore-error-when-using-translation-plugin
    >
      <InputGroup size="md">
        <InputLeftElement pointerEvents="none">
          <Box color="gray.400">
            <FontAwesomeIcon icon={regular('search')} />
          </Box>
        </InputLeftElement>
        <Input
          placeholder={searchQuery || t`Find an icon`}
          value={inputValue}
          onChange={(event) => {
            setInputValue(event.target.value)
            setSearchQueryDebounced(event.target.value)
          }}
          data-testid="image-search-input"
        />
        {randomQueries && (
          <InputRightElement>
            <GammaTooltip placement="top" label={<Trans>Surprise me</Trans>}>
              <MotionIconButton
                icon={<FontAwesomeIcon icon={regular('dice')} />}
                aria-label={t`Surprise me`}
                variant="ghost"
                onClick={randomizeQuery}
                size="sm"
                whileTap={{ y: '-5px' }}
                onMouseDown={preventDefaultToAvoidBlur}
              />
            </GammaTooltip>
          </InputRightElement>
        )}
      </InputGroup>

      <IconSearchGrid
        currentAttributes={currentAttributes}
        updateAttributes={updateAttributes}
        searchQuery={searchQuery || ''}
      />
    </VStack>
  )
}
