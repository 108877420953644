import { uniqBy } from 'lodash'

import { config } from 'config'
import { featureFlags } from 'modules/featureFlags'
import { downloadImage } from 'utils/image'

import {
  ImageResultWithProvider,
  ImageSearchFilterOptions,
  ImageSearchProviderKey,
  ImageSearchResult,
} from '../types/ImageSearch'

type SearchImageOptions = {
  query: string
  provider: ImageSearchProviderKey
  count?: number
  options?: ImageSearchFilterOptions
  prefetch?: boolean
}

// 35 because that's what serper.dev uses for a 2 credit bing search
// NOTE: if we ever switch to serper.dev google, we need to change this
// to 10, since 1 credit = 10 images
const DEFAULT_IMAGE_COUNT = 35

export const fetchImageSearchResults = async ({
  query,
  provider,
  options = {},
  count = DEFAULT_IMAGE_COUNT,
  prefetch = false,
}: SearchImageOptions): Promise<ImageSearchResult[]> => {
  const type =
    provider === 'web' ? featureFlags.get('webImageProviderType') : undefined
  const imageSearchEndpoint = getSearchUrl({
    query,
    provider,
    options: { type, ...options },
    count,
  })
  try {
    const req = await fetch(imageSearchEndpoint, { credentials: 'include' })
    const res = await req.json()

    if (!(res?.length >= 0)) {
      throw new Error(res)
    }
    if (prefetch) {
      Promise.all(
        res.map((image: any) => {
          return downloadImage(image.imageUrl)
        })
      )
        .then(() => {
          console.debug(
            '[fetchImageSearchResults] images downloaded in background'
          )
        })
        .catch((error) => {
          console.debug(
            '[fetchImageSearchResults] error downloading image:',
            error
          )
        })
    }
    return res
  } catch (error) {
    console.error('(caught) [fetchImages] fetchResults:', error)
    return []
  }
}

type MultiSearchImageOptions = {
  queries: string[]
  provider: ImageSearchProviderKey
  count?: number
  options?: ImageSearchFilterOptions
  prefetch?: boolean
}

// Fetch images for multiple queries and combine the results so that there are no duplicates
export const fetchImageSearchResultsMulti = async ({
  queries,
  count,
  provider,
  prefetch,
  options,
}: MultiSearchImageOptions): Promise<ImageResultWithProvider[]> => {
  const images = (
    await Promise.all(
      queries.map((query) =>
        fetchImageSearchResults({
          query,
          count,
          provider,
          prefetch,
          options,
        }).then((results) =>
          results.map((i) => ({
            query,
            image: i,
            provider,
          }))
        )
      )
    )
  ).flat()
  const uniqueImages = uniqBy(images, (i) => i.image.id || i.image.imageUrl)
  return uniqueImages
}

const getSearchUrl = ({
  query,
  count,
  provider,
  options = {},
}: {
  query: string
  count: number
  provider: string
  options: ImageSearchFilterOptions
}) => {
  const params = new URLSearchParams()
  const apiUrl = config.API_HOST || 'https://api.gamma.app'
  const path = '/media/images/search'

  params.append('query', query)
  params.append('provider', provider)
  params.append('count', String(count))

  Object.entries(options).forEach(([key, value]) => {
    if (value !== undefined) {
      params.append(key, String(value))
    }
  })

  return `${apiUrl}${path}?${params.toString()}`
}
