import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { BING_IMAGE_DEFAULTS } from 'modules/media/providers/BingImage/bingImageDefaults'
import { useCanBingSearch } from 'modules/monetization/hooks/productFeatures'

import { ImageSearch } from '../components/ImageSearch'
import {
  MediaProviderType,
  MediaProviderPanelProps,
} from '../types/MediaProvider'
import BingImageIcon from './icons/bing.svg'

const BingImagePanel = ({
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps) => {
  const canBingSearch = useCanBingSearch()

  return (
    <ImageSearch
      updateAttributes={updateAttributes}
      currentImageUrl={currentAttributes.src}
      defaultQuery={currentAttributes.query || ''}
      enableSearch={canBingSearch}
      enablePagination={false}
      provider="bing"
      resultsPerPage={BING_IMAGE_DEFAULTS.count}
    />
  )
}

export const BingImageProvider: MediaProviderType = {
  key: 'image.bing',
  Panel: BingImagePanel,
  label: () => t`Bing image search`,
  icon: brands('microsoft'),
  image: BingImageIcon,
  keywords: ['image', 'photo', 'bing', 'pic'],
}
