import { Checkbox, Text, VStack } from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t, Trans } from '@lingui/macro'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { getMediaEmbedUrl } from 'modules/tiptap_editor/extensions/media/utils'

import { URLFetcher } from '../components/URLFetcher'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import { EmbedMetadata } from './EmbedMetadata'

const WebpagePanel = ({
  editor,
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<WebEmbedAttrs>) => {
  const { proxy, displayStyle } = currentAttributes
  const url = getMediaEmbedUrl(currentAttributes)

  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url || ''}
        updateAttributes={updateAttributes}
        placeholder={t`Enter a URL here`}
      />
      {url && displayStyle && (
        <>
          <Checkbox
            isChecked={proxy}
            onChange={(event) =>
              updateAttributes({
                proxy: event.target.checked,
              })
            }
          >
            <Trans>Load through a proxy</Trans>
          </Checkbox>
          <Text fontSize="sm" color="gray.500" mt={-2}>
            <Trans>
              Tip: try this setting if pages aren't loading. It works best with
              static web pages, not interactive apps.
            </Trans>
          </Text>
        </>
      )}
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
    </VStack>
  )
}

export const WebpageProvider: MediaProviderType = {
  label: () => t`Webpage or app`,
  description: () => t`Embed interactive apps and websites`,
  key: 'embed.custom',
  icon: duotone('browser'),
  Panel: WebpagePanel,
  keywords: ['webpage', 'website', 'app', 'embed', 'iframe'],
}
