import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t, Trans } from '@lingui/macro'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'

import { EmbedPanel } from '../components/EmbedPanel'
import { EmbedTip } from '../components/EmbedTip'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import Icon from './icons/airtable.svg'

const Panel = (props: MediaProviderPanelProps<WebEmbedAttrs>) => {
  return (
    <EmbedPanel
      {...props}
      urlPlaceholder="https://airtable.com/..."
      embedTip={
        <EmbedTip
          guideLink="https://support.airtable.com/hc/en-us/articles/217846478-Embedding-a-view-or-base"
          providerName="Airtable"
          extraMessage={
            <Trans>
              You can paste in the private link, or the full embed code.
            </Trans>
          }
        />
      }
    />
  )
}

export const AirtableProvider: MediaProviderType = {
  label: () => 'Airtable',
  key: 'embed.airtable',
  description: () => t`Embed a base, form, or view from Airtable`,
  icon: duotone('table-list'),
  image: Icon,
  urlRegex: /^https?:\/\/airtable.com/,
  Panel,
  categories: ['app', 'form'],
  defaultDisplayStyle: 'inline',
  inlineInteractive: true,
  iframeOptions: {
    allowPopups: true,
  },
  keywords: ['airtable', 'database', 'spreadsheet', 'table'],
}
