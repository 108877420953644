import { useCallback, useState } from 'react'

import { config } from 'config'
import { fetchImageSearchResults } from 'modules/media/apis/imageSearch'
import { BING_IMAGE_DEFAULTS } from 'modules/media/providers/BingImage/bingImageDefaults'
import {
  ImageSearchFilterOptions,
  ImageSearchProviderKey,
  ImageSearchResult,
} from 'modules/media/types/ImageSearch'
import { isValidImageString } from 'utils/image'

import { ImageLicense } from '../../types/Image'

const MAX_PAGES = 6

export const useTrackImageClick = (provider: string) => {
  return useCallback(
    (image: ImageSearchResult) => {
      const imageTrackEndpoint = `${config.API_HOST || ''}/media/images/track`
      fetch(imageTrackEndpoint, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          provider,
          id: image.id,
        }),
      })
    },
    [provider]
  )
}

export const useImageSearch = ({
  provider,
  enablePagination,
  resultsPerPage,
}: {
  provider: ImageSearchProviderKey
  enablePagination: boolean
  resultsPerPage: number
}) => {
  const [lastSearchedQuery, setLastSearchedQuery] = useState('')
  const [imageResults, setImageResults] = useState<ImageSearchResult[]>([])
  const [isLoading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [page, setPage] = useState(1)
  const [license, setLicense] = useState<ImageLicense>('All')

  // Load more by incrementing the page and refetching
  const canLoadMore =
    enablePagination && imageResults.length > 0 && page < MAX_PAGES

  const fetchResults = useCallback(
    async (searchQuery: string, forPage: number) => {
      setLoading(true)
      setHasError(false)
      try {
        const options: ImageSearchFilterOptions = {
          license,
          page: forPage,
          gammaFeature: 'mediaDrawer',
        }
        if (provider === 'bing') {
          options.minWidth = BING_IMAGE_DEFAULTS.minWidth
          options.maxWidth = BING_IMAGE_DEFAULTS.maxWidth
        }
        const res = await fetchImageSearchResults({
          query: searchQuery,
          count: resultsPerPage,
          provider,
          options,
        })
        setLoading(false)
        if (!(res?.length >= 0)) {
          throw new Error('No images found')
        }
        const validImages = res.filter((image: ImageSearchResult) =>
          isValidImageString(image.imageUrl)
        )
        setImageResults((currentRes) => currentRes.concat(validImages))
      } catch (error) {
        setHasError(true)
        setLoading(false)
        console.error('(caught) [ImageSearchGrid] fetchResults:', error)
      }
    },
    [provider, resultsPerPage, license]
  )

  const search = useCallback(
    (searchQuery: string) => {
      // TODO how to handle searchQuery === ''
      setImageResults([])
      setPage(1)
      setLastSearchedQuery(searchQuery)
      fetchResults(searchQuery, 1)
    },
    [fetchResults]
  )

  const loadMore = useCallback(() => {
    fetchResults(lastSearchedQuery, page + 1)
    setPage(page + 1)
  }, [fetchResults, lastSearchedQuery, page])

  const resetResults = useCallback(() => {
    setLastSearchedQuery('')
    setImageResults([])
    setPage(1)
  }, [])

  const updateLicense = useCallback(
    (newLicense: ImageLicense) => {
      setLicense(newLicense)
      resetResults()
      search(lastSearchedQuery)
    },
    [resetResults, search, lastSearchedQuery]
  )

  return {
    search,
    isLoading,
    loadMore,
    canLoadMore,
    hasError,
    imageResults,
    resetResults,
    searchQuery: lastSearchedQuery,
    license,
    updateLicense,
  }
}
