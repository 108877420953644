import { ImageSearchProvidersToMediaProviderKey } from 'modules/media/providers/ImageSearchProviders'
import {
  ImageSearchProviderKey,
  ImageSearchResult,
} from 'modules/media/types/ImageSearch'

import { ImageAttrs } from '../types/Image'

export const getImageTextDescription = (image: ImageAttrs) => {
  // Future: enrich the image with AI recognition
  // For now, this uses a ranked order of properties
  // to make a best effort attempt at a description
  return (
    image.meta?.description ||
    image.meta?.alt_description ||
    image.meta?.title ||
    image.meta?.snippet ||
    image.meta?.tags?.join(', ') ||
    image.src
  )
}

export const imageResultToImageAttrs = (
  imageResult: ImageSearchResult,
  searchQuery: string,
  searchProvider: ImageSearchProviderKey
): ImageAttrs => {
  const attrs: ImageAttrs = {
    src: imageResult.imageUrl,
    tempUrl: imageResult.thumbnailUrl,
    meta: {
      height: imageResult.height,
      width: imageResult.width,
      description: imageResult.description, // Unsplash only
      alt_description: imageResult.altDescription, // Unsplash only
      title: imageResult.title, // Google only
      snippet: imageResult.snippet, // Google only
    },
    query: searchQuery,
    source: ImageSearchProvidersToMediaProviderKey[searchProvider],
  }

  if (searchProvider === 'aiGenerated') {
    attrs.aiParams = {
      prompt: imageResult.aiPrompt!,
      height: imageResult.height,
      width: imageResult.width,
      model: imageResult.aiModel,
      style_preset: imageResult.aiStylePreset,
    }
  }

  return attrs
}
