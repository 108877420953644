import { Button, ButtonProps, Spinner, Text } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Plural, Trans } from '@lingui/macro'
import { memo } from 'react'

import { getLowCreditAttributes } from '../constants'
import { LowCreditStatus } from '../types'

type CreditsBadgeComponentProps = {
  credits: number | null
  lowCreditStatus: LowCreditStatus | null
  onClick: () => void
  variant?: 'solid' | 'ghost'
}

export const CreditsBadgeComponent = ({
  credits,
  lowCreditStatus,
  onClick,
  variant = 'solid',
}: CreditsBadgeComponentProps) => {
  const nullCredits = credits === null

  const buttonProps: ButtonProps =
    variant === 'ghost'
      ? { variant: 'ghost', size: 'sm', colorScheme: 'blackAlpha' }
      : { variant: 'unstyled', bg: 'white', px: 4 }

  const lowCreditAttributes = getLowCreditAttributes()

  return (
    <GammaTooltip
      label={<Trans>Learn how to get more credits</Trans>}
      placement="top"
    >
      <Button
        onClick={onClick}
        isDisabled={nullCredits}
        {...buttonProps}
        leftIcon={<FontAwesomeIcon icon={regular('coins')} size="sm" />}
        rightIcon={
          <FontAwesomeIcon
            color="var(--chakra-colors-gray-600)"
            icon={regular('circle-info')}
            size="xs"
          />
        }
      >
        {nullCredits ? (
          <Spinner size="xs" />
        ) : (
          <Text
            as="span"
            color={
              lowCreditStatus === null
                ? undefined
                : lowCreditAttributes[lowCreditStatus].lightColor
            }
          >
            <Plural value={credits} one="# credit" other="# credits" />
          </Text>
        )}
      </Button>
    </GammaTooltip>
  )
}

export const CreditsBadge = memo(CreditsBadgeComponent)
