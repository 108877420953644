import { AIGeneratedImageProvider } from './AIGeneratedImage'
import { AirtableProvider } from './Airtable'
import { AmplitudeProvider } from './Amplitude'
import { BingImageProvider } from './BingImage'
import { CalendlyProvider } from './Calendly'
import { CustomImageProvider } from './CustomImage'
import { CustomVideoProvider } from './CustomVideo'
import { FigmaProvider } from './Figma'
import { GiphyProvider } from './Giphy'
import { GoogleDriveProvider } from './GoogleDrive'
import { GoogleFormProvider } from './GoogleForm'
import { GoogleImageProvider } from './GoogleImage'
import { GradientProvider } from './Gradient'
import { IconProvider } from './Icon'
import { JotformProvider } from './Jotform'
import { LexicaProvider } from './Lexica'
import { LoomProvider } from './Loom'
import { MicrosoftOfficeProvider } from './MicrosoftOffice'
import { MiroProvider } from './Miro'
import { PDFProvider } from './PDF'
import { PowerBIProvider } from './PowerBI'
import { SpotifyProvider } from './Spotify'
import { TallyProvider } from './Tally'
import { TikTokProvider } from './TikTok'
import { TwitterProvider } from './Twitter'
import { TypeformProvider } from './Typeform'
import { UnsplashProvider } from './Unsplash'
import { VimeoProvider } from './Vimeo'
import { WebImageProvider } from './WebImage'
import { WebpageProvider } from './Webpage'
import { WistiaProvider } from './Wistia'
import { YoutubeProvider } from './Youtube'

export const MediaProviders = [
  BingImageProvider,
  GiphyProvider,
  GoogleImageProvider,
  WebImageProvider,
  LexicaProvider,
  UnsplashProvider,
  YoutubeProvider,
  LoomProvider,
  FigmaProvider,
  GoogleFormProvider,
  GoogleDriveProvider,
  WebpageProvider,
  CustomImageProvider,
  AIGeneratedImageProvider,
  MicrosoftOfficeProvider,
  PowerBIProvider,
  TwitterProvider,
  VimeoProvider,
  CustomVideoProvider,
  PDFProvider,
  MiroProvider,
  AirtableProvider,
  AmplitudeProvider,
  WistiaProvider,
  SpotifyProvider,
  TikTokProvider,
  GradientProvider,
  IconProvider,
  CalendlyProvider,
  TypeformProvider,
  JotformProvider,
  TallyProvider,
]

export const MediaProvidersMap = Object.fromEntries(
  Object.values(MediaProviders)
    .flat()
    .map((provider) => [provider.key, provider])
)

export {
  BingImageProvider,
  GiphyProvider,
  GoogleImageProvider,
  WebImageProvider,
  LexicaProvider,
  UnsplashProvider,
  YoutubeProvider,
  LoomProvider,
  FigmaProvider,
  GoogleDriveProvider,
  WebpageProvider,
  CustomImageProvider,
  AIGeneratedImageProvider,
  MicrosoftOfficeProvider,
  PowerBIProvider,
  TwitterProvider,
  VimeoProvider,
  CustomVideoProvider,
  PDFProvider,
  MiroProvider,
  AirtableProvider,
  AmplitudeProvider,
  WistiaProvider,
  SpotifyProvider,
  TikTokProvider,
  GradientProvider,
  IconProvider,
  CalendlyProvider,
  TypeformProvider,
  GoogleFormProvider,
  JotformProvider,
  TallyProvider,
}
