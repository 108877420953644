import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Alert, AlertDescription, AlertIcon, Link } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

type EmbedTipProps = {
  guideLink: string
  extraMessage?: React.ReactNode
  providerName: string
}

export const EmbedTip = ({
  guideLink,
  extraMessage,
  providerName,
}: EmbedTipProps) => {
  return (
    <Alert status="info">
      <AlertIcon />
      <AlertDescription>
        <Trans>
          Follow{' '}
          <Link href={guideLink} textDecoration="underline" isExternal>
            this guide
            <ExternalLinkIcon mx="2px" />
          </Link>{' '}
          to find the URL or embed code for {providerName}. {extraMessage}
        </Trans>
      </AlertDescription>
    </Alert>
  )
}
