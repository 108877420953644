import { Heading, VStack } from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t, Trans } from '@lingui/macro'
import { useCallback, useRef } from 'react'

import { useAppSelector } from 'modules/redux'
import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import {
  getTempPDFAttrs,
  handlePDFUploadSuccess,
} from 'modules/tiptap_editor/extensions/media/Upload'
import { getMediaEmbedUrl } from 'modules/tiptap_editor/extensions/media/utils'
import { selectDocOrgId } from 'modules/tiptap_editor/reducer'

import { OnUploadStartParams, UploadBox } from '../components/UploadBox'
import { URLFetcher } from '../components/URLFetcher'
import { ImageUploadResult } from '../types/ImageUpload'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import { EmbedMetadata } from './EmbedMetadata'
import PDFIcon from './icons/PDF_icon.svg'

const PDFPanel = ({
  editor,
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<WebEmbedAttrs>) => {
  const orgId = useAppSelector(selectDocOrgId)
  const url = getMediaEmbedUrl(currentAttributes)
  const uploadingTempUrl = useRef<string | null>(null)

  const onUploadStart = useCallback(
    ({ tempUrl, file }: OnUploadStartParams) => {
      const attrs = getTempPDFAttrs(file)
      uploadingTempUrl.current = tempUrl
      updateAttributes({
        ...attrs,
        url: tempUrl,
      })
    },
    [updateAttributes]
  )

  const onUploadSuccess = useCallback(
    (result: ImageUploadResult, prevUrl: string) => {
      if (!editor) return
      const tempUrl = uploadingTempUrl.current
      const existingUrl = tempUrl || prevUrl
      handlePDFUploadSuccess(editor, existingUrl, result)
      uploadingTempUrl.current = null
    },
    [editor]
  )

  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url}
        updateAttributes={updateAttributes}
        placeholder={t`Enter a URL here`}
      />
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
      <Heading size="md">
        <Trans>Upload</Trans>
      </Heading>
      {orgId && (
        <UploadBox
          onUploadSuccess={onUploadSuccess}
          onUploadStart={onUploadStart}
          orgId={orgId}
          uploadType="doc"
          showTip={false}
          dropHereText={t`Drag a .pdf, .docx, or .pptx file or ${`%{browse}`}`}
          editType="node"
        />
      )}
    </VStack>
  )
}

export const PDFProvider: MediaProviderType = {
  label: () => t`PDF file`,
  key: 'embed.pdf',
  description: () =>
    t`Attach PDFs, Word documents, and PowerPoint presentations`,
  icon: duotone('file-pdf'),
  Panel: PDFPanel,
  // Match any URL that ends with .pdf
  urlRegex: /\.pdf$/,
  image: PDFIcon,
  featureFlag: 'uploadPDF',
  iframeOptions: {
    noSandbox: true,
  },
  downloadable: true,
  keywords: ['pdf', 'doc', 'upload', 'file'],
}
