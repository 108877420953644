import { Editor } from '@tiptap/core'
import { useCallback, useRef, useState } from 'react'

export const useSetSize = () => {
  const containerWrapperRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  const [currentWidth, setCurrentWidth] = useState(0)
  const [currentHeight, setCurrentHeight] = useState(0)
  const [intrinsicAspectRatio, setIntrisicAspectRatio] = useState<
    number | null
  >(null)

  const setSize = useCallback(() => {
    const containerRect = containerWrapperRef.current?.getBoundingClientRect()
    const imageRect = imageRef.current?.getBoundingClientRect()
    if (containerRect && imageRect) {
      const ar = imageRect.width / imageRect.height
      const width = containerRect.width
      const height = containerRect.width / ar
      if (width === 0 || isNaN(width) || height === 0 || isNaN(height)) {
        return
      }

      setCurrentWidth(width)
      setCurrentHeight(height)
      setIntrisicAspectRatio(ar)
    }
  }, [])

  return {
    width: currentWidth,
    height: currentHeight,
    aspectRatio: intrinsicAspectRatio,
    setSize,
    containerWrapperRef,
    imageRef,
  }
}

export const useScrollToOnImageNodeLoad = (editor?: Editor) => {
  const scrollOnImageLoad = useCallback(
    (pos: number) => {
      if (!editor) {
        return
      }
      const dom = editor?.view.domAtPos(pos)
      if (!dom) {
        console.warn('scrollOnImageLoad could not domAtPos', { pos })
        return
      }
      const node = dom.node.childNodes[dom.offset] as HTMLElement | undefined
      if (!node) {
        console.warn('scrollOnImageLoad could not find node', {
          parentNode: dom.node,
          offset: dom.offset,
        })
        return
      }

      const img = node.querySelector(
        '[data-image-node-element] img'
      ) as HTMLImageElement
      if (!img) {
        console.warn('scrollOnImageLoad could not find <img> element')
        return
      }

      const scrollTo = () => {
        // try to immediately scroll
        editor
          .chain()
          .selectNodeAtPos(pos, false)
          .focus()
          .scrollIntoView()
          .run()
        setTimeout(() => {
          // scroll again in 1 sec to ensure
          editor
            .chain()
            .selectNodeAtPos(pos, false)
            .focus()
            .scrollIntoView()
            .run()
        }, 1000)
        img.removeEventListener('load', scrollTo)
      }
      img.addEventListener('load', scrollTo)
    },
    [editor]
  )

  return scrollOnImageLoad
}
