import { customAlphabet } from 'nanoid'
import { ReadableStream } from 'web-streams-polyfill/ponyfill'

import { StreamError } from 'modules/ai/stream/errors'

export const consumeStream = <T>(
  stream: ReadableStream<T>,
  handlers: {
    onError?: (err: Error) => void
    onMessage?: (data: T) => void
    onDone?: () => void
  }
) => {
  const reader = stream.getReader()
  const read = async () => {
    try {
      const { done, value } = await reader.read()
      if (!done) {
        handlers.onMessage?.(value)
        read()
        return
      } else {
        handlers.onDone?.()
      }
    } catch (e) {
      handlers.onError?.(e instanceof Error ? e : new Error(e))
    }
  }
  read()
}

export const getStreamErrorMessage = (
  err: Error | string
): {
  errorCode?: string
  errorMessage: string
} => {
  if (err instanceof StreamError) {
    return {
      errorCode: err.code,
      errorMessage: err.message,
    }
  }
  if (err instanceof Error) {
    return {
      errorMessage: err.message,
    }
  }
  return { errorMessage: err }
}

export const generateStreamId = customAlphabet(
  '0123456789abcdefghijklmnopqrstuvwxyz',
  16
)
