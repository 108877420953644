import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  GridItem,
  SimpleGrid,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { range } from 'lodash'
import React, { useCallback } from 'react'

import {
  ImageSearchProviderKey,
  ImageSearchResult,
} from 'modules/media/types/ImageSearch'
import { imageResultToImageAttrs } from 'modules/media/utils/imageResultUtils'

import { ImageAttrs } from '../../types/Image'
import { useTrackImageClick } from './hooks'
import { ImageSearchGridItem } from './ImageSearchGridItem'

type ImageSearchGridProps = {
  searchQuery: string
  currentImageUrl?: string
  updateAttributes: (attrs: Partial<ImageAttrs>) => void
  provider: ImageSearchProviderKey
  imageResults: ImageSearchResult[]
  isLoading: boolean
  canLoadMore: boolean
  loadMore: () => void
  hasError: boolean
  resultsPerPage: number
  resizeThumbnails?: boolean
  loadFromTop?: boolean
}

export const ImageSearchGrid = ({
  searchQuery,
  currentImageUrl,
  updateAttributes,
  provider,
  imageResults,
  isLoading,
  canLoadMore,
  loadMore,
  hasError,
  resultsPerPage,
  resizeThumbnails,
  loadFromTop = false,
}: ImageSearchGridProps) => {
  const trackImageClick = useTrackImageClick(provider)

  const onImageClick = useCallback(
    (imageResult: ImageSearchResult) => {
      trackImageClick(imageResult)
      updateAttributes(
        imageResultToImageAttrs(imageResult, searchQuery, provider)
      )
    },
    [provider, searchQuery, trackImageClick, updateAttributes]
  )

  const gridItems: React.ReactNode[] = imageResults.map(
    (image: ImageSearchResult, index) => {
      const imageBaseUrl = image.imageUrl.split('?')[0]
      const currentImageBaseUrl =
        currentImageUrl && currentImageUrl.split('?')[0]
      return (
        <ImageSearchGridItem
          provider={provider}
          image={image}
          isSelected={imageBaseUrl === currentImageBaseUrl}
          onImageClick={onImageClick}
          key={image.id}
          resizeThumbnail={resizeThumbnails}
        />
      )
    }
  )
  return (
    <Flex direction="column" align="stretch">
      <SimpleGrid gap={2} columns={{ base: 2, '2xl': 3 }}>
        {loadFromTop ? (
          <>
            {isLoading && <GridSkeleton resultsPerPage={resultsPerPage} />}
            {gridItems}
          </>
        ) : (
          <>
            {gridItems}
            {isLoading && <GridSkeleton resultsPerPage={resultsPerPage} />}
          </>
        )}
      </SimpleGrid>
      {canLoadMore && (
        <Button variant="plain" onClick={loadMore} mt={4}>
          <Trans>Load more</Trans>
        </Button>
      )}
      {imageResults.length === 0 && !isLoading && (
        <Box width="100%" textAlign="center" padding="10px">
          <Text style={{ color: 'gray' }}>
            <Trans>No images found for "{searchQuery}"</Trans>
          </Text>
        </Box>
      )}
      {hasError && (
        <Alert status="error">
          <AlertIcon />
          <Trans>Error searching for images.</Trans>
        </Alert>
      )}
    </Flex>
  )
}
const GridSkeleton = ({ resultsPerPage }: { resultsPerPage: number }) => {
  return (
    <>
      {range(resultsPerPage).map((_, index) => (
        <GridItem key={index}>
          <Skeleton height="100px" borderRadius="base" />
        </GridItem>
      ))}
    </>
  )
}
