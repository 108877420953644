import { t } from '@lingui/macro'

import { config } from 'config'
import { SavedMedia, SavedMediaContext } from 'modules/api'

import { ImageErrorType } from '../components/AIGeneratedImages/hooks'

export type ImageGenerateModel = 'stable-diffusion-xl-v1-0'

export type ImageGenerateProvider = 'baseten'

export type ImageGenerateStylePreset =
  typeof IMAGE_GENERATE_STYLE_PRESETS[number]

const IMAGE_GENERATE_STYLE_PRESETS = [
  'None',
  'Starter',
  'Anime',
  'Digital_Art_Concept_Art',
  'Ethereal_Fantasy_Art',
  'Photography',
  'Cinematography',
  'Analog_film',
  'Vaporwave',
  'Isometric',
  'Low_Poly',
  'Claymation',
  '3D_Model',
  'Origami',
  'Line_Art',
  'Pixel_Art',
  'Texture',
] as const

export type GenerateImageOptions = {
  interactionId: string
  prompt: string
  workspaceId: string
  themeId?: string
  upscaleFactor?: number
  // context is not here, fetchGenerateImage will set based on themeId and docId
  docId?: string
  model?: ImageGenerateModel
  context?: SavedMediaContext
  // dont export this year
  count: number
  width?: number
  height?: number
  style_preset?: ImageGenerateStylePreset
  // currently not used below here
  steps?: number
  cfg_scale?: number
}

export const fetchGenerateImage = async (
  options: GenerateImageOptions
): Promise<SavedMedia[]> => {
  const { themeId, docId, ...rest } = options
  // set the context based on themeId or docId
  const contextObj: Pick<
    GenerateImageOptions,
    'docId' | 'themeId' | 'context'
  > = themeId
    ? {
        context: SavedMediaContext.Theme,
        themeId: options.themeId,
      }
    : docId
    ? {
        context: SavedMediaContext.Doc,
        docId: options.docId,
      }
    : {
        // we dont have either docId or themeId, respect the context
        // passed in
        context: options.context,
      }

  const url = `${
    config.API_HOST || 'https://api.gamma.app'
  }/media/images/generate`
  const req = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...rest,
      ...contextObj,
    }),
    credentials: 'include',
  })
  if (!req.ok) {
    const json = await req.json()
    const message = `${json.error}: ${json.message}`
    if (json.code === 'prohibited_input' && json.categories) {
      throw new ProhibitedInputError({ categories: json.categories })
    }
    throw new GenerateImageError(message, json)
  }
  return req.json()
}

class GenerateImageError extends Error {
  response: any

  constructor(message: string, response) {
    super(message, response)
    this.response = response
  }
}
GenerateImageError.prototype.name = 'GenerateImageError'

export const ImageErrorMessages: Record<ImageErrorType, () => string> = {
  sexual: () =>
    t`This prompt was blocked because it could generate sexual imagery.`,
  violence: () =>
    t`This prompt was blocked because it could generate violent imagery.`,
  prohibited: () =>
    t`This prompt was blocked because it could generate inappropriate content.`,
}

export class ProhibitedInputError extends Error {
  messageTranslated: string
  code = 'prohibited_input'
  category: string

  constructor({ categories }: { categories: Record<ImageErrorType, any> }) {
    const message = `Cannot generate image, prohibited (reasons=${JSON.stringify(
      Object.keys(categories)
    )})`
    super(message)
    this.category = this.parseCategories(categories)
    this.messageTranslated =
      ImageErrorMessages[this.category]() || t`This prompt was blocked`
  }

  parseCategories(categories: Record<ImageErrorType, any>): string {
    if (categories.sexual || categories['sexual/minors']) {
      return 'sexual'
    } else if (categories.violence || categories['violence/graphic']) {
      return 'violence'
    } else {
      return 'prohbited'
    }
  }
}
