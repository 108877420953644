import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { memo, useCallback, useEffect, useState } from 'react'

import { AIImageRatingKey, trackAIIImageFeedback } from 'modules/ai/track'
import { ImageAttrs } from 'modules/media/types/Image'

const SHOW_THANKS_DURATION = 3000

type AIImageRatingProps = {
  attrs: ImageAttrs
  onRateImage: (rating: AIImageRatingKey) => void
}

export const AIImageRating = memo(
  ({ attrs, onRateImage }: AIImageRatingProps) => {
    const interactionId = attrs.aiParams?.interactionId
    const [rating, setRating] = useState<AIImageRatingKey | null>(null)
    const [feedback, setFeedback] = useState('')
    const [step, setStep] = useState<'rate' | 'feedback' | 'thanks' | 'hide'>(
      'rate'
    )

    // when the image changes, reset the form
    useEffect(() => {
      if (attrs.savedMediaId) {
        setStep('rate')
        setFeedback('')
        setRating(null)
      }
    }, [attrs.savedMediaId])

    const handleSuggestionButtonClick = useCallback(
      (r: AIImageRatingKey) => {
        setRating(r)
        setStep('feedback')
        onRateImage(r)
      },
      [onRateImage]
    )

    const closeFeedback = useCallback(() => {
      setStep('thanks')
      setTimeout(() => {
        setStep('hide')
        setFeedback('')
        setRating(null)
      }, SHOW_THANKS_DURATION)
    }, [])

    const onClickSkip = useCallback(() => {
      closeFeedback()
    }, [closeFeedback])

    const handleFeedbackTextSubmitted = useCallback(() => {
      if (!attrs.aiParams?.interactionId) {
        return
      }
      trackAIIImageFeedback({
        feedback,
        aiParams: attrs.aiParams,
        imageUrl: attrs.src!,
        interactionId: attrs.aiParams.interactionId,
      })
      closeFeedback()
    }, [attrs, feedback, closeFeedback])

    // old images dont have interactionId, cannot rate them
    if (!interactionId || step === 'hide') {
      return null
    }

    if (step === 'thanks') {
      return (
        <Flex
          position="absolute"
          bottom={1}
          p={2}
          right={1}
          bg="white"
          borderRadius="md"
          borderWidth="1px"
          borderColor="gray.200"
          shadow="xl"
        >
          <Text fontSize="xs">
            <Trans>Thank you for your feedback</Trans>
          </Text>
        </Flex>
      )
    }

    return (
      <VStack
        position="absolute"
        bottom={1}
        zIndex="popover"
        right={1}
        align="flex-end"
        spacing={1}
      >
        {step === 'feedback' && (
          <Flex
            p={2}
            bg="white"
            borderRadius="md"
            borderWidth="1px"
            borderColor="gray.200"
            shadow="xl"
          >
            <Stack alignItems="flex-end">
              <Textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                fontSize="xs"
                placeholder={t`Optionally, tell us how we did`}
                p={1}
                px={2}
                borderRadius="0"
              />
              <ButtonGroup size="xs">
                <Button variant="ghost" onClick={onClickSkip}>
                  <Trans>Skip</Trans>
                </Button>
                <Button variant="solid" onClick={handleFeedbackTextSubmitted}>
                  <Trans>Submit feedback</Trans>
                </Button>
              </ButtonGroup>
            </Stack>
          </Flex>
        )}
        <ButtonGroup size="sm">
          <GammaTooltip label={<Trans>Poor suggestion</Trans>} placement="top">
            <IconButton
              aria-label="thumbs down"
              color={rating === 'poor' ? 'red.600' : undefined}
              borderWidth="1px"
              borderColor={rating === 'poor' ? 'red.600' : 'transparent'}
              bg={rating === 'poor' ? 'red.100' : undefined}
              _hover={{
                bg: rating === 'poor' ? 'red.200' : 'red.100',
              }}
              icon={<FontAwesomeIcon icon={regular('thumbs-down')} />}
              onClick={() => {
                handleSuggestionButtonClick('poor')
              }}
            />
          </GammaTooltip>
          <GammaTooltip label={<Trans>Good suggestion</Trans>} placement="top">
            <IconButton
              aria-label="thumbs up"
              color={rating === 'good' ? 'green.600' : undefined}
              borderWidth="1px"
              borderColor={rating === 'good' ? 'green.600' : 'transparent'}
              bg={rating === 'good' ? 'green.100' : undefined}
              _hover={{
                bg: rating === 'good' ? 'green.200' : 'green.100',
              }}
              icon={<FontAwesomeIcon icon={regular('thumbs-up')} />}
              onClick={() => {
                handleSuggestionButtonClick('good')
              }}
            />
          </GammaTooltip>
        </ButtonGroup>
      </VStack>
    )
  }
)

AIImageRating.displayName = 'AIImageRating'
