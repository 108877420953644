import { Box, HStack, IconButton, StackProps } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type PromptSelectorProps = {
  prevPrompt: () => void
  nextPrompt: () => void
  promptPageInfo: {
    curr: number
    total: number
    canGoPrev: boolean
    canGoNext: boolean
  }
  isDisabled?: boolean
} & StackProps

export const PromptSelector = ({
  promptPageInfo,
  nextPrompt,
  prevPrompt,
  isDisabled,
  ...rest
}: PromptSelectorProps) => {
  return (
    <HStack spacing={1} {...rest}>
      <IconButton
        variant="ghost"
        icon={<FontAwesomeIcon icon={regular('chevron-left')} />}
        isDisabled={isDisabled || !promptPageInfo.canGoPrev}
        onClick={() => prevPrompt()}
        aria-label="left"
        color="gray.800"
        size="xs"
      ></IconButton>
      <Box as="span" color="gray.800" fontSize="sm">
        {promptPageInfo.curr} / {promptPageInfo.total}
      </Box>
      <IconButton
        variant="ghost"
        icon={<FontAwesomeIcon icon={regular('chevron-right')} />}
        color="gray.800"
        isDisabled={isDisabled || !promptPageInfo.canGoNext}
        onClick={() => nextPrompt()}
        aria-label="left"
        size="xs"
      ></IconButton>
    </HStack>
  )
}
