import { dropPoint } from 'prosemirror-transform'
import { EditorView } from 'prosemirror-view'

import { findNodeAndParents } from 'modules/tiptap_editor/utils'

import { isSmartLayoutCellNode } from '../SmartLayout/isSmartLayoutCellNode'
import { checkCanDrop } from './checkCanDrop'
import { Slice } from 'prosemirror-model'

export const getDropPos = (
  view: EditorView,
  event: DragEvent,
  slice?: Slice
): number | null => {
  const coordPos = view.posAtCoords({
    left: event.clientX,
    top: event.clientY,
  })
  if (!coordPos || coordPos.inside < 0) {
    return null
  }
  const $pos = view.state.doc.resolve(coordPos.pos)
  if (!$pos) {
    return null
  }

  let target: number | null = coordPos.pos

  const $inside = view.state.doc.resolve(coordPos.inside)
  const insideNode = view.state.doc.nodeAt(coordPos.inside)
  const posNode = view.state.doc.nodeAt(coordPos.pos)

  /**
   * Start custom logic to prevent or adjust certain drop targets
   */
  // prevent dropping between smart layout cells
  if (
    insideNode?.type.name === 'smartLayout' &&
    posNode?.type.name === 'smartLayoutCell'
  ) {
    // prevent drops between smartLayoutCells, breaking them up
    return null
  }

  const smartLayoutCell = findNodeAndParents($inside, isSmartLayoutCellNode)[0]
  if (smartLayoutCell) {
    target = coordPos.inside
    if (smartLayoutCell.pos === target) {
      // prevent drops between smartLayoutCells, breaking them up
      return null
    }
  }
  /**
   * End custom logic
   */

  if (!target) {
    return null
  }

  if (slice) {
    target = dropPoint(view.state.doc, target, slice)

    if (target == null) {
      return null
    }
  }
  const canDrop = checkCanDrop(
    view.state.doc.resolve(target),
    view.dragging?.slice
  )
  if (!canDrop) {
    return null
  }

  return target
}
