import { BackendChatCompletionPrompt } from 'modules/ai/prompt/BackendChatCompletionPrompt'
import { ChatOperation } from 'modules/api/generated/graphql'

export const generateImagePrompt = new BackendChatCompletionPrompt({
  systemPromptOperation: ChatOperation.GenerateImagePromptSystem,
  userPromptOperation: ChatOperation.GenerateImagePromptUser,
  variables: ['input', 'html'],
  params: {
    temperature: 0.7,
    maxTokens: 50,
  },
})
