import Uppy, { UppyOptions } from '@uppy/core'
import Transloadit, { Assembly, COMPANION_URL, Result } from '@uppy/transloadit'
import Url from '@uppy/url'
import mimeTypes from '@uppy/utils/lib/mimeTypes'
import { Dispatch, SetStateAction } from 'react'

import { config } from 'config'
import { isDocUpload, isFontFileType, isPDFFileType } from 'utils/file'
import { isHEICFileType } from 'utils/image'

import { OnUploadStartParams } from '../components/UploadBox'
import { ImageUploadResult } from '../types/ImageUpload'

// Based on https://gamma.app/docs/9qnli7y5b4fz092
const BACKGROUND_IMAGE_MAX_SIZE = 3840
const ICON_IMAGE_MAX_SIZE = 250
const NODE_IMAGE_MAX_SIZE = 2560
const PROFILE_IMAGE_MAX_SIZE = 1024

export const UPPY_PARAMS = {
  auth: { key: config.TRANSLOADIT_AUTH_KEY },
  template_id: config.TRANSLOADIT_IMAGE_TEMPLATE_ID,
}

// Supported image types
export const imageTypes = ['image/*', mimeTypes.heic, mimeTypes.heif]

// Supported icon types
export const iconTypes = ['.png', '.ico', '.gif', '.svg']

export const slideTypes = [
  // Uppy package doesn't have these mime types so we hard code
  'application/vnd.ms-powerpoint', // ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', //pptx
]

export const documentTypes = [mimeTypes.doc, mimeTypes.docx]

// Supported doc types
// This follows Google Doc conventions that "docs" is the umbrella category of documents, slides, spreadsheets
export const docTypes = [...documentTypes, ...slideTypes, mimeTypes.pdf]

// Supported font types
export const fontTypes = [
  'font/otf',
  'font/ttf',
  'application/x-font-ttf', // These are older mime types for fonts
  'application/x-font-otf',
  '.otf',
  '.ttf',
]

export type ImageType =
  | 'accessory'
  | 'background'
  | 'icon'
  | 'node'
  | 'profile'
  | 'iframelyThumbnail'

export type UploadFileType =
  | 'image'
  | 'doc'
  | 'font'
  | 'importDoc'
  | 'all'
  | 'icon'

const fileToTypes: Record<UploadFileType, string[]> = {
  all: [...docTypes, ...slideTypes, ...imageTypes],
  importDoc: [...docTypes, ...slideTypes],
  doc: docTypes,
  font: fontTypes,
  image: imageTypes,
  icon: iconTypes,
}

export const UPPY_CONFIG: UppyOptions = {
  autoProceed: true,
  restrictions: {
    allowedFileTypes: [],
    maxNumberOfFiles: 1,
  },
}

type TransloaditSignature = {
  signature: string
  expires: string
}
const signatureMap: Record<string, TransloaditSignature> = {}

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000

export const getUppySignature = async (templateId: string) => {
  const existing = signatureMap[templateId]
  if (
    existing &&
    existing.expires &&
    +new Date(existing.expires) - FIVE_MINUTES_IN_MS > Date.now()
  ) {
    return signatureMap[templateId]
  }

  const res = await fetch('/api/transloadit/signature', {
    method: 'POST',
    body: JSON.stringify({
      templateId,
    }),
  })
  const { signature, expires } = await res.json()
  if (!signature || !expires) {
    console.error('Error getting Transloadit signature')
    return { signature: '', expires: '' }
  }
  signatureMap[templateId] = { signature, expires }

  return signatureMap[templateId]
}

export type UppyUploadHandlers = {
  onUploadStart?: (params: OnUploadStartParams) => void
  onOriginalFileUpload?: (
    uploadResult: ImageUploadResult,
    previousUrl?: string
  ) => void
  onUploadComplete?: (
    uploadResult: ImageUploadResult,
    previousUrl?: string
  ) => void
  onUploadFailed?: (message?: string) => void
  setErrorMessage?: Dispatch<SetStateAction<string>>
}

// This needs to be exported via UppyUploader to allow stubbing in tests
const createUppyInstance = (
  orgId: string,
  {
    onUploadStart,
    onOriginalFileUpload,
    onUploadComplete,
    onUploadFailed,
    setErrorMessage,
  }: UppyUploadHandlers,
  imageType: ImageType = 'node',
  maxFilesAllowed: number | null = 1,
  uploadType?: UploadFileType,
  templateId?: string
) => {
  let resizePx = NODE_IMAGE_MAX_SIZE
  let detectAndCropFace = 'false'
  const cropPadding = '50%'

  if (imageType === 'background') {
    resizePx = BACKGROUND_IMAGE_MAX_SIZE
  } else if (imageType === 'icon') {
    resizePx = ICON_IMAGE_MAX_SIZE
  } else if (imageType === 'profile') {
    resizePx = PROFILE_IMAGE_MAX_SIZE
    detectAndCropFace = 'true'
  }

  // Allow only image files if not provided
  uploadType = uploadType || 'image'
  const uppy = new Uppy({
    ...UPPY_CONFIG,
    restrictions: {
      ...UPPY_CONFIG.restrictions,
      maxNumberOfFiles: maxFilesAllowed,
      allowedFileTypes: fileToTypes[uploadType],
    },
  })

  uppy.use(Transloadit, {
    assemblyOptions: async () => {
      const template_id = templateId || UPPY_PARAMS.template_id
      const { signature, expires } = await getUppySignature(template_id)
      return {
        params: {
          ...UPPY_PARAMS,
          auth: {
            ...UPPY_PARAMS.auth,
            expires,
          },
          template_id: templateId || UPPY_PARAMS.template_id,
        },
        fields: {
          orgId,
          imageType,
          resizePx,
          cropPadding,
          detectAndCropFace,
        },
        signature,
      }
    },
    waitForEncoding: true,
  })
  uppy.on('file-added', (file) => {
    onUploadStart &&
      onUploadStart({ tempUrl: URL.createObjectURL(file.data), file })
  })
  uppy.on('error', (error: Error) => {
    const msg = `Error uploading to Transloadit: ${error.message}`
    setErrorMessage && setErrorMessage('')
    onUploadFailed && onUploadFailed(msg)
    console.error(msg)
  })
  uppy.on(
    'transloadit:result',
    (stepName: string, result: Result, assembly: Assembly) => {
      // Skip if step is not original_file or if upload type's
      // original file is not supported
      if (
        !onOriginalFileUpload ||
        stepName !== 'original_file' ||
        assembly.uploads.length === 0 ||
        isHEICFileType(assembly.uploads[0].mime) ||
        isDocUpload(assembly.uploads[0].mime, assembly.uploads[0].ext)
      ) {
        return
      }

      if (!result) {
        return
      }

      const { url, meta, name } = result
      setErrorMessage && setErrorMessage('')
      if (url) {
        onOriginalFileUpload({
          src: url,
          // @ts-ignore
          meta,
          name,
          uploadResultStep: 'original',
        })
      } else {
        onUploadFailed && onUploadFailed('Error uploading to Transloadit')
        console.error('Error uploading to Transloadit')
      }
    }
  )
  uppy.on('transloadit:complete', (assembly: Assembly) => {
    let previousUrl
    let result
    let thumbnail
    let croppedFace

    if (assembly.uploads.length === 0) {
      console.error('[transloadit:complete] No uploads in assembly', assembly)
      throw new Error('No uploads in assembly')
    }
    if (uploadType === 'importDoc') {
      result = assembly.results.convert_to_html[0]
    } else if (isFontFileType(assembly.uploads[0].ext)) {
      console.log(`${assembly.uploads.length} fonts uploaded.`)
      return
    } else if (isPDFFileType(assembly.uploads[0].mime)) {
      // For PDF upload only file is the original file
      // We also set the thumbnail URL for PDFs.
      result = assembly.results.original_file[0]
      thumbnail = assembly.results.doc_thumbnail[0].url
    } else if (isDocUpload(assembly.uploads[0].mime, assembly.uploads[0].ext)) {
      // For doc, docx, ppt, pptx upload supported file is the PDF converted file
      result = assembly.results.convert_to_pdf[0]
      // Thumbnails are only generated when embedding a doc in a card,
      // not when using doc import
      thumbnail = assembly.results.doc_thumbnail[0].url
    } else if (isHEICFileType(assembly.uploads[0].mime)) {
      // for HEIC we cannot display the .heic original_file
      result =
        assembly.results.optimized?.[0] ||
        assembly.results.filter_skip_optimize?.[0]
      croppedFace = assembly.results.detect_and_crop_face?.[0].url
    } else {
      // Non HEIC image files will have original file uploaded previously
      previousUrl = assembly.results.original_file[0].url
      croppedFace = assembly.results.detect_and_crop_face?.[0].url
      // Transloadit will sometimes return only an original file, e.g. with SVGs
      result =
        assembly.results.optimized?.[0] || assembly.results.original_file[0]
    }

    const { url, meta, name } = result

    setErrorMessage && setErrorMessage('')
    if (url) {
      onUploadComplete &&
        onUploadComplete(
          {
            src: url,
            // @ts-ignore
            meta,
            name,
            thumbnail,
            croppedFace,
            uploadResultStep: 'optimized',
          },
          previousUrl
        )
    } else {
      onUploadFailed && onUploadFailed('Error uploading to Transloadit')
      console.error('Error uploading to Transloadit')
    }
  })
  return uppy
}

export const UppyUploader = {
  createUppyInstance,
}

export const uploadFile = async (
  file: File,
  orgId: string | undefined,
  uppyUploadHandlers: UppyUploadHandlers,
  imageType: ImageType = 'node',
  uploadType: UploadFileType = 'image',
  templateId?: string
) => {
  if (!orgId) {
    throw new Error('Tried uplaoding an image with no orgId')
  }

  const uppy = UppyUploader.createUppyInstance(
    orgId,
    uppyUploadHandlers,
    imageType,
    1,
    uploadType,
    templateId
  )
  uppy.addFile({ name: file.name, type: file.type, data: file })
  uppy.upload()
}

// Transloadit will do a server-side fetch for the image
export const uploadFileFromUrl = async (
  url: string,
  orgId: string | undefined,
  uppyUploadHandlers: UppyUploadHandlers,
  imageType: ImageType = 'node'
) => {
  if (!orgId) {
    throw new Error('Tried uploading an image with no orgId')
  }

  const uppy = UppyUploader.createUppyInstance(
    orgId,
    uppyUploadHandlers,
    imageType
  )
  uppy.use(Url, {
    companionUrl: COMPANION_URL,
  })

  uppy
    .getPlugin('Url')!
    // @ts-ignore
    .addFile(url) // Uppy doesn't expose the type for this in URL plugin :(
}
