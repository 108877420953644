import { Button, Flex, HStack, Stack, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import { Currency } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import {
  GAMMA_PRO_PROPER_NOUN,
  GAMMA_PROPER_NOUN,
} from 'modules/i18n/properNouns'
import { formatCurrency } from 'modules/i18n/utils/currency'
import { useMonetizationContext } from 'modules/monetization/context/MonetizationContext'
import { SegmentEvents } from 'modules/segment'

import { getCurrencyDivisor, getNotation } from '../utils'
import { PaidProductBadge } from './PaidProductBadge'

export const UpsellCallout = ({
  description,
  buttonLabel,
  onOpenCallback,
  onCloseCallback,
  segmentEvent,
  isNarrow,
}: {
  description?: string | React.ReactNode
  buttonLabel?: string
  onOpenCallback?: () => void
  onCloseCallback?: () => void
  segmentEvent: SegmentEvents
  isNarrow?: boolean
}) => {
  const upsellUXEnabled_Pro = useFeatureFlag('upsellUXEnabled_Pro')

  const { openUpsellModal, product, proYearlyProductPrice } =
    useMonetizationContext()

  if (product === 'pro' || !upsellUXEnabled_Pro) {
    return null
  }

  // Possibly show the plus price in the future if this modal supports a Plus upsell
  // We (James Nik Zach) aren't yet sure how to show "from $8"
  const fromPrice =
    (proYearlyProductPrice?.price || 19200) /
    getCurrencyDivisor(proYearlyProductPrice?.currency) /
    12
  const fromCurrency = proYearlyProductPrice?.currency || Currency.Usd
  const notation = getNotation(proYearlyProductPrice?.currency)

  return (
    <Flex
      flex={1}
      w="100%"
      mb={4}
      p={4}
      borderRadius="md"
      bg="trueblue.50"
      borderColor="trueblue.200"
      position="relative"
      borderWidth={1}
      shadow="sm"
      flexDirection={isNarrow ? 'column' : 'row'}
      align="center"
      gap={4}
    >
      <Stack flex={2} alignSelf="stretch">
        <HStack>
          <PaidProductBadge variant="solid" />
          <Text fontWeight="bold">
            <Trans>Upgrade to {GAMMA_PRO_PROPER_NOUN}</Trans>
          </Text>
        </HStack>
        <Text color="gray.700" fontSize="xs">
          {description || (
            <Trans>
              Unlock unlimited AI and remove {GAMMA_PROPER_NOUN} branding
            </Trans>
          )}
        </Text>
      </Stack>
      <Stack spacing={1} flex={1} alignSelf="stretch">
        <Button
          variant="solid"
          colorScheme="trueblue"
          size="sm"
          onClick={() => {
            openUpsellModal({
              onOpenCallback,
              onCloseCallback,
              segmentEvent,
            })
          }}
        >
          {buttonLabel || <Trans>Upgrade</Trans>}
        </Button>
        <Text textAlign="center" fontSize="xs" color="gray.700">
          <Trans>
            from{' '}
            {formatCurrency(fromPrice, {
              currency: fromCurrency,
              notation,
            })}
            /mo
          </Trans>
        </Text>
      </Stack>
    </Flex>
  )
}
