import { Button, Flex, GridItem, SimpleGrid, Skeleton } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { range } from 'lodash'
import React from 'react'

import { SavedMedia } from 'modules/api'

import { AIImageHistoryItem } from './AIImageHistoryItem'

type AIImageHistoryProps = {
  savedMediaId: string | undefined | null
  imageResults: SavedMedia[]
  onSelectImage: (SavedMedia) => void
  onDeleteImage: (savedMediaId: string) => void
  deletingId: string | null
  isLoading: boolean
  canLoadMore: boolean
  isLoadingMore: boolean
  loadMore: () => void
  resultsPerPage: number
  resizeThumbnails?: boolean
}

export const AIImageHistory = ({
  onSelectImage,
  savedMediaId,
  imageResults,
  onDeleteImage,
  deletingId,
  isLoading,
  canLoadMore,
  isLoadingMore,
  loadMore,
  resultsPerPage,
  resizeThumbnails,
}: AIImageHistoryProps) => {
  const gridItems: React.ReactNode[] = imageResults.map((image: SavedMedia) => {
    return (
      <AIImageHistoryItem
        image={image}
        isSelected={image.id === savedMediaId}
        isDeleting={image.id === deletingId}
        onDeleteImage={onDeleteImage}
        onImageClick={onSelectImage}
        key={image.id}
        resizeThumbnail={resizeThumbnails}
      />
    )
  })
  return (
    <Flex direction="column" align="stretch">
      <SimpleGrid gap={2} columns={3}>
        {isLoading && <GridSkeleton resultsPerPage={resultsPerPage} />}
        {gridItems}
      </SimpleGrid>
      {canLoadMore && (
        <Button
          variant="plain"
          onClick={loadMore}
          mt={4}
          isLoading={isLoadingMore}
        >
          <Trans>Load more</Trans>
        </Button>
      )}
    </Flex>
  )
}
const GridSkeleton = ({ resultsPerPage }: { resultsPerPage: number }) => {
  return (
    <>
      {range(resultsPerPage).map((_, index) => (
        <GridItem key={index}>
          <Skeleton height="100px" borderRadius="base" />
        </GridItem>
      ))}
    </>
  )
}
