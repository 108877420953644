import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'

import { EmbedPanel } from '../components/EmbedPanel'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import Icon from './icons/calendly.svg'

const Panel = (props: MediaProviderPanelProps<WebEmbedAttrs>) => {
  return <EmbedPanel {...props} urlPlaceholder="https://calendly.com/..." />
}

export const CalendlyProvider: MediaProviderType = {
  label: () => 'Calendly',
  key: 'embed.calendly',
  description: () =>
    t`Let people schedule meetings with you, directly from your page`,
  icon: duotone('calendar'),
  image: Icon,
  urlRegex: /^https?:\/\/calendly.com/,
  Panel,
  defaultDisplayStyle: 'inline',
  inlineInteractive: true,
  categories: ['form'],
  iframeOptions: {
    allowPopups: true,
  },
  keywords: ['calendly', 'meeting', 'schedule', 'appointment'],
}
