import { VStack } from '@chakra-ui/react'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { getMediaEmbedUrl } from 'modules/tiptap_editor/extensions/media/utils'

import { EmbedMetadata } from '../providers/EmbedMetadata'
import { MediaProviderPanelProps } from '../types/MediaProvider'
import { URLFetcher } from './URLFetcher'

type EmbedPanelProps = MediaProviderPanelProps<WebEmbedAttrs> & {
  urlPlaceholder?: string
  embedTip?: React.ReactNode
}

export const EmbedPanel = ({
  editor,
  updateAttributes,
  currentAttributes,
  urlPlaceholder,
  embedTip,
}: EmbedPanelProps) => {
  const url = getMediaEmbedUrl(currentAttributes)

  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url}
        updateAttributes={updateAttributes}
        placeholder={urlPlaceholder || 'https://...'}
      />
      {embedTip}
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
    </VStack>
  )
}
