import { Center, keyframes, Spinner, Text, TextProps } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ImageSearchProviderKey } from 'modules/media/types/ImageSearch'

type ImageLoadingSpinnerProps = {
  provider?: ImageSearchProviderKey
  label?: string
} & Pick<TextProps, 'color'>

export const ImageLoadingSpinner = ({
  provider,
  color = 'gray',
}: ImageLoadingSpinnerProps) => {
  const pulse = keyframes`
      from {transform: rotate(-5deg) scale(1)}
        to {transform: rotate(5deg) scale(1.2)}
  `
  const pulseAnimation = `${pulse} infinite alternate 700ms ease-out`
  const icon =
    provider === 'aiGenerated'
      ? regular('sparkle')
      : regular('magnifying-glass')

  return (
    <Center>
      <Spinner
        color={`${color}.300`}
        thickness="3px"
        size="xl"
        position="absolute"
        speed="0.7s"
        emptyColor="transparent"
      />
      <Text
        color={`${color}.400`}
        position="absolute"
        lineHeight="2"
        fontSize="lg"
        animation={pulseAnimation}
      >
        <FontAwesomeIcon icon={icon} fixedWidth />
      </Text>
    </Center>
  )
}
