import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Text,
} from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from '@lingui/macro'

import { useLocalizedFunction } from 'modules/i18n/hooks/useLocalizedFunction'

import { ImageLicense } from '../types/Image'

type ImageLicenseOption = {
  label: () => string
  key: ImageLicense
  description: () => string
}

const ALL_IMAGES_LICENSE_OPTION: ImageLicenseOption = {
  label: () => t`All images`,
  key: 'All',
  description: () =>
    t`Use the most relevant images, even if licensing is unknown.`,
}
const SHARE_IMAGE_LICENSE_OPTION: ImageLicenseOption = {
  label: () => t`Free to use`,
  key: 'Share',
  description: () =>
    t`Only use images licensed for personal use, like a school project.`,
}
const SHARE_COMMERCIALLY_IMAGE_LICENSE_OPTION: ImageLicenseOption = {
  label: () => t`Free to use commercially`,
  key: 'ShareCommercially',
  description: () =>
    t`Only use images licensed for commercial use, like a sales pitch.`,
}

const IMAGE_LICENSE_OPTIONS: ImageLicenseOption[] = [
  ALL_IMAGES_LICENSE_OPTION,
  SHARE_IMAGE_LICENSE_OPTION,
  SHARE_COMMERCIALLY_IMAGE_LICENSE_OPTION,
]

type ImageLicensePickerProps = {
  value?: ImageLicense
  onChange: (license: ImageLicense) => void
}

const DEFAULT_LICENSE = ALL_IMAGES_LICENSE_OPTION.key

export const getImageLicenseLabel = (license: ImageLicense) => {
  return IMAGE_LICENSE_OPTIONS.find((l) => l.key === license)?.label()
}

export const ImageLicensePicker = ({
  value = DEFAULT_LICENSE,
  onChange,
}: ImageLicensePickerProps) => {
  return (
    <Menu>
      <MenuButton
        w="100%"
        as={Button}
        boxShadow="sm"
        size="sm"
        variant="plain"
        rightIcon={<FontAwesomeIcon icon={solid('chevron-down')} />}
        textAlign="left"
      >
        {getImageLicenseLabel(value)}
      </MenuButton>
      <Portal>
        <MenuList zIndex="tooltip" w="350px">
          <MenuOptionGroup
            type="radio"
            w="100%"
            defaultValue={ALL_IMAGES_LICENSE_OPTION.key}
            value={value}
            onChange={(val) => onChange(val as ImageLicense)}
          >
            {IMAGE_LICENSE_OPTIONS.map(({ key, label, description }) => (
              <MenuItemOption key={key} value={key}>
                {label()}
                <Text fontSize="sm" color="gray.400">
                  {description()}
                </Text>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  )
}
