import { VStack } from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { getMediaEmbedUrl } from 'modules/tiptap_editor/extensions/media/utils'

import { URLFetcher } from '../components/URLFetcher'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import { EmbedMetadata } from './EmbedMetadata'
import PowerBIIcon from './icons/powerbi.svg'

const PowerBIPanel = ({
  editor,
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<WebEmbedAttrs>) => {
  const url = getMediaEmbedUrl(currentAttributes)

  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url}
        updateAttributes={updateAttributes}
        placeholder={t`Paste a URL or <iframe...`}
      />
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
    </VStack>
  )
}

export const PowerBIProvider: MediaProviderType = {
  key: 'embed.microsoft.powerbi',
  Panel: PowerBIPanel,
  label: () => 'PowerBI',
  image: PowerBIIcon,
  icon: solid('database'),
  urlRegex: /^https?:\/\/app\.powerbi\.com\//,
  canEmbedPrivate: true,
  iframeOptions: {
    allowPopups: true, // Required for their sign-in system
  },
  keywords: ['microsoft', 'powerbi'],
}

// URL examples
// <iframe width=“1140” height=“541.25" src=“https://app.powerbi.com/reportEmbed?reportId=3b64c160-ff70-4b7d-b78e-58b45a615281&autoAuth=true&ctid=0d82bba3-3f6e-40a7-9fce-0cba65c01af3&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLXdlc3QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9” frameborder=“0" allowFullScreen=“true”></iframe>
