import { TransformStream } from 'web-streams-polyfill/ponyfill'

import { StreamMessageEvent } from 'modules/ai/stream/types'

export type StreamMessageCbs = {
  onStart?: () => void
  onContent?: (content: string) => void
  onComplete?: (content: string) => void
  onError?: (err) => void
  onCancel?: () => void
  // can reliably always be called
  onFlush?: () => void
}

export class StreamMessageTextAccumulator extends TransformStream<
  StreamMessageEvent,
  StreamMessageEvent
> {
  private content = ''

  constructor(cbs: StreamMessageCbs) {
    super({
      start: () => {
        cbs.onStart?.()
      },

      transform: async (
        msg: StreamMessageEvent,
        controller: TransformStreamDefaultController<StreamMessageEvent>
      ) => {
        if (msg.event === 'content') {
          this.content += msg.data
          cbs.onContent?.(this.content)
        }

        if (msg.event === 'done') {
          cbs.onComplete?.(this.content)
        }

        if (msg.event === 'cancel') {
          cbs.onCancel?.()
        }

        controller.enqueue(msg)
      },

      flush() {
        cbs.onFlush?.()
      },
    })
  }

  getContent() {
    return this.content
  }
}
