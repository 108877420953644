import { VStack } from '@chakra-ui/react'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { getMediaEmbedUrl } from 'modules/tiptap_editor/extensions/media/utils'

import { EmbedTip } from '../components/EmbedTip'
import { URLFetcher } from '../components/URLFetcher'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import { EmbedMetadata } from './EmbedMetadata'
import FigmaIcon from './icons/figma.svg'

const FigmaPanel = ({
  editor,
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<WebEmbedAttrs>) => {
  const url = getMediaEmbedUrl(currentAttributes)

  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url}
        updateAttributes={updateAttributes}
        placeholder="https://www.figma.com/file/..."
      />
      <EmbedTip
        guideLink="https://help.figma.com/hc/en-us/articles/360039827134-Embed-files-and-prototypes"
        providerName="Figma/FigJam"
      />
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
    </VStack>
  )
}

export const FigmaProvider: MediaProviderType = {
  label: () => 'Figma',
  key: 'embed.figma',
  icon: brands('figma'),
  keywords: ['design', 'prototype', 'wireframe', 'figjam', 'whiteboard'],
  description: () => t`Interactive prototypes, wireframes, and FigJam boards`,
  image: FigmaIcon,
  urlRegex: /^https?:\/\/www.figma.com/,
  Panel: FigmaPanel,
  canEmbedPrivate: true,
  rewriteEmbedUrl: (url) => {
    // Don't rewrite if it's already an embed URL
    if (url.includes('figma.com/embed')) return url
    // Convert https://www.figma.com/file/u9lZT9stq6g0N5Vdh1ioju/Test-private-figma-file?node-id=0%3A1 to https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2Fu9lZT9stq6g0N5Vdh1ioju%2FTest-private-figma-file%3Fnode-id%3D0%253A1
    return `https://www.figma.com/embed?embed_host=share&url=${encodeURIComponent(
      url
    )}`
  },
}
