import {
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Stack,
  useBreakpointValue,
  useClipboard,
  useToast,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useCallback, useEffect, useRef, useState } from 'react'

type LinkCopierProps = InputProps & {
  url: string
  placeholder?: string
  isDisabled?: boolean
  variant?: 'plain' | 'solid'
  customLabel?: string
  onClick?: () => void
}

export const LinkCopier = ({
  url,
  placeholder,
  isDisabled = false,
  variant = 'plain',
  customLabel,
  onClick,
  ...rest
}: LinkCopierProps) => {
  const [isSelected, setIsSelected] = useState(false)
  const { hasCopied, onCopy } = useClipboard(url)
  const inputRef = useRef<HTMLInputElement>(null)
  const toast = useToast()
  const LINK_COPIED_TOAST_ID = 'link-copied-toast'
  const isBaseViewport = useBreakpointValue(
    {
      base: true,
      sm: false,
    },
    { fallback: 'sm' }
  )

  useEffect(() => {
    if (!inputRef.current) {
      return
    }
    if (isSelected) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [isSelected])

  const onInputClick = useCallback(() => setIsSelected(true), [])
  const onInputBlur = useCallback(() => setIsSelected(false), [])

  const onCopyClick = useCallback(() => {
    onCopy()
    if (!toast.isActive(LINK_COPIED_TOAST_ID)) {
      toast({
        id: LINK_COPIED_TOAST_ID,
        title: <Trans>Link copied to clipboard</Trans>,
        status: 'success',
        duration: 5000,
      })
    }
    onClick?.()
  }, [onCopy, toast])

  const buttonLabel = customLabel ? customLabel : <Trans>Copy</Trans>
  const shouldButtonBeBelow = Boolean(
    isBaseViewport && customLabel !== undefined
  )
  const buttonProps = shouldButtonBeBelow ? {} : { height: '2rem', margin: 2 }

  const button = (
    <Button
      isDisabled={isDisabled}
      onClick={onCopyClick}
      variant={variant}
      w="100%"
      {...buttonProps}
      {...(isDisabled
        ? {
            opacity: '1 !important',
            color: 'gray.400',
            _hover: {
              opacity: '1',
            },
            _active: {
              boxShadow: '0 none',
            },
          }
        : {})}
    >
      {hasCopied ? <Trans>Copied</Trans> : buttonLabel}
    </Button>
  )

  const input = (
    <Input
      ref={inputRef}
      type="text"
      variant="filled"
      value={url}
      isReadOnly
      isDisabled={isDisabled}
      placeholder={placeholder || undefined}
      onClick={onInputClick}
      onBlur={onInputBlur}
      pointerEvents={isDisabled ? 'none' : undefined}
      h="3rem"
      {...rest}
    />
  )

  return shouldButtonBeBelow ? (
    <Stack w="100%">
      {input}
      {button}
    </Stack>
  ) : (
    <InputGroup>
      {input}
      <InputRightElement h="3rem" zIndex={1} w="unset">
        {button}
      </InputRightElement>
    </InputGroup>
  )
}
