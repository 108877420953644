import { Divider, Flex, VStack } from '@chakra-ui/react'
import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { TDShapeType, TDSnapshot, TDToolType } from '@gamma-app/tldraw'
import { t } from '@lingui/macro'
import * as React from 'react'

import { useTldrawApp } from '../hooks'
import { ToolbarIcon } from './ToolbarIcon'
import { ToolbarWrapper } from './ToolbarWrapper'

type InsertToolbarCommand = {
  icon: FontAwesomeIconProps['icon']
  label: string
  tool: TDToolType
}

const getInsertToolbarCommands = (): InsertToolbarCommand[] => [
  {
    icon: solid('arrow-pointer'),
    label: t`Select`,
    tool: 'select',
  },
  {
    icon: duotone('eraser'),
    label: t`Erase`,
    tool: 'erase',
  },
  {
    icon: solid('horizontal-rule'),
    label: t`Line`,
    tool: TDShapeType.Line,
  },
  {
    icon: solid('arrow-up-right'),
    label: t`Arrow`,
    tool: TDShapeType.Arrow,
  },
  {
    icon: solid('rectangle'),
    label: t`Rectangle`,
    tool: TDShapeType.Rectangle,
  },
  {
    icon: solid('circle'),
    label: t`Circle`,
    tool: TDShapeType.Ellipse,
  },
  {
    icon: solid('triangle'),
    label: t`Triangle`,
    tool: TDShapeType.Triangle,
  },
  {
    icon: solid('text'),
    label: t`Text`,
    tool: TDShapeType.Text,
  },
  // Disabling this because freehand drawings massively bloat up the
  // JSON and SVG size of the drawings.
  // {
  //   icon: duotone('pen-clip'),
  //   label: t`Freehand`,
  //   tool: TDShapeType.Draw,
  // },
  // {
  //   icon: duotone('note-sticky'),
  //   label: t`Sticky`,
  //   tool: TDShapeType.Sticky,
  // },
]

const activeToolSelector = (s: TDSnapshot) => s.appState.activeTool

export const InsertToolbar = React.memo(function InsertToolbar() {
  const app = useTldrawApp()
  const activeTool = app.useStore(activeToolSelector)

  return (
    <Flex
      h="100%"
      direction="column"
      position="absolute"
      right={2}
      align="flex-end"
      justify="center"
      zIndex={1}
    >
      <ToolbarWrapper>
        <VStack spacing={2}>
          {getInsertToolbarCommands().map(({ label, icon, tool }) => {
            return (
              <>
                <ToolbarIcon
                  icon={icon}
                  label={label}
                  key={label}
                  onClick={() => app.selectTool(tool)}
                  isActive={activeTool === tool}
                  tooltipPlacement="left"
                  data-testid={`drawing-insert-toolbar-${tool}`}
                />
                {tool == 'select' && <Divider key="selectDivider" />}
              </>
            )
          })}
        </VStack>
      </ToolbarWrapper>
    </Flex>
  )
})
