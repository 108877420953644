import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { ImageSearch } from '../components/ImageSearch'
import {
  MediaProviderType,
  MediaProviderPanelProps,
} from '../types/MediaProvider'

const WebImagePanel = ({
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps) => {
  return (
    <ImageSearch
      updateAttributes={updateAttributes}
      currentImageUrl={currentAttributes.src}
      defaultQuery={currentAttributes.query || ''}
      enablePagination={true}
      enableLicenseFilter={true}
      provider="web"
      resultsPerPage={48}
      randomQueries={['wallpaper']}
      resizeThumbnails={true}
    />
  )
}

export const WebImageProvider: MediaProviderType = {
  key: 'image.web',
  Panel: WebImagePanel,
  featureFlag: 'webImageSearch',
  label: () => t`Web image search`,
  icon: duotone('magnifying-glass'),
  keywords: ['image', 'photo', 'pic'],
  description: () => t`Search the internet for images`,
  resizeToFit: true,
}
