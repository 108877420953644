import { Badge, BadgeProps, HStack, Text } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Plural, t } from '@lingui/macro'

import { useFeatureFlag } from 'modules/featureFlags'
import { useHasUnlimitedAI } from 'modules/monetization/hooks/productFeatures'

import { DEFAULT_CREDIT_COSTS } from '../constants'
import { CreditCostTypes } from '../types'

type CreditCostIconProps = {
  type: CreditCostTypes
} & BadgeProps

export const CreditCostIcon = ({
  type,
  ...badgeProps
}: CreditCostIconProps) => {
  const creditCosts = useFeatureFlag('aiDebits')
  const hasUnlimitedAI = useHasUnlimitedAI()
  if (hasUnlimitedAI) {
    return null
  }
  const numCredits = creditCosts[type] ?? DEFAULT_CREDIT_COSTS[type]
  return (
    <GammaTooltip
      label={
        <Plural
          value={numCredits}
          one="This will cost # credit"
          other="This will cost # credits"
        />
      }
    >
      <Badge
        colorScheme="whiteAlpha"
        bg="whiteAlpha.300"
        textTransform="none"
        textShadow="none"
        fontSize="xxs"
        ml={2}
        {...badgeProps}
      >
        <HStack spacing={1}>
          <Text>{numCredits}</Text>
          <FontAwesomeIcon icon={regular('coins')} size="sm" />
        </HStack>
      </Badge>
    </GammaTooltip>
  )
}
