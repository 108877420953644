import { t } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { uniqBy } from 'lodash'
import { useCallback, useMemo, useRef, useState } from 'react'

import { contentToAiHtml } from 'modules/ai/serialization/contentToAiHtml'
import { useStreamingChatCompletion } from 'modules/ai/stream/hooks'
import {
  AIImageRatingKey,
  AIRequestProps,
  generateAIInteractionId,
  trackAIRequestError,
  trackAIRequestResponse,
  trackAIRequestSent,
} from 'modules/ai/track'
import { StreamMessageCbs } from 'modules/ai/transform/StreamTransformers/StreamMessageTextAccumulator'
import {
  MediaType,
  SavedMedia,
  SavedMediaContext,
  useArchiveSavedMediaMutation,
  useGetSavedMediaQuery,
} from 'modules/api'
import { deductCredits } from 'modules/credits/mutations'
import { useFeatureFlag } from 'modules/featureFlags'
import {
  fetchGenerateImage,
  GenerateImageOptions,
} from 'modules/media/apis/imageGenerate'
import { generateImagePrompt } from 'modules/media/components/AIGeneratedImages/GenerateImagePrompt'
import { ImageAttrs } from 'modules/media/types/Image'
import { useCanUseProductFeature } from 'modules/monetization/hooks/productFeatures'
import { useAppSelector } from 'modules/redux'
import { useThemeConfigNavigationContext } from 'modules/theming/components/ThemeConfig/ThemeConfigNavigationContext'
import { findCardBody } from 'modules/tiptap_editor/extensions/Card/cardNavigationUtils'
import { selectDocId } from 'modules/tiptap_editor/reducer'
import { findNodeAndParents } from 'modules/tiptap_editor/utils'
import { isCardNode } from 'modules/tiptap_editor/utils/nodeHelpers'
import { getCurrentWorkspaceId, useUserContext } from 'modules/user'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { raceWithTimeout } from 'utils/promise'

const HISTORY_PAGE_SIZE = 18

/**
 * Takes either a docId or themeId
 */
const useImageGenerateHistory = ({
  docId,
  themeId,
}: {
  docId?: string
  themeId?: string
}) => {
  const [newImages, setNewImages] = useState<SavedMedia[]>([])
  const [isFetchingMore, setIsFetchingMore] = useState(false)

  const { data, loading, fetchMore } = useGetSavedMediaQuery({
    variables: {
      docId,
      themeId,
      source: 'image.ai-generated',
      type: MediaType.Image,
      first: HISTORY_PAGE_SIZE,
    },
  })

  const [deletingId, setDeletingId] = useState<string | null>(null)
  const [archiveSavedMedia] = useArchiveSavedMediaMutation()
  const deleteImage = useCallback(
    async (savedMediaId: string) => {
      // Remove from client side images
      setNewImages((existing) => existing.filter((i) => i.id !== savedMediaId))
      setDeletingId(savedMediaId)
      try {
        await archiveSavedMedia({
          variables: {
            id: savedMediaId,
          },
          update: (cache: any) => {
            // https://medium.com/@martinseanhunt/how-to-invalidate-cached-data-in-apollo-and-handle-updating-paginated-queries-379e4b9e4698
            // https://stackoverflow.com/questions/48596265/deleting-apollo-client-cache-for-a-given-query-and-every-set-of-variables
            const re = new RegExp(savedMediaId, 'g')
            Object.keys(cache.data.data).forEach((key) => {
              key.match(re) && cache.data.delete(key)
            })
          },
          refetchQueries: ['GetSavedMedia'],
        })
      } catch (e) {
        console.error(e)
      }
      setDeletingId(null)
    },
    [archiveSavedMedia]
  )

  const savedMedia = useMemo(
    () => data?.savedMedia.edges.map((a) => a.node) || [],
    [data?.savedMedia.edges]
  )

  const addToHistory = useCallback((images: SavedMedia[]) => {
    setNewImages((existing) => [...images, ...existing])
  }, [])

  const history = useMemo(() => {
    return uniqBy([...newImages, ...savedMedia], 'id')
  }, [newImages, savedMedia])

  const pageInfo = data?.savedMedia.pageInfo
  const loadMore = useCallback(() => {
    if (!pageInfo?.hasNextPage || !pageInfo?.endCursor) {
      return
    }

    setIsFetchingMore(true)
    fetchMore({
      variables: {
        after: pageInfo?.endCursor,
      },
    }).finally(() => {
      setIsFetchingMore(false)
    })
  }, [fetchMore, pageInfo])

  return {
    history,
    loadMore,
    canFetchMore: !!pageInfo?.hasNextPage,
    isLoadingHistory: loading,
    isFetchingMore,
    addToHistory,
    deleteImage,
    deletingId,
  }
}

export const useImageGenerate = ({ selectImage }) => {
  const docId = useAppSelector(selectDocId)!
  const themeContext = useThemeConfigNavigationContext()
  let themeId: string | undefined = themeContext?.state?.theme?.id
  // content for the saved image, let fetchGenerateImage set it based off
  // of docId or themeId.  special case if themeId is "new" because we dont
  // have a docId but know the context is theme create
  let context: GenerateImageOptions['context']
  // if we're in the theme editor in a new theme, we dont have the theme id yet
  // cannot pass "new" because prisma will try to associate with a theme with id="new" and
  // and error
  if (themeId === 'new') {
    themeId = undefined
    context = SavedMediaContext.Theme
  }

  const { currentWorkspace } = useUserContext()

  const [lastSearchedQuery, setLastSearchedQuery] = useState('')
  const [isGenerating, setIsGenerating] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    history,
    isLoadingHistory,
    addToHistory,
    loadMore,
    canFetchMore,
    isFetchingMore,
    deleteImage,
    deletingId,
  } = useImageGenerateHistory(
    // if we're in the theme editor context, search for theme images
    themeId ? { themeId } : docId ? { docId } : {}
  )

  const generate = useCallback(
    async (
      options: Omit<GenerateImageOptions, 'interactionId' | 'workspaceId'>
    ) => {
      const interactionId = generateAIInteractionId()
      setLastSearchedQuery(options.prompt)
      setIsGenerating(true)
      setHasError(false)

      setErrorMessage('')

      const optionsToUse = {
        ...options,
        docId: options.docId || docId,
        themeId: options.themeId || themeId,
        context,
      }
      const startTime = performance.now()
      const requestInfo: AIRequestProps = {
        interactionId,
        interface: 'generate-image' as const,
        provider: 'baseten' as const,
        streaming: false,
        inputContent: optionsToUse.prompt,
        docId: optionsToUse.docId,
        generateImageOptions: optionsToUse,
      }
      try {
        trackAIRequestSent(requestInfo)
        const images = await fetchGenerateImage({
          interactionId,
          workspaceId: currentWorkspace?.id!,
          ...optionsToUse,
        })
        setIsGenerating(false)
        if (images.length === 0) {
          throw new Error('No images generated')
        }

        // assume we're only creating one image right now
        if (images.length > 0) {
          addToHistory(images)
          selectImage(images[0])
        }
        deductCredits('generateImage')

        trackAIRequestResponse({
          ...requestInfo,
          latency: performance.now() - startTime,
          generateImageUrls: images.map((i) => i.attrs.src),
        })
      } catch (error) {
        setHasError(true)
        setErrorMessage(
          error.messageTranslated
            ? error.messageTranslated
            : t`There was an issue generating your image, please try again`
        )

        setIsGenerating(false)
        console.error('(caught) [AIGenerateImage] error:', error)
        trackAIRequestError({
          ...requestInfo,
          latency: performance.now() - startTime,
          errorName: 'GenerateImageError',
          errorMessage: error.message,
          outputMessage: '',
        })
      }
      setIsGenerating(false)
    },
    [addToHistory, currentWorkspace?.id, docId, selectImage, context, themeId]
  )

  return {
    generate,
    isGenerating,
    hasError,
    errorMessage,
    isLoadingHistory,
    imageResults: history,
    searchQuery: lastSearchedQuery,
    loadMore,
    canLoadMore: canFetchMore,
    isLoadingMore: isFetchingMore,
    deleteImage,
    deletingId,
  }
}

const useGetCurrentCardHtml = (editor?: Editor) => {
  const getCardHtml = useCallback(() => {
    if (!editor) {
      return null
    }

    const { selection } = editor.state
    const parentCard = findNodeAndParents(selection.$from, isCardNode)[0]
    const cardBody = parentCard && findCardBody(parentCard.node, parentCard.pos)
    const { content } = editor.state.doc.slice(
      cardBody.pos + 1,
      cardBody.pos + cardBody.node.nodeSize - 1
    )
    return contentToAiHtml(editor, content)
  }, [editor])

  return getCardHtml
}

export const ENHANCE_PROMPT_TIMEOUT = 30000
/**
 * Convenience wrapper around `useStreamingTextPrompt` that handles
 * not allowing concurrent requests and adding a timeout conditions
 */
export const useEnhanceImagePrompt = (
  editor: Editor | undefined,
  cbs: StreamMessageCbs
) => {
  const getCurrentCardHtml = useGetCurrentCardHtml(editor)
  const { generate } = useStreamingChatCompletion(generateImagePrompt, {
    ...cbs,
    timeout: ENHANCE_PROMPT_TIMEOUT,
  })

  const enhancePrompt = useCallback(
    (prompt: string): Promise<string> => {
      const { promise } = generate({
        variables: {
          input: prompt,
          html: getCurrentCardHtml(),
        },
        workspaceId: getCurrentWorkspaceId(),
      })
      return raceWithTimeout(promise, ENHANCE_PROMPT_TIMEOUT)
    },
    [generate, getCurrentCardHtml]
  )

  return enhancePrompt
}

type PromptHistoryState = {
  history: string[]
  ind: number
}

// LOAD first load AI image ->  load existing prompt as only history
// LOAD first load non ai image ->  load existing query as only history
// SELECT selecting image -> diff prompt -> push prompt
// SELECT selecting image -> same prompt -> no-op
// UPDATE typing after selecting image -> push new prompt
// UPDATE typing after typing -> update prompt
// UPDATE enhance prompt (no content) -> replace prompt
// UPDATE enhance prompt -> push prompt
// select generate prompt + select image (is last) -> no-op
// select generate prompt + select image (is not last) -> push prompt
const isLast = (state: PromptHistoryState) =>
  state.ind === state.history.length - 1
const getLast = (state: PromptHistoryState) =>
  state.history[state.history.length - 1]

const stateFns = {
  load(state: PromptHistoryState, prompt: string): PromptHistoryState {
    return {
      history: [prompt],
      ind: 0,
    }
  },

  push(state: PromptHistoryState, prompt: string): PromptHistoryState {
    if (getLast(state).trim() === '') {
      return this.replace(state, prompt)
    }
    return {
      history: [...state.history, prompt],
      ind: state.history.length,
    }
  },

  replace(state: PromptHistoryState, prompt: string): PromptHistoryState {
    const prev = state.history.slice(0, -1)
    return {
      history: [...prev, prompt],
      ind: prev.length,
    }
  },

  select(state: PromptHistoryState, prompt: string): PromptHistoryState {
    if (isLast(state)) {
      return getLast(state) === prompt ? state : this.push(state, prompt)
    }

    return this.push(state, prompt)
  },
}

export const usePromptHistory = () => {
  const lastLoadedIsExisting = useRef(false)

  const [state, setState] = useState<PromptHistoryState>({
    history: [''],
    ind: 0,
  })

  const addPrompt = useCallback(
    (prompt: string, operation: 'load' | 'push' | 'update' | 'select') => {
      if (operation === 'load') {
        setState((existing) => stateFns.load(existing, prompt))
      }

      if (operation === 'push') {
        setState((existing) => stateFns.push(existing, prompt))
      }

      if (operation === 'update') {
        if (lastLoadedIsExisting.current) {
          setState((existing) => stateFns.push(existing, prompt))
        } else {
          setState((existing) => stateFns.replace(existing, prompt))
        }
      }

      if (operation === 'select') {
        setState((existing) => stateFns.select(existing, prompt))
      }

      lastLoadedIsExisting.current = operation !== 'update'
    },
    []
  )

  const prev = useCallback(() => {
    const { ind } = state
    if (ind <= 0) {
      return
    }
    setState((existing) => ({
      ...existing,
      ind: existing.ind - 1,
    }))
  }, [state])

  const next = useCallback(() => {
    const { history, ind } = state
    if (ind >= history.length - 1) {
      return
    }

    setState((existing) => ({
      ...existing,
      ind: existing.ind + 1,
    }))
  }, [state])

  const { ind, history } = state
  return {
    prompt: history[ind] || '',
    addPrompt,
    promptPageInfo: {
      curr: ind + 1,
      total: history.length,
      canGoNext: ind < history.length - 1,
      canGoPrev: ind > 0,
    },
    nextPrompt: next,
    prevPrompt: prev,
  }
}

export const useCanUseAIGeneratedImages = () => {
  const canUseFeature = useCanUseProductFeature('ai_generated_images')
  const hasFlag = useFeatureFlag('aiGeneratedImages')
  return canUseFeature || hasFlag
}

export const useCanUseAIGeneratedImagesWizard = () => {
  const canUseFeature = useCanUseProductFeature('ai_generated_images')
  const hasFlag = useFeatureFlag('aiGeneratedImagesInWizard')
  return canUseFeature || hasFlag
}

// Not a complete list, just the ones we show a special message for
export type ImageErrorType = 'sexual' | 'violence' | 'prohibited'

/**
 * Light in-memory mapping of what images have been rated
 *
 * This hook is a bit strange, we dont actually want the hasRatedImage
 * function to be reactive and update it's references when setMap is called
 * so it does not depend on `map` as a `useCallback` dependency.  This is because
 * immediately updating this function would cause the rating box to immediately disappear
 *
 * Instead allow the local state of AIImageRating to control the state of the box
 */
export const useImageRatingStore = () => {
  // const map = useRef<ImageRatingMap>({})
  const [map, setMap] = useLocalStorage('aiImageRatings', {})

  const saveImageRating = useCallback(
    (attrs: ImageAttrs, rating: AIImageRatingKey) => {
      if (attrs.savedMediaId) {
        map[attrs.savedMediaId] = rating
        setMap(map)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const hasRatedImage = useCallback((attrs: ImageAttrs) => {
    if (!attrs.savedMediaId) {
      return true
    }
    return !!map[attrs.savedMediaId]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { saveImageRating, hasRatedImage }
}
