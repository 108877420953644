import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'

import { EmbedPanel } from '../components/EmbedPanel'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import Icon from './icons/typeform.svg'

const Panel = (props: MediaProviderPanelProps<WebEmbedAttrs>) => {
  return <EmbedPanel {...props} urlPlaceholder="https://typeform.com/..." />
}

export const TypeformProvider: MediaProviderType = {
  label: () => 'Typeform',
  key: 'embed.typeform',
  description: () => t`Capture information one question at a time`,
  icon: duotone('input-text'),
  image: Icon,
  urlRegex: /^https?:\/\/([a-z0-9]*\.)?typeform\.com/,
  Panel,
  defaultDisplayStyle: 'inline',
  inlineInteractive: true,
  categories: ['form'],
  iframeOptions: {
    allowPopups: true,
  },
  keywords: ['typeform', 'form', 'survey', 'questionnaire'],
}
