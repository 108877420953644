import {
  Button,
  Flex,
  FormControl,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { memo } from 'react'

import {
  AspectRatioKey,
  ASPECT_RATIOS,
} from 'modules/media/components/AIGeneratedImages/imageGenerateConfig'

const ICONS: Record<AspectRatioKey, IconProp> = {
  square: regular('square'),
  portrait: regular('rectangle-vertical'),
  landscape: regular('rectangle-wide'),
}

type AspectRatioSelectProps = {
  value: AspectRatioKey
  setValue: (val: AspectRatioKey) => void
  isDisabled?: boolean
}

export const AspectRatioSelect = memo(
  ({ value, setValue, isDisabled }: AspectRatioSelectProps) => {
    const selected = ASPECT_RATIOS[value] || ASPECT_RATIOS['square']

    return (
      <FormControl>
        <Menu>
          <MenuButton
            as={Button}
            variant="plain"
            isDisabled={isDisabled}
            w="100%"
            textAlign="left"
            justifyContent="space-between"
            rightIcon={<FontAwesomeIcon icon={regular('chevron-down')} />}
            boxShadow="none"
          >
            <HStack spacing={2}>
              <Flex width="24px" height="24px" align="center" justify="center">
                <FontAwesomeIcon icon={ICONS[selected.key]} fixedWidth />
              </Flex>
              <Text as="span" flex={1}>
                {selected.name()}
              </Text>
            </HStack>
          </MenuButton>
          <MenuList
            minWidth="240px"
            maxH="290px"
            overflowY="auto"
            overflowX="hidden"
          >
            <MenuOptionGroup
              value={value}
              type="radio"
              onChange={(v: AspectRatioKey) => {
                setValue(v)
              }}
            >
              {Object.values(ASPECT_RATIOS).map((entry) => (
                <MenuItemOption value={entry.key} key={entry.key}>
                  <HStack spacing={2}>
                    <Flex
                      width="24px"
                      height="24px"
                      align="center"
                      justify="center"
                    >
                      <FontAwesomeIcon icon={ICONS[entry.key]} fixedWidth />
                    </Flex>
                    <Text as="span" flex={1}>
                      {entry.name()}
                    </Text>
                  </HStack>
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </FormControl>
    )
  }
)

AspectRatioSelect.displayName = 'AspectRatioSelect'
