import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'

import { EmbedPanel } from '../components/EmbedPanel'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import Icon from './icons/jotform.svg'

const Panel = (props: MediaProviderPanelProps<WebEmbedAttrs>) => {
  return <EmbedPanel {...props} urlPlaceholder="https://form.jotform.com/..." />
}

export const JotformProvider: MediaProviderType = {
  label: () => 'Jotform',
  key: 'embed.jotform',
  description: () => t`Embed forms with conditional logic`,
  icon: duotone('input-text'),
  image: Icon,
  urlRegex: /^https?:\/\/form\.jotform\.com/,
  Panel,
  defaultDisplayStyle: 'inline',
  inlineInteractive: true,
  categories: ['form'],
  iframeOptions: {
    allowPopups: true,
  },
  keywords: ['jotform', 'form', 'survey', 'questionnaire'],
}
