import { Editor, getRenderedAttributes } from '@tiptap/core'
import { DOMSerializer, Fragment, Node } from 'prosemirror-model'

import { transformCopiedDocFragment } from 'modules/tiptap_editor/extensions/Clipboard/transformCopiedDocFragment'

// Takes ProseMirror content (a doc, node, or fragment) and renders it into HTML
// for AI to consume.
export const contentToAiHtml = (editor: Editor, content: Fragment | Node) => {
  const serializer = getAIDomSerializer(editor)

  const fragment = Fragment.from(content)
  const doc = serializer.serializeFragment(fragment) as DocumentFragment
  const serialized = transformCopiedDocFragment(doc)

  // Turn the fragment into HTML for clipboard
  const div = document.createElement('div')
  div.appendChild(serialized)
  const html = div.innerHTML
  return html
}

// Extends Tiptap's serializer to use the `renderHTMLforAI` attribute on nodes and marks
// Based on https://github.com/ProseMirror/prosemirror-model/blob/9201015c268947c34fa31be26b8b7aa5a0cf9776/src/to_dom.ts#L160
const getAIDomSerializer = (editor: Editor): DOMSerializer => {
  const { schema, extensionManager } = editor
  if (schema.cached.aiDomSerializer) {
    return schema.cached.aiDomSerializer
  }
  const markSerializers = DOMSerializer.marksFromSchema(schema)
  const nodeSerializers = DOMSerializer.nodesFromSchema(schema)
  const allExtensionAttributes = editor.extensionManager.attributes

  extensionManager.extensions.forEach((extension) => {
    if (!extension.config.renderHTMLforAI) return
    // Copied from https://github.com/ueberdosis/tiptap/blob/43970fd496ffa22eae73ce95a11ed71d6efa4c03/packages/core/src/helpers/getSchemaByResolvedExtensions.ts#L120
    const extensionAttrs = allExtensionAttributes.filter(
      (attr) => attr.type === extension.name
    )

    if (nodeSerializers[extension.name]) {
      nodeSerializers[extension.name] = (node) => {
        const HTMLAttributes = getRenderedAttributes(node, extensionAttrs)
        return extension.config.renderHTMLforAI.bind(extension)({
          node,
          HTMLAttributes,
        })
      }
    }
    if (markSerializers[extension.name]) {
      markSerializers[extension.name] = (mark, inline) => {
        const HTMLAttributes = getRenderedAttributes(mark, extensionAttrs)
        return extension.config.renderHTMLforAI.bind(extension)({
          mark,
          inline,
          HTMLAttributes,
        })
      }
    }
  })

  const serializer = new DOMSerializer(nodeSerializers, markSerializers)
  schema.cached.aiDomSerializer = serializer
  return serializer
}
