import { ResolvedPos, Slice } from 'prosemirror-model'

import { findParentNodes } from 'modules/tiptap_editor/utils'

import { getCardLayoutItemsFromResolvedPos } from '../Card/CardLayout/cardLayoutUtils'
import { isCardNode } from '../Card/utils'

/**
 * Util that handles special cases where we cannot drop on something
 *
 *
 * Special Cases:
 * - non card layout content on cards with cardLayoutItems
 *
 * return true if we can drop, false otherwise
 */
export const checkCanDrop = ($pos: ResolvedPos, slice?: Slice) => {
  if (!checkCardContentDrop($pos)) {
    return false
  }
  return true
}

const checkCardContentDrop = ($pos: ResolvedPos) => {
  const isOnCard = $pos.parent && isCardNode($pos.parent)
  if (!isOnCard) {
    return true
  }

  const cardNode = findParentNodes($pos, isCardNode)[0]
  if (!cardNode) {
    return true
  }

  const $card = $pos.doc.resolve(cardNode.pos)
  const layoutItems = getCardLayoutItemsFromResolvedPos($card)

  return Object.keys(layoutItems).length === 0
}
