import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  ButtonGroup,
} from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AlignStyle } from '@gamma-app/tldraw'
import { t } from '@lingui/macro'
import * as React from 'react'

import { useTldrawApp } from '../hooks'
import {
  getCommonStyle,
  currentStyleSelector,
  selectedIdsSelector,
} from './StylePopover'
import { ToolbarIcon } from './ToolbarIcon'
import { ToolbarWrapper } from './ToolbarWrapper'

const AlignIcons = {
  [AlignStyle.Start]: duotone('align-left'),
  [AlignStyle.Middle]: duotone('align-center'),
  [AlignStyle.End]: duotone('align-right'),
}

export const TextAlignPopover = () => {
  const app = useTldrawApp()

  // The style used for new shapes going forward
  const currentStyle = app.useStore(currentStyleSelector)
  // Common style attributes in the selection
  const selectedIds = app.useStore(selectedIdsSelector)
  const commonStyle = getCommonStyle(app, selectedIds)
  // Display the current style with common styles overridden
  const displayStyle = { ...currentStyle, ...commonStyle }

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <ToolbarIcon
          icon={AlignIcons[displayStyle.textAlign || AlignStyle.Start]}
        />
      </PopoverTrigger>
      <PopoverContent background="none" shadow="none" w="fit-content">
        <ToolbarWrapper direction="column">
          <ButtonGroup>
            <ToolbarIcon
              icon={duotone('align-left')}
              label={t`Align left`}
              key={'align-left'}
              onClick={() => app.style({ textAlign: AlignStyle.Start })}
              isActive={displayStyle.textAlign === AlignStyle.Start}
            />
            <ToolbarIcon
              icon={duotone('align-center')}
              label={t`Align center`}
              key={'align-center'}
              onClick={() => app.style({ textAlign: AlignStyle.Middle })}
              isActive={displayStyle.textAlign === AlignStyle.Middle}
            />
            <ToolbarIcon
              icon={duotone('align-right')}
              label={t`Align right`}
              key={'align-right'}
              onClick={() => app.style({ textAlign: AlignStyle.End })}
              isActive={displayStyle.textAlign === AlignStyle.End}
            />
            <ToolbarIcon
              icon={duotone('align-justify')}
              label={t`Justify`}
              key={'justify'}
              onClick={() => app.style({ textAlign: AlignStyle.Justify })}
              isActive={displayStyle.textAlign === AlignStyle.Justify}
            />
          </ButtonGroup>
        </ToolbarWrapper>
      </PopoverContent>
    </Popover>
  )
}
