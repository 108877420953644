// https://stackoverflow.com/a/28211790

export const querySelectorArray = (
  dom: Document | DocumentFragment | HTMLElement,
  selector: string,
  reverse: boolean = false
) => {
  const elts = [].slice.call(dom.querySelectorAll(selector), 0) as HTMLElement[]
  if (reverse) {
    elts.reverse()
  }
  return elts
}
