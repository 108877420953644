import { Editor, findChildren, JSONContent } from '@tiptap/core'
import { UppyFile } from '@uppy/core'
import get from 'lodash/get'
import merge from 'lodash/merge'
import set from 'lodash/set'
import { Attrs, Node as ProsemirrorNode, Schema } from 'prosemirror-model'

import { ImageAttrs } from 'modules/media/types/Image'
import {
  ImageUploadResult,
  UploadStatus,
} from 'modules/media/types/ImageUpload'
import { formatBytes } from 'utils/file'

import { BackgroundImageAttrs } from '../../../styles/types'
import { WebEmbedAttrs } from '../types'
import { MediaUploadStorage } from './UploadExtension'
export const REVOKE_TEMP_URL_TIMEOUT = 30000

type UploadResult =
  | {
      result?: undefined
      isError: true
    }
  | {
      isError: false
      result: ImageUploadResult
    }

export const getUploadedImageAttrs = ({
  result,
  isError,
}: UploadResult): Partial<ImageAttrs> => {
  if (isError) {
    return {
      uploadStatus: UploadStatus.Error,
      showPlaceholder: true,
      tempUrl: null,
      src: null,
    }
  }

  return {
    uploadStatus: UploadStatus.Done,
    showPlaceholder: false,
    ...result,
  }
}

/**
 * Merges the provided image attributes into the node's existing attributes
 * at the keypath defined by spec.imageKeyPath, otherwise at the root.
 */
export const mergeImageAttrs = (
  node: ProsemirrorNode,
  attrs: Partial<ImageAttrs | BackgroundImageAttrs>
) => {
  const key = node.type.spec.imageKeyPath
  const mergeObj = key ? set({}, key, attrs) : attrs
  const result = merge({}, node.attrs, mergeObj)
  return result
}

export const getNodeImageAttrs = (
  node: ProsemirrorNode
): BackgroundImageAttrs | null => {
  const imageKeyPath = node.type.spec.imageKeyPath
  if (!imageKeyPath && node.type.name !== 'image') {
    return null
  }
  return imageKeyPath ? get(node.attrs, imageKeyPath, node.attrs) : node.attrs
}

const findImagesWithUrl = (editor: Editor, existingUrl: string) =>
  // Find the image with placeholder we inserted via attrs.tempUrl
  findChildren(editor.state.doc, (node) => {
    const attrsObj = getNodeImageAttrs(node)
    return [attrsObj?.tempUrl, attrsObj?.src].includes(existingUrl)
  })

const findEmbedsWithUrl = (editor: Editor, existingUrl: string) =>
  // Find the embed with placeholder we inserted via attrs.url
  findChildren(editor.state.doc, (node) => {
    return node.type.name === 'embed' && node.attrs.url === existingUrl
  })

export const handleImageUploadSuccess = (
  editor: Editor,
  existingUrl: string,
  result: ImageUploadResult
) => {
  // For image uploads in the footnote editor, we want to update in the outer editor
  // since the child one may have been destroyed while upload was in progress
  if (editor.parentEditor) {
    editor = editor.parentEditor
  }

  if (existingUrl) {
    updateMediaUploadStorage(editor, result, existingUrl)
  }

  const matches = findImagesWithUrl(editor, existingUrl)
  if (matches.length == 0) {
    // This can happen if the user hits undo or deletes before the upload is done
    console.warn("[MediaUpload] Couldn't find placeholder node")
    return
  }
  matches.forEach(({ pos }) => {
    editor.commands.updateImageOnUploadSuccess(pos, result)
  })

  setTimeout(() => {
    editor.commands.revokeImageTempURLs(existingUrl)
  }, REVOKE_TEMP_URL_TIMEOUT)
}

export const handleImageUploadFailed = (
  editor: Editor,
  existingUrl: string,
  errorMessage?: string,
  restoreAttrs: boolean = false
) => {
  // For image uploads in the footnote editor, we want to update in the outer editor
  // since the child one may have been destroyed while upload was in progress
  if (editor.parentEditor) {
    editor = editor.parentEditor
  }

  const matches = findImagesWithUrl(editor, existingUrl)
  if (matches.length == 0) {
    // This can happen if the user hits undo or deletes before the upload is done
    console.warn("[MediaUpload] Couldn't find placeholder node")
    return
  }
  matches.forEach((match) =>
    editor.commands.updateImageOnUploadFailure(match.pos, restoreAttrs)
  )
  // TODO handle upload errors here
  console.error(errorMessage)
}

// Track the upload result so if the user copies the placeholder and pastes it
// during upload, we can put it back in place in transformPasted
const updateMediaUploadStorage = (
  editor: Editor,
  result: ImageUploadResult,
  tempUrl: string
) => {
  const uploadStorage: MediaUploadStorage = editor.storage.mediaUpload
  uploadStorage.completedUploads[tempUrl] = result
}

export const handlePDFUploadSuccess = (
  editor: Editor,
  existingUrl: string,
  result: ImageUploadResult
) => {
  const matches = findEmbedsWithUrl(editor, existingUrl)
  if (matches.length == 0) {
    // This can happen if the user hits undo or deletes before the upload is done
    console.warn("[MediaUpload] Couldn't find placeholder node")
    return
  }
  matches.forEach((match) => {
    editor.commands.updateAttributesAtPos(match.pos, {
      url: result.src,
      sourceUrl: result.src,
      embed: {
        url: result.src,
      },
      thumbnail: {
        src: result.thumbnail,
        uploadStatus: UploadStatus.Done,
        showPlaceholder: false,
      },
    })
  })
}

export const getTempPDFAttrs = (
  file: File | UppyFile
): Partial<WebEmbedAttrs> => {
  return {
    meta: {
      title: file.name,
      description: formatBytes(file.size, 1),
    },
    thumbnail: {
      uploadStatus: UploadStatus.Uploading,
      showPlaceholder: true,
    },
    source: 'embed.pdf',
  }
}
